<template>
	<div class="com-content">
		<div class="group1">
			<div class="row1">{{ $t("NewbieCheckin_title") }}</div>
			<div class="row2">{{ $t("NewbieCheckin_subtitle") }}</div>
			
			<div class="row3 countdown">
				<span class="refresh">{{ $t("DaysLegacy56_countdown") }}</span>
				<div class="time" v-if="dd>0 || hh>0 || mm>0">
					<span class="tm">{{ dd }}</span>
					<span class="un">{{ $t("time_D") }}</span>
					<span class="tm">{{ hh }}</span>
					<span class="un">{{ $t("time_H") }}</span>
					<span class="tm">{{ mm }}</span>
					<span class="un">{{ $t("time_M") }}</span>
				</div>
				<div class="time" v-else>
					<span class="tm" style="width: 140px;">{{ $t("EventEnded") }}</span>
				</div>
			</div>
			
			<img class="dbx" src="../../assets/earncoins/dbx.png"/>
		</div>
		
		<div class="group2">
			
			<div class="row2">
				<div class="left">
					<div class="item" :class="{ 'curr': currCard==1, 'kai':checkNumber(1) }">
						<span class="num">01</span>
						<div class="pt2">
							<img class="img" :src="require('../../assets/earncoins/'+(checkNumber(1)?'jb2':'jb1')+'-7-1.png')"/>
							<span class="txt">×1</span>
						</div>
						<img class="lb2" :src="require('../../assets/earncoins/'+(checkNumber(1)?'lb2':'lb1')+'.png')"/>
						<img class="rt2" :src="require('../../assets/earncoins/'+(checkNumber(1)?'rt2':'rt1')+'.png')"/>
						<div class="btn" @click="item_click(1)">{{ $t("CHECKEDIN") }}</div>
					</div>
					<div class="item" :class="{ 'curr': currCard==2, 'kai':checkNumber(2) }">
						<span class="num">02</span>
						<div class="pt2">
							<img class="img" :src="require('../../assets/earncoins/'+(checkNumber(2)?'jb2':'jb1')+'-7-1.png')"/>
							<span class="txt">×1</span>
						</div>
						<img class="lb2" :src="require('../../assets/earncoins/'+(checkNumber(2)?'lb2':'lb1')+'.png')"/>
						<img class="rt2" :src="require('../../assets/earncoins/'+(checkNumber(2)?'rt2':'rt1')+'.png')"/>
						<div class="btn" @click="item_click(2)">{{ $t("CHECKEDIN") }}</div>
					</div>
					<div class="item" :class="{ 'curr': currCard==3, 'kai':checkNumber(3) }">
						<span class="num">03</span>
						<div class="pt2">
							<img class="img" :src="require('../../assets/earncoins/'+(checkNumber(3)?'jb2':'jb1')+'-7-1.png')"/>
							<span class="txt">×2</span>
						</div>
						<img class="lb2" :src="require('../../assets/earncoins/'+(checkNumber(3)?'lb2':'lb1')+'.png')"/>
						<img class="rt2" :src="require('../../assets/earncoins/'+(checkNumber(3)?'rt2':'rt1')+'.png')"/>
						<div class="btn" @click="item_click(3)">{{ $t("CHECKEDIN") }}</div>
					</div>
					<div class="item" :class="{ 'curr': currCard==4, 'kai':checkNumber(4) }">
						<span class="num">04</span>
						<div class="pt2">
							<img class="img" :src="require('../../assets/earncoins/'+(checkNumber(4)?'jb2':'jb1')+'-7-1.png')"/>
							<span class="txt">×1</span>
						</div>
						<img class="lb2" :src="require('../../assets/earncoins/'+(checkNumber(4)?'lb2':'lb1')+'.png')"/>
						<img class="rt2" :src="require('../../assets/earncoins/'+(checkNumber(4)?'rt2':'rt1')+'.png')"/>
						<div class="btn" @click="item_click(4)">{{ $t("CHECKEDIN") }}</div>
					</div>
					<div class="item" :class="{ 'curr': currCard==5, 'kai':checkNumber(5) }">
						<span class="num">05</span>
						<div class="pt2">
							<img class="img" :src="require('../../assets/earncoins/'+(checkNumber(5)?'jb2':'jb1')+'-7-1.png')"/>
							<span class="txt">×1</span>
						</div>
						<img class="lb2" :src="require('../../assets/earncoins/'+(checkNumber(5)?'lb2':'lb1')+'.png')"/>
						<img class="rt2" :src="require('../../assets/earncoins/'+(checkNumber(5)?'rt2':'rt1')+'.png')"/>
						<div class="btn" @click="item_click(5)">{{ $t("CHECKEDIN") }}</div>
					</div>
					<div class="item" :class="{ 'curr': currCard==6, 'kai':checkNumber(6) }">
						<span class="num">06</span>
						<div class="pt2">
							<img class="img" :src="require('../../assets/earncoins/'+(checkNumber(6)?'jb2':'jb1')+'-7-1.png')"/>
							<span class="txt">×1</span>
						</div>
						<img class="lb2" :src="require('../../assets/earncoins/'+(checkNumber(6)?'lb2':'lb1')+'.png')"/>
						<img class="rt2" :src="require('../../assets/earncoins/'+(checkNumber(6)?'rt2':'rt1')+'.png')"/>
						<div class="btn" @click="item_click(6)">{{ $t("CHECKEDIN") }}</div>
					</div>
				</div>
				<div class="item last" :class="{ 'curr': currCard==7, 'kai':checkNumber(7)}">
					<img class="qdt1" src="../../assets/earncoins/qdt1.png"/>
					<span class="num">07</span>
					<div class="pt2">
						<img class="img" :src="require('../../assets/earncoins/'+(checkNumber(7)?'jb2':'jb1')+'-7-2.png')"/>
						<span class="txt">×3</span>
					</div>
					<img class="lb2" :src="require('../../assets/earncoins/'+(checkNumber(7)?'lb2':'lb1')+'.png')"/>
					<img class="rt2" :src="require('../../assets/earncoins/'+(checkNumber(7)?'rt2':'rt1')+'.png')"/>
					<div class="btn" @click="item_click(7)">{{ $t("CHECKEDIN") }}</div>
				</div>
			</div>
		</div>
		
		<div class="group3">
			<div class="item">
				<div class="left">{{ $t("FAQ") }}</div>
				<div class="right">
					<span class="tit">{{ $t("FAQ_tit1") }}</span>
					<span class="sub">{{ $t("FAQ_sub1") }}</span>
				</div>
			</div>
			<div class="item">
				<div class="left">{{ $t("FAQ") }}</div>
				<div class="right">
					<span class="tit">{{ $t("FAQ_tit2") }}</span>
					<span class="sub">{{ $t("FAQ_sub2") }}</span>
				</div>
			</div>
			<div class="item">
				<div class="left">{{ $t("FAQ") }}</div>
				<div class="right">
					<span class="tit">{{ $t("FAQ_tit3") }}</span>
					<span class="sub">{{ $t("FAQ_sub3") }}</span>
				</div>
			</div>
		</div>
		
		<div class="donghua" v-if="show_donghua">
			<img class="img" v-if="coin_count==1" src="../../assets/earncoins/donghua.png"/>
			<img class="img" v-if="coin_count==2" src="../../assets/earncoins/donghua2.png"/>
			<img class="img" v-if="coin_count==3" src="../../assets/earncoins/donghua3.png"/>
		</div>
		
	</div>
</template>

<script>
	import args from "@/common/args.js"
	import {startLoading, endLoading} from "../../common/loading.js"
	
	let timeout_countdown = 0;
	
	function sleep(time){
		return new Promise((resolve) => setTimeout(resolve, time));
	}
	
	export default {
		data() {
			return {
				dd: 0,
				hh: 0,
				mm: 0,
				
				countdown: 0, // 倒计时总时间
				currRound: 0, // 当前第几轮
				currCard: 0, // 当前选框的卡片
				// kaiCard: 0, // 当前所开卡片最大号
				kaiCards: [], // 当前所开卡片号
				
				show_donghua: false,
				coin_count: 1
			}
		},
		created() {
			
			startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
			
			this.getData();
			
			setTimeout(_ => endLoading(), 1000);
			
		},
		methods: {
			async getData() {
				let week2 = 56*args.day_home*3600;
				
				let rest = await this.$http.get("api-open/newbie_checkin/countdown");
				if(!rest.body.data) return;
				
				let st = rest.body.data.start_date;
				this.currRound = rest.body.data.round;
				let now = parseInt(new Date().getTime()/1000);
				
				let count = st+week2-now;
				this.setCountdown(count);
				
				this.countdown = count;
				this.setCurrentCard(count);
				
				// console.log("countdown", this.countdown);
				
				this.getSingin();
			},
			async getSingin() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				let rest = await this.$http.get(`api-open/newbie_checkin/sign-in?address=${account}&round=${this.currRound}`);
				// console.log(123 , rest); 
				
				if(rest.body.error || !rest.body.data) return;
				
				if(rest.body.data.length == 0) this.currCard = 1;
				else {
					let obj = rest.body.data[rest.body.data.length - 1];
					let num = obj.number;
					let ct = obj.create_time;
					let cha = ((new Date().getUTCTime()) - (new Date(ct).getZero())) / 1000 / 3600;
					if(cha > args.day_home) {
						this.currCard = num + 1;
					}
				}
				
				this.kaiCards = [];
				rest.body.data.forEach(iv => {
					this.kaiCards.push(iv.number);
				});
				
				if(this.dd == 0 && this.hh == 0 && this.mm == 0) {
					this.currCard = 0;
				}
			},
			setCountdown(count) {
				let d = count / 24 / 3600;
				let dy = count % (24 * 3600);
				
				let h = dy / 3600;
				let hy = dy % 3600;
				
				let m = hy / 60;
				let my = hy % 60;
				
				d = parseInt(d);
				h = parseInt(h);
				m = parseInt(m);
				
				if(d < 0) d = 0;
				if(h < 0) h = 0;
				if(m < 0) m = 0;
				
				if(d < 10) d = "0" + d;
				if(h < 10) h = "0" + h;
				if(m < 10) m = "0" + m;
				
				this.dd = d;
				this.hh = h;
				this.mm = m;
				
				// console.log(count, d, dy, h, hy, m, my);
				
				count--;
				clearTimeout(timeout_countdown);
				timeout_countdown = setTimeout(_ => {
					this.setCountdown(count);
					if(count % 60 == 0) this.getData();
				}, 1000)
				
			},
			setCurrentCard(count) {
				// let d = parseInt(count / 24 / 3600);
				// let curr = 7 - d;
				// this.currCard = curr; 
			},
			checkNumber(num) {
				let ft = this.kaiCards.filter(iv => iv == num);
				return ft.length>0;
			},
			
			async item_click(num) {
				if(this.currCard != num) return;
				
				
				startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
				
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				let rest = await this.$http.post("api-open/newbie_checkin/sign-in", {
					address: account,
					round: this.currRound,
					number: this.currCard
				});
				
				this.getData();
				this.$bus.$emit("getTimes");
				
				await sleep(2000);
				
				endLoading();
				
				if(rest.body.error) {
					this.$message({
						type: 'warning',
						message: this.$i18n.locale=='en-US'?rest.body.msg_en:rest.body.msg
					});
					return;
				}
				
				let coins = [1, 1, 2, 1, 1, 1, 3];
				this.coin_count = coins[this.currCard-1];
				this.show_donghua = true;
				setTimeout(_ => this.show_donghua = false, 1300);
				
			},
		},
	}
</script>

<style lang="scss" scoped>
	.countdown {
		display: flex;
		align-items: center;
		.refresh {
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			margin-right: 18px;
		}
		.time {
			display: flex;
			align-items: flex-end;
			.tm {
				width: 36px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				background: #111A32;
				border-radius: 6px;
				// padding: 8px;
				box-sizing: border-box;
				margin-left: 6px;
				
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFB72D;
			}
			.un {
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #52618C;
				margin-left: 6px;
			}
		}
	}
	
	.group1 {
		height: 200px;
		background: #1C2849;
		border-radius: 30px 30px 0px 0px;
		margin-top: 108px;
		box-sizing: border-box;
		padding: 40px;
		position: relative;
		
		.row1 {
			font-size: 40px;
			font-family: Impact;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
		}
		.row2 {
			width: 604px;
			height: 30px;
			background: linear-gradient(90deg, rgba(255,183,45,0.8), rgba(255,183,45,0));
			margin: 22px 0;
			box-sizing: border-box;
			padding: 0 10px;
			
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
			white-space: nowrap;
		}
		.row3 {
			display: flex;
			
			.num {
				display: flex;
				align-items: center;
				
				.txt {
					font-size: 20px;
					font-family: Alibaba PuHuiTi;
					font-weight: bold;
					color: #FFFFFF;
				}
				.prog {
					width: 185px;
					height: 7px;
					background: #57617D;
					border-radius: 4px;
					margin: 0 13px;
					.c {
						width: 93px;
						height: 7px;
						background: #FFB72D;
						border-radius: 4px;
					}
				}
				.unit {
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
				}
			}
			.num2 {
				margin-left: 62px;
			}
		}
		
		.dbx {
			position: absolute;
			right: 0%;
			top: 0%;
		}
	}
	
	.group2 {
		width: 1300px;
		height: 590px;
		background: #1C2849;
		border-radius: 0px 0px 30px 30px;
		margin-top: 14px;
		padding: 36px 40px;
		box-sizing: border-box;
		
		.row2 {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			
			.left {
				display: flex;
				flex-wrap: wrap;
				flex: 1;
				justify-content: space-between;
				box-sizing: border-box;
			}
			
			.item {
				width: 260px;
				height: 246px;
				background: #473A8C;
				border-radius: 10px;
				position: relative;
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				align-items: center;
				&:nth-child(n+4) {
					margin-top: 18px;
				}
				
				.num {
					font-size: 50px;
					font-family: Impact;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
					position: absolute;
					left: 11px;
					top: 13px;
				}
				.pt2 {
					// width: 132px;
					height: 132px;
					display: flex;
					align-items: flex-end;
					margin-bottom: 30px;
					.img {
						height: 100%;
						object-fit: contain;
					}
					.txt {
						font-size: 30px;
						font-family: Impact;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 33px;
						transform: translate(-20px, -20px);
					}
				}
				.lb2 {
					position: absolute;
					left: 10px;
					bottom: 10px;
				}
				.rt2 {
					position: absolute;
					right: 10px;
					top: 10px;
				}
				.btn {
					position: absolute;
					bottom: 39px;
					width: 109px;
					height: 30px;
					background: #6656B6;
					
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #B9ADF7;
					line-height: 30px;
					text-align: center;
				}
			}
			.curr {
				.btn {
					background: #FFB72D;
					color: #111A32;
					cursor: pointer;
				}
			}
			
			.last {
				width: 340px;
				height: 510px;
				box-sizing: border-box;
				margin-left: 33px;
				overflow: hidden;
				.qdt1 {
					position: absolute;
					width: 100%;
					height: 100%;
				}
				.pt2 {
					flex-direction: column;
					z-index: 0;
					.txt {
						width: 100px;
						text-align: center;
						// background-color: #FFB72D;
						transform: translate(-15px, 10px);
					}
				}
			}
			
			.kai {
				background: #111A32;
				.num {
					color: #2F374D;
				}
				.pt2 {
					.txt {
						color: #2F374D;
					}
				}
				.btn {
					background: #2F374D;
					color: #828EAC;
				}
			}
		}
		
	}
	
	.group3 {
		width: 1300px;
		height: 600px;
		background: #1C2849;
		border-radius: 20px;
		margin-top: 40px;
		box-sizing: border-box;
		padding: 30px 35px;
		
		.item {
			display: flex;
			margin-top: 30px;
			&:first-of-type {
				margin-top: 0px;
			}
			&:last-of-type {
				.right {
					border-bottom: unset;
				}
			}
		}
		
		.left {
			font-size: 20px;
			font-family: Alibaba PuHuiTi;
			font-weight: bold;
			color: #727D9B;
			line-height: 33px;
		}
		
		.right {
			display: flex;
			flex-direction: column;
			margin-left: 18px;
			border-bottom: 1px solid #57617D;
			padding-bottom: 29px;
			
			.tit {
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #727D9B;
				line-height: 33px;
			}
			.sub {
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 33px;
				margin-top: 6px;
			}
		}
	}
	
	
	.donghua {
		position: fixed;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		
		.img {
			
		}
	}
</style>