import CryptoJS from 'crypto-js'
import args from "./args.js"

Date.prototype.getUTCTime = function(){
	return new Date(
		this.getUTCFullYear(),
		this.getUTCMonth(),
		this.getUTCDate(),
		this.getUTCHours(),
		this.getUTCMinutes(),
		this.getUTCSeconds()
	).getTime();
}

Date.prototype.getZero = function() {
    return new Date(
		this.getFullYear(),
		this.getMonth(),
		this.getDate(),
		0,
		0,
		0
	).getTime();
}

Date.prototype.format = function(fmtStr) { 
	let fmt = fmtStr || "yyyy-MM-dd hh:mm:ss";
    var o = { 
        "M+" : this.getMonth()+1,                 //月份 
        "d+" : this.getDate(),                    //日 
        "h+" : this.getHours(),                   //小时 
        "m+" : this.getMinutes(),                 //分 
        "s+" : this.getSeconds(),                 //秒 
        "q+" : Math.floor((this.getMonth()+3)/3), //季度 
        "S"  : this.getMilliseconds()             //毫秒 
    }; 
    if(/(y+)/.test(fmt)) {
            fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
    }
     for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
             fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
         }
     }
    return fmt; 
}

export default {
	getImage(val) {
		if (val) {
			return args.server_host + val;
		}
	},
	getCookie(name) {
		var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
		if (arr = document.cookie.match(reg))
			return (arr[2]);
		else
			return null;
	},
	setCookie(c_name, value, expiredays) {
		var exdate = new Date();
		exdate.setDate(exdate.getDate() + expiredays);
		document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
	},
	delCookie(name) {
		var exp = new Date();
		exp.setTime(exp.getTime() - 1);
		var cval = getCookie(name);
		if (cval != null)
			document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
	},
	
	getKeyHash(strKey) {
		let token = "0x" + CryptoJS.MD5(strKey).toString().substr(8,16);
		return token;
	},
	checkToken(strKey, strPassword, strToken) {
		let token = getKeyHash(strKey + strPassword);
		return (strToken == token)
	},
	
	sleep(time){
		return new Promise((resolve) => setTimeout(resolve, time));
	}

}
