export default {
	// server_host: "http://localhost:8000",
	//server_host: "http://47.242.107.228:8015",
	 server_host: "https://admin.cardaether.com",
	
	CARD_NFT_CONTRACT_ADDRESS: "0x847c518de64ac7f0accb45c6e491c8c512b5c882",
	CARD_SERVICE_CONTRACT_ADDRESS: "0xf2648b202e6c453f2e56a7967b8d1e0eb570024d",
	CARD_NFT_AMOUNT: 10000,
	
	day_home: 24,
	
	wallet_chain: {
		chainId: "0x1",
		rpcUrls: ["https://mainnet.infura.io/v3/"],
		chainName: "以太坊主网",
		blockExplorerUrls: ["https://etherscan.io"],
		nativeCurrency: {
			name: "ETH",
			symbol: "ETH",
			decimals: 18
		}
	}

}