<template>
	<div class="com-content">
		<div class="group1">
			<div class="row1">{{ $t("DaysLegacy56_title") }}</div>
			<div class="row2">{{ $t("DaysLegacy56_subtitle") }}</div>
			
			<div class="row3 countdown">
				<span class="refresh">{{ $t("DaysLegacy56_countdown") }}</span>
				<div class="time">
					<span class="tm">{{ m_dd }}</span>
					<span class="un">{{ $t("time_D") }}</span>
					<span class="tm">{{ m_hh }}</span>
					<span class="un">{{ $t("time_H") }}</span>
					<span class="tm">{{ m_mm }}</span>
					<span class="un">{{ $t("time_M") }}</span>
				</div>
			</div>
			
			<img class="dbx" src="../../assets/earncoins/dbx.png"/>
			<div class="PURCHASE" :class="{ 'dis': getPurchaseDisabled() }" @click="purchase_click">{{ $t("PURCHASE") }}</div>
		</div>
		
		<div class="group2">
			<div class="row1">
				<div class="left">
					<span class="sp"></span>
					<span class="sign">{{ $t("FreeLegacy_round") }}</span>
					<span class="rounds">{{ currRound_w }}/4，{{ $t("DaysLegacy56_rounds") }}</span>
				</div>
				<div class="countdown">
					<span class="refresh">{{ $t("FreeLegacy_countdown") }}</span>
					<div class="time">
						<span class="tm">{{ w_dd }}</span>
						<span class="un">{{ $t("time_D") }}</span>
						<span class="tm">{{ w_hh }}</span>
						<span class="un">{{ $t("time_H") }}</span>
						<span class="tm">{{ w_mm }}</span>
						<span class="un">{{ $t("time_M") }}</span>
					</div>
				</div>
			</div>
			<div class="row2">
				<div class="item" :class="{ 'curr': currCard==1, 'kai':checkNumber(1) }" @click="item_click(1)">
					<span class="num">01</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(1)?'pt2':'pt1')+'.png')"/>
					<img class="qd" v-if="checkNumber(1)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==2, 'kai':checkNumber(2) }" @click="item_click(2)">
					<span class="num">02</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(2)?'jb2':'jb1')+'.png')"/>
					<img class="qd" v-if="checkNumber(2)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==3, 'kai':checkNumber(3) }" @click="item_click(3)">
					<span class="num">03</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(3)?'pt2':'pt1')+'.png')"/>
					<img class="qd" v-if="checkNumber(3)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==4, 'kai':checkNumber(4) }" @click="item_click(4)">
					<span class="num">04</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(4)?'jb2':'jb1')+'.png')"/>
					<img class="qd" v-if="checkNumber(4)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==5, 'kai':checkNumber(5) }" @click="item_click(5)">
					<span class="num">05</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(5)?'pt2':'pt1')+'.png')"/>
					<img class="qd" v-if="checkNumber(5)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==6, 'kai':checkNumber(6) }" @click="item_click(6)">
					<span class="num">06</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(6)?'jb2':'jb1')+'.png')"/>
					<img class="qd" v-if="checkNumber(6)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==7, 'kai':checkNumber(7) }" @click="item_click(7)">
					<span class="num">07</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(7)?'pt2':'pt1')+'.png')"/>
					<img class="qd" v-if="checkNumber(7)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==8, 'kai':checkNumber(8) }" @click="item_click(8)">
					<span class="num">08</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(8)?'jb2':'jb1')+'.png')"/>
					<img class="qd" v-if="checkNumber(8)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==9, 'kai':checkNumber(9) }" @click="item_click(9)">
					<span class="num">09</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(9)?'pt2':'pt1')+'.png')"/>
					<img class="qd" v-if="checkNumber(9)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==10, 'kai':checkNumber(10) }" @click="item_click(10)">
					<span class="num">10</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(10)?'jb2':'jb1')+'.png')"/>
					<img class="qd" v-if="checkNumber(10)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==11, 'kai':checkNumber(11) }" @click="item_click(11)">
					<span class="num">11</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(11)?'pt2':'pt1')+'.png')"/>
					<img class="qd" v-if="checkNumber(11)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==12, 'kai':checkNumber(12) }" @click="item_click(12)">
					<span class="num">12</span>
					<img class="pt2" :src="require('../../assets/earncoins/'+(checkNumber(12)?'jb2':'jb1')+'.png')"/>
					<img class="qd" v-if="checkNumber(12)" src="../../assets/earncoins/qd.svg"/>
				</div>
				
			</div>
		</div>
		
		<div class="group3">
			<div class="item">
				<div class="left">{{ $t("FAQ") }}</div>
				<div class="right">
					<span class="tit">{{ $t("FAQ_tit1") }}</span>
					<span class="sub">{{ $t("FAQ_sub1") }}</span>
				</div>
			</div>
			<div class="item">
				<div class="left">{{ $t("FAQ") }}</div>
				<div class="right">
					<span class="tit">{{ $t("FAQ_tit2") }}</span>
					<span class="sub">{{ $t("FAQ_sub2") }}</span>
				</div>
			</div>
			<div class="item">
				<div class="left">{{ $t("FAQ") }}</div>
				<div class="right">
					<span class="tit">{{ $t("FAQ_tit3") }}</span>
					<span class="sub">{{ $t("FAQ_sub3") }}</span>
				</div>
			</div>
		</div>
		
		
		
		<el-dialog custom-class="details-dialog" :visible.sync="timesDialogVisible" width="400px">
			<div class="content">
				<div class="left">
					<img src="../../assets/recharge-bg.png"/>
				</div>
				<div class="right">
					<div>BUY</div>
					<div class="num">
						<input type="text" v-model="rec_times" disabled onkeyup="this.value=this.value.replace(/[^\d]/g,'') " onafterpaste="this.value=this.value.replace(/[^\d]/g,'') " />
						<span class="num_unit">{{ $t("dayslegacy56") }}</span>
					</div>
					<div>Total: {{getcost()}} ETH</div>
					<div class="btn">
						<span class="btn1" style="cursor:pointer;" @click="timesDialogVisible=false">CANCEL</span>
						<span class="btn2" style="cursor:pointer;background: #111A32;" @click="recharge_click">CONFIRM</span>
					</div>
				</div>
			</div>
		</el-dialog>
		
		
		<div class="donghua" v-if="show_donghua">
			<img class="img" src="../../assets/earncoins/donghua.png"/>
		</div>
		
	</div>
</template>

<script>
	import args from "@/common/args.js"
	import {startLoading, endLoading} from "../../common/loading.js"
	import { service } from "../../common/web3/index.js"
	
	import { getBalance } from "@/common/web3/index.js"
		
	let timeout_countdown_m = 0;
	let timeout_countdown_w = 0;
	
	function sleep(time){
		return new Promise((resolve) => setTimeout(resolve, time));
	}
	
	export default {
		data() {
			return {
				m_dd: 0,
				m_hh: 0,
				m_mm: 0,
			
				w_dd: 0,
				w_hh: 0,
				w_mm: 0,
				
				timesDialogVisible: false,
				rec_times: "1",
				
				countdown_m: 0, // 56天 倒计时总时间
				countdown_w: 0, // 14天 倒计时总时间
				currRound_m: 0, // 56天 当前第几轮
				currRound_w: 0, // 14天 当前第几轮
				currCard: 0, // 当前选框的卡片
				kaiCard: 0, // 当前所开卡片最大号
				kaiCards: [], // 当前所开卡片号
				
				coinCount: 0, // 当前签到金币数量
				
				show_donghua: false
			}
		},
		created() {
			
			startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
			
			this.getData();
			
			setTimeout(_ => endLoading(), 1000);
			
		},
		methods: {
			async getData() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				if (!account) return;
				
				let month2 = 56*args.day_home*3600;
				
				let rest = await this.$http.get(`api-open/days_legacy56/purchase?address=${account}`);
				// console.log(1234, rest);
				
				if(!rest.body.data) {
					this.m_dd = 56;
					return;
				}
				
				let st = rest.body.data.start_date;
				let now = parseInt(new Date().getTime()/1000);
				let count = st+month2-now;
				// console.log(12,count);
				this.countdown_m = count;
				this.currRound_m = rest.body.data.round;
				
				this.setCountdown(count);
				
				this.getWeekData();
			},
			async getWeekData() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				if (!account) {
					this.$message({
						type: 'warning',
						message: this.$i18n.locale=='en-US'?'Please sign in to MetaMask first':'请先登录MetaMask'
					});
					return;
				}
				
				let week2 = 14*args.day_home*3600;
				
				let rest = await this.$http.get(`api-open/days_legacy56/countdown_week?address=${account}&round=${this.currRound_m}`);
				// console.log(12,rest); 
				if(!rest.body.data) {
					return;
				}
				let st = rest.body.data.start_date;
				let now = parseInt(new Date().getTime()/1000);
				
				let count = st+week2-now;
				this.countdown_w = count;
				this.currRound_w = rest.body.data.round_week;
				// this.currCard = rest.body.data.day;
				
				this.setCountdown2(count);
				
				this.getSingin();
			},
			async getSingin() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				let rest = await this.$http.get(`api-open/days_legacy56/sign-in?address=${account}&round=${this.currRound_m}&round_week=${this.currRound_w}`);
				// console.log(131,rest, this.currRound_m,this.currRound_w);  
				if(!rest.body.data) return;
				
				if(rest.body.data.length == 0) this.currCard = 1;
				else {
					let obj = rest.body.data[rest.body.data.length - 1];
					let num = obj.number;
					let ct = obj.create_time;
					let cha = ((new Date().getUTCTime()) - (new Date(ct).getZero())) / 1000 / 3600;
					if(cha > args.day_home) {
						this.currCard = num + 1;
					}
				}
				
				this.kaiCards = [];
				rest.body.data.forEach(iv => {
					this.kaiCards.push(iv.number);
					this.coinCount += iv.coin;
				});
				
				if(this.w_dd == 0 && this.w_hh == 0 && this.w_mm == 0) {
					this.currCard = 0;
				}
			},
			checkNumber(num) {
				let ft = this.kaiCards.filter(iv => iv == num);
				return ft.length>0;
			},
			
			getPurchaseDisabled() {
				let dis = (this.m_dd>0 && this.m_dd<56 ) || this.m_hh>0 || this.m_mm>0;
				return dis;
			},
			purchase_click() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				if (!account) {
					this.$bus.$emit("account_login");
					return;
				}
				
				if(this.getPurchaseDisabled()) {
					return;
				}
				this.timesDialogVisible=true;
			},
			
			
			getcost() {
				if(!this.rec_times || !(window.setting && window.setting.price)) {
					return 0;
				}
				return this.rec_times * (window.setting && window.setting.price) * 10;
			},
			async recharge_click() {
				try{
					let account = this.$utils.getCookie("CHAIN_ADDRESS");
					if(!account) return;
					
					let cost = this.getcost();
					if(cost <= 0) {
						this.$message({ type: "warning", message: this.$i18n.locale=='en-US'?"Please enter the recharge count":"请输入充值次数" });
						endLoading();
						return;
					}
					
					let balance = await getBalance(account);
					if(!balance || balance=="0" || balance<cost) {
						this.$message({ type: "warning", message: this.$i18n.locale=='en-US'?"Insufficient ETH balance":"ETH余额不足" });
						return;
					}
					
					startLoading(this.$i18n.locale=='en-US'?"Call up Metamask":"调起Metamask...");
					this.timesDialogVisible = false;
					
					let hash = await service(account, cost);
					console.log("service", hash, hash==false);
					endLoading();
					if(!hash) {
						this.$message({ type: "warning", message: this.$i18n.locale=='en-US'?"Recharge failed":"充值失败" });
						endLoading();
						return;
					}
					startLoading(this.$i18n.locale=='en-US'?"Recharging in progress":"正在充值中...");
					
					let ret2 = await this.$http.post("api-open/recharge_log/save", {
						address: account, amount: cost, times: this.rec_times*10, legacy56:1, chain_info: hash
					})
					
					let status = 0; 
					while(status == 0) {
						await sleep(2000);
						let ret_status = await this.$http.get("api-open/recharge_log/status?hash="+hash);
						console.log(120,ret_status);
						status = ret_status.body.data || 0;
					}
					await sleep(2000);
					// await this.$http.post("api-open/days_legacy56/purchase", {
					// 	address: account
					// });
					
					this.$bus.$emit("getTimes");
					
					await this.getData();
					
					await sleep(2000);
					
					endLoading();
					this.$message({ type: "success", message: this.$i18n.locale=='en-US'?"Recharge successful":"充值成功" });
					
				} catch(e) {
					console.log(e);
					endLoading();
				}
			},
			setCountdown(count) {
				let d = count / 24 / 3600;
				let dy = count % (24 * 3600);
				
				let h = dy / 3600;
				let hy = dy % 3600;
				
				let m = hy / 60;
				let my = hy % 60;
				
				d = parseInt(d);
				h = parseInt(h);
				m = parseInt(m);
				
				if(d < 0) d = 0;
				if(h < 0) h = 0;
				if(m < 0) m = 0;
				
				if(d < 10) d = "0" + d;
				if(h < 10) h = "0" + h;
				if(m < 10) m = "0" + m;
				
				this.m_dd = d;
				this.m_hh = h;
				this.m_mm = m;
				
				// console.log(count, m_dd, m_hh, m_mm);
				
				count--;
				clearTimeout(timeout_countdown_m);
				timeout_countdown_m = setTimeout(_ => {
					this.setCountdown(count);
				}, 1000)
			},
			setCountdown2(count) {
				let d = count / 24 / 3600;
				let dy = count % (24 * 3600);
				
				let h = dy / 3600;
				let hy = dy % 3600;
				
				let m = hy / 60;
				let my = hy % 60;
				
				d = parseInt(d);
				h = parseInt(h);
				m = parseInt(m);
				
				if(d < 0) d = 0;
				if(h < 0) h = 0;
				if(m < 0) m = 0;
				
				if(d < 10) d = "0" + d;
				if(h < 10) h = "0" + h;
				if(m < 10) m = "0" + m;
				
				this.w_dd = d;
				this.w_hh = h;
				this.w_mm = m;
				
				if(this.m_dd == 0 && this.m_hh == 0 && this.m_mm == 0)  {
					this.w_dd = 0;
					this.w_hh = 0;
					this.w_mm = 0;
				}
				
				// console.log(count, w_dd, w_hh, w_mm);
				
				count--;
				clearTimeout(timeout_countdown_w);
				timeout_countdown_w = setTimeout(_ => {
					this.setCountdown2(count);
					if(count % 60 == 0) this.getData();
				}, 1000)
				
			},
			
			async item_click(num) {
				if(this.currCard != num || this.checkNumber(num)) return;
				
				if(!this.checkParent(num-1)) {
					let zh = "昨日没签到";
					let en = "Didn't check in yesterday"; 
					this.$message({
						type: 'warning',
						message: this.$i18n.locale=='en-US'?en:zh
					});
					return;
				}
				
				startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
				
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				let rest = await this.$http.post("api-open/days_legacy56/sign-in", {
					address: account,
					round: this.currRound_m,
					round_week: this.currRound_w,
					number: this.currCard
				});
				
				await this.getData();
				this.$bus.$emit("getTimes");
				
				await sleep(2000);
				
				endLoading();
				
				if(rest.body.error) {
					this.$message({
						type: 'warning',
						message: this.$i18n.locale=='en-US'?rest.body.msg_en:rest.body.msg
					});
					return;
				}
				
				let coin = num % 2 == 0 ? 1 : 0; // 当前金币个数
				if(coin == 1) {
					this.show_donghua = true;
					setTimeout(_ => this.show_donghua = false, 1300);
				}
				
			},
			checkParent(num) {
				if (num == 0) {
					return true;
				} else if (num % 2 == 0) {
					return true;
				}
				let ft = this.kaiCards.filter(iv => iv == num);
				return ft.length>0;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.countdown {
		display: flex;
		align-items: center;
		.refresh {
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			margin-right: 18px;
		}
		.time {
			display: flex;
			align-items: flex-end;
			.tm {
				width: 36px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				background: #111A32;
				border-radius: 6px;
				// padding: 8px;
				box-sizing: border-box;
				margin-left: 6px;
				
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFB72D;
			}
			.un {
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #52618C;
				margin-left: 6px;
			}
		}
	}
	
	.group1 {
		height: 200px;
		background: #1C2849;
		border-radius: 30px 30px 0px 0px;
		margin-top: 108px;
		box-sizing: border-box;
		padding: 40px;
		position: relative;
		
		.row1 {
			font-size: 40px;
			font-family: Impact;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
		}
		.row2 {
			width: 604px;
			height: 30px;
			background: linear-gradient(90deg, rgba(255,183,45,0.8), rgba(255,183,45,0));
			margin: 22px 0;
			box-sizing: border-box;
			padding: 0 10px;
			
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
			white-space: nowrap;
		}
		.row3 {
			display: flex;
			
			.num {
				display: flex;
				align-items: center;
				
				.txt {
					font-size: 20px;
					font-family: Alibaba PuHuiTi;
					font-weight: bold;
					color: #FFFFFF;
				}
				.prog {
					width: 185px;
					height: 7px;
					background: #57617D;
					border-radius: 4px;
					margin: 0 13px;
					.c {
						width: 93px;
						height: 7px;
						background: #FFB72D;
						border-radius: 4px;
					}
				}
				.unit {
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
				}
			}
			.num2 {
				margin-left: 62px;
			}
		}
		
		.dbx {
			position: absolute;
			right: 0%;
			top: 0%;
		}
		.PURCHASE {
			width: 133px;
			height: 40px;
			position: absolute;
			right: 43px;
			bottom: 29px;
			text-align: center;
			line-height: 40px;
			cursor: pointer;
			background-image: url("../../assets/earncoins/tab-select.svg");
		}
		.PURCHASE.dis {
			background-color: #999;
			background-image: unset;
			cursor: unset;
		}
	}
	
	.group2 {
		width: 1300px;
		height: 700px;
		background: #1C2849;
		border-radius: 0px 0px 30px 30px;
		margin-top: 14px;
		padding: 36px 40px;
		box-sizing: border-box;
		
		.row1 {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			
			.left {
				display: flex;
				align-items: center;
				.sp {
					display: inline-block;
					width: 7px;
					height: 19px;
					background: #FFFFFF;
					border-radius: 4px;
				}
				.sign {
					font-size: 20px;
					font-family: Alibaba PuHuiTi;
					font-weight: bold;
					color: #FFFFFF;
					margin-left: 15px;
				}
				.rounds {
					width: 210px;
					height: 30px;
					background: #273458;
					border-radius: 15px;
					text-align: center;
					margin-left: 25px;
					
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #7582A7;
					line-height: 30px;
				}
			}
			
		}
		
		.row2 {
			margin-top: 57px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			box-sizing: border-box;
			
			.item {
				width: 181px;
				height: 246px;
				background: #473A8C;
				border-radius: 10px;
				position: relative;
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				align-items: center;
				&:nth-child(n+7) {
					margin-top: 32px;
				}
				
				.num {
					font-size: 50px;
					font-family: Impact;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
					position: absolute;
					left: 11px;
					top: 13px;
				}
				.pt2 {
					width: 132px;
					height: 132px;
				}
				.qd {
					width: 30px;
					height: 30px;
					position: absolute;
					right: 12px;
					bottom: 12px;
				}
			}
			.curr {
				border: 2px solid #FFB72D;
				cursor: pointer;
			}
			.kai {
				background: #111A32;
				border: unset;
				cursor: unset;
				.num {
					color: #2F374D;
				}
			}
		}
		
	}
	
	.group3 {
		width: 1300px;
		height: 600px;
		background: #1C2849;
		border-radius: 20px;
		margin-top: 40px;
		box-sizing: border-box;
		padding: 30px 35px;
		
		.item {
			display: flex;
			margin-top: 30px;
			&:first-of-type {
				margin-top: 0px;
			}
			&:last-of-type {
				.right {
					border-bottom: unset;
				}
			}
		}
		
		.left {
			font-size: 20px;
			font-family: Alibaba PuHuiTi;
			font-weight: bold;
			color: #727D9B;
			line-height: 33px;
		}
		
		.right {
			display: flex;
			flex-direction: column;
			margin-left: 18px;
			border-bottom: 1px solid #57617D;
			padding-bottom: 29px;
			
			.tit {
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #727D9B;
				line-height: 33px;
			}
			.sub {
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 33px;
				margin-top: 6px;
			}
		}
	}
	
	
	
	
	
	
	.details-dialog {
		.content {
			width: 400px;
			height: 209px;
			background: #FFFFFF;
			border-radius: 20px;
			margin-top: 30vh;
			overflow: hidden;
			display: flex;
			padding: 19px;
			box-sizing: border-box;
			
			.left {
				border-radius: 6px;
				overflow: hidden;
				img {
					width: 119px;
					height: 100%;
				}
			}
			.right {
				margin-left: 20px;
				display: flex;
				flex-direction: column;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #222222;
				line-height: 33px;
				
				.num {
					margin: 5px 0;
					input {
						border: unset;
						width: 223px;
						height: 36px;
						background: #EBEBEB;
						border-radius: 6px;
						padding: 0 10px;
						box-sizing: border-box;
					}
					position: relative;
					
					.num_unit {
						position: absolute;
						right: 10px;
						line-height: 36px;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #666666;
					}
				}
				.btn {
					display: flex;
					justify-content: space-between;
					margin-top: 20px;
					
					span {
						width: 106px;
						height: 30px;
						background: #8C8C8C;
						border-radius: 15px;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 30px;
						display: inline-block;
						text-align: center;
					}
				}
			}
		}
	}
	
	
	.donghua {
		position: fixed;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		
		.img {
			
		}
	}
</style>