const Web3 = require('web3');
import { ethers } from "ethers";
import { abi as abi_nft } from "@/common/artifacts/contracts/CardNFT.sol/CardNFT.json"
import { abi as abi_service } from "@/common/artifacts/contracts/CardService.sol/CardService.json"
import args from "@/common/args.js"
import utils from "@/common/utils"

const web3 = new Web3(Web3.givenProvider);

function sleep(time){
	return new Promise((resolve) => setTimeout(resolve, time));
}

export const getAccount = async () => {
	if (!window.ethereum) {
		return;
	}
	const accounts = await window.ethereum.request({
		method: "eth_requestAccounts",
	});
	if (accounts.length > 0) {
		return accounts[0];
	}
	console.log(accounts)
}

export const addMaticNetwork = async () => {
	try {
		await window.ethereum.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId: args.wallet_chain.chainId }],
		});
		
	} catch (e) {
		console.log("wallet_chain",e);
		
		if(e.code == 4902) {
			await window.ethereum.request({
			  method: "wallet_addEthereumChain",
			  params: [{
				chainId: args.wallet_chain.chainId,
				rpcUrls: args.wallet_chain.rpcUrls,
				chainName: args.wallet_chain.chainName,
				nativeCurrency: args.wallet_chain.nativeCurrency,
				blockExplorerUrls: args.wallet_chain.blockExplorerUrls
			  }]
			});
		}
	}
}

export const getBalance = async (address) => {
	await addMaticNetwork();
	
	const amount = await window.ethereum.request({
	    method: "eth_getBalance",
	    params: [
	        address,
	        "latest",
	    ],
	});
	// console.log(111, amount);
	let amt = eval(amount);
	let amt2 = web3.utils.fromWei(amt.toString());
	// console.log("getBalance",amt2);
	
	return amt2;
}


export const getFee = async () => {
	await addMaticNetwork();
	let contract = await new web3.eth.Contract(abi_nft, args.CARD_NFT_CONTRACT_ADDRESS);
	let fee = await contract.methods.getFee().call();
	// let fee = web3.utils.fromWei(feeW);
	return fee;
}

export const getReceiver = async () => {
	await addMaticNetwork();
	let contract = await new web3.eth.Contract(abi_nft, args.CARD_NFT_CONTRACT_ADDRESS);
	let addr = await contract.methods.getReceiver().call();
	return addr;
}


export const mintNft = async (address, tokenId, url) => {
	await addMaticNetwork();

	let price = await web3.eth.getGasPrice();
	// console.log("mintNft", abi_nft, address, window.setting, price);
	
	// let cost = web3.utils.toWei(window.setting.cost.toString());
	let cost = await getFee();
	// debugger;
	// let wei = parseInt(web3.utils.toWei(window.setting.cost.toString())).toString(16);
	// let wei = (parseInt(cost) + parseInt(price)).toString(16);
	let wei = (parseInt(cost) + parseInt(price)).toString(16);
	let contract = await new web3.eth.Contract(abi_nft, args.CARD_NFT_CONTRACT_ADDRESS);
	const transactionParameters = {
		to: args.CARD_NFT_CONTRACT_ADDRESS,
		from: address,
		value: wei,
		// gasLimit: "0x02540be400",
		// gasPrice: "0x07A12000",  // 0x7a1200
		data: contract.methods.mint(address,tokenId,url).encodeABI(),
	};

	try {
		let tx = await window.ethereum.request({
			method: "eth_sendTransaction",
			params: [transactionParameters],
		});
		console.log("hash", tx);
		// await getTransactionReceipt(tx);
		// console.log("getTransactionReceipt");
		return tx;
	} catch (error) {
		console.log(error);
		return false;
	}
}

// 充值
export const service = async (address, cost) => {
	await addMaticNetwork();
	
	let value = web3.utils.toWei(cost.toString(), 'ether');
	let amount = web3.utils.toHex(value);
	// console.log("amount",amount);
	let contract = await new web3.eth.Contract(abi_service, args.CARD_SERVICE_CONTRACT_ADDRESS);
	const transactionParameters = {
		to: args.CARD_SERVICE_CONTRACT_ADDRESS,
		from: address,
		value: amount,
		// gasLimit: "0x02540be400",
		// gasPrice: "0x07A12000",  // 0x7a1200
		// gas: '0x76c0', // 30400
		// gasPrice: '0x9184e72a000', // 10000000000000
		data: contract.methods.send().encodeABI(),
	};
	// console.log("1122", transactionParameters);
	try {
		let tx = await window.ethereum.request({
			method: "eth_sendTransaction",
			params: [transactionParameters],
		});
		console.log("hash", tx);
		// await getTransactionReceipt(tx);
		return tx;
	} catch (error) {
		console.log(error);
		return false;
	}
}

export const getMintFee = async () => {
	let price = await web3.eth.getGasPrice();
	let cost = await getFee();
	let fee = web3.utils.fromWei((parseInt(cost) + parseInt(price)).toString());
	
	return parseFloat(fee).toFixed(8);
}

export const getTransactionReceipt = async (hash) => {
	let receipt;
	while(!receipt) {
		receipt = await web3.eth.getTransactionReceipt(hash);
		await sleep(2000);
	}
	return receipt.status;
}

