<template>
	<div class="container">
		<headbar></headbar>
		
		<div class="head">
			<img class="bgtext" src="../../assets/earncoins/bgtext.svg"/>
		</div>
		
		<div class="main">
			<div class="tab">
				<div class="btn" @click="tab_click(0)">
					<img class="img" v-if="tabIndex==0" src="../../assets/earncoins/tab-select.svg"/>
					<span class="txt">{{ $t("Freelegacy") }}</span>
				</div>
				<div class="btn" @click="tab_click(1)">
					<img class="img" v-if="tabIndex==1" src="../../assets/earncoins/tab-select.svg"/>
					<span class="txt">{{ $t("dayslegacy56") }}</span>
				</div>
				<div class="btn" @click="tab_click(2)">
					<img class="img" v-if="tabIndex==2" src="../../assets/earncoins/tab-select.svg"/>
					<span class="txt">{{ $t("Newbiecheckin") }}</span>
				</div>
				<div class="btn" @click="tab_click(3)">
					<img class="img" v-if="tabIndex==3" src="../../assets/earncoins/tab-select.svg"/>
					<span class="txt">{{ $t("LuckyDID") }}</span>
				</div>
				<div class="btn" @click="tab_click(4)">
					<img class="img" v-if="tabIndex==4" src="../../assets/earncoins/tab-select.svg"/>
					<span class="txt">{{ $t("Invitationrebates") }}</span>
				</div>
			</div>
			
			
			<FreeLegacy v-if="tabIndex==0"></FreeLegacy>
			
			<DaysLegacy56 v-if="tabIndex==1"></DaysLegacy56>
			
			<NewbieCheckin v-if="tabIndex==2"></NewbieCheckin>
			
			<LuckyDID v-if="tabIndex==3"></LuckyDID>
			
			<InvitationRebates v-if="tabIndex==4"></InvitationRebates>
			
		</div>
		
		<div style="height: 100px;"></div>
		
	</div>
</template>

<script>
	import headbar from "../../components/headbar.vue"
	import FreeLegacy from "./FreeLegacy.vue"
	import DaysLegacy56 from "./DaysLegacy56.vue"
	import NewbieCheckin from "./NewbieCheckin.vue"
	import LuckyDID from "./LuckyDID.vue"
	import InvitationRebates from "./InvitationRebates.vue"
	
	export default {
		components: {
			headbar, FreeLegacy, DaysLegacy56, NewbieCheckin, LuckyDID, InvitationRebates
		},
		data() {
			return {
				tabIndex: 0
			}
		},
		methods: {
			tab_click(idx) {
				console.log(idx);
				this.tabIndex = idx;
			},
		},
	}
	
</script>

<style lang="scss" scoped>
	.container {
		min-width: 1300px;
		min-height: 100%;
		overflow: hidden;
		background-image: url('../../assets/earncoins/ecbj.png');
		background-repeat: no-repeat;
		background-size: 100%;
	}
	
	.head {
		text-align: center;
		.bgtext {
			object-fit: contain;
			width: 60%;
			margin-top: 13%;
		}
	}
	
	
	.main {
		width: 1300px;
		// background-color: aliceblue;
		margin: auto;
		margin-top: 5%;
		position: relative;
		
		.tab {
			display: flex;
			justify-content: space-around;
			text-align: center;
			.btn {
				width: 164px;
				height: 50px;
				line-height: 50px;
				background: #FFFFFF;
				cursor: pointer;
				position: relative;
				.img {
					width: 100%;
					height: 100%;
				}
				.txt {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 164px;
					height: 50px;
				}
			}
		}
		
	}
	
</style>