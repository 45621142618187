import { render, staticRenderFns } from "./DaysLegacy56.vue?vue&type=template&id=f093a630&scoped=true&"
import script from "./DaysLegacy56.vue?vue&type=script&lang=js&"
export * from "./DaysLegacy56.vue?vue&type=script&lang=js&"
import style0 from "./DaysLegacy56.vue?vue&type=style&index=0&id=f093a630&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f093a630",
  null
  
)

export default component.exports