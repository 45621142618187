<template>
	<div class="container">
		<headbar></headbar>
		
		<div class="details">
			<img class="dtbt" :src="require(`../assets/${$i18n.locale}/policy.svg`)" />
			
			<pre v-if="$i18n.locale=='en-US'">
				Effective Date: 10/01/2023
				
				We, at Cardaether, highly value your privacy. This Privacy Policy outlines how we handle your personal information and ensure its protection.
				
				1. **Information Collection**
				   Cardaether does not collect or store any personally identifiable information (PII). We do not require registration, and no personal data is sought during the use of our services.
				
				2. **Cookies and Tracking Technologies**
				   Cardaether does not utilize cookies or any tracking technologies to gather information about our users.
				
				3. **Third-Party Services**
				   Cardaether does not integrate with any third-party services or platforms that would collect user data.
				
				4. **Children's Privacy**
				   Cardaether is not directed towards children under the age of 13. We do not knowingly collect any data from children.
				
				5. **Data Security**
				   Despite not collecting user information, we maintain robust security measures to ensure that your visit to Cardaether is safe and secure.
				
				6. **Changes to Privacy Policy**
				   Cardaether reserves the right to update or modify this Privacy Policy at any time. Changes will be effective immediately upon posting the updated policy on our website.
				
				7. **Contact Us**
				   If you have any questions or concerns about our privacy practices, please contact us at @Cardaether..
				
				Thank you for choosing Cardaether.
				
			</pre>
			<pre v-if="$i18n.locale=='zh-CN'">
				生效日期：2023年10月1日
				
				我们的Cardaether非常重视您的隐私。本隐私政策概述了我们如何处理您的个人信息并确保其安全保护。
				
				1. **信息收集**
				   Cardaether不会收集或存储任何可识别个人信息（PII）。我们不要求注册，也不在使用我们的服务期间获取任何个人数据。
				
				2. **Cookies和追踪技术**
				   Cardaether不使用Cookie或任何追踪技术来收集用户信息。
				
				3. **第三方服务**
				   Cardaether不与任何可能收集用户数据的第三方服务或平台集成。
				
				4. **儿童隐私**
				   Cardaether不面向13岁以下的儿童。我们不会有意收集儿童的任何数据。
				
				5. **数据安全**
				   尽管不收集用户信息，我们仍采取强有力的安全措施，确保您访问Cardaether的安全性和保障。
				
				6. **隐私政策更改**
				   Cardaether保留随时更新或修改本隐私政策的权利。更新政策将于在我们网站上发布更新的政策后立即生效。
				
				7. **联系我们**
				   如果您对我们的隐私做法有任何疑问或担忧，请联系我们 @Cardaether。
				
				感谢您选择Cardaether。
				
			</pre>
		</div>
	</div>
</template>

<script>
	import headbar from "../components/headbar.vue"
	
	export default {
		components: {
			headbar,
		},
	}
</script>

<style lang="scss" scoped>
	.container {
		min-width: 1305px;
		background-image: url('../assets/cjbj.png');
		background-repeat: no-repeat;
		background-size: 100%;
	}
	
	.details {
		text-align: center;
		
		.dtbt {
			height: 111px;
			object-fit: contain;
			margin-top: 184px;
		}
		
		pre {
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
			text-align: left;
			width: 1270px;
			margin: auto;
		}
	}
</style>