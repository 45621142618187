export default {
    lang: "中文",
	LinkWallets:"登录",
	
	more:"更多",
	CARD_DRAW_RULES:"卡牌活动规则",
	Beast:"野兽",
	Blood:"血",
	Chaos:"混沌",
	Corruption:"堕落",
	Craftsmanship:"工艺",
	Curse:"咒",
	Dark:"暗",
	Death:"死",
	Earth:"地",
	Earth2:"土",
	Evil:"邪",
	Fire:"火",
	Grass:"草",
	Healing:"治愈",
	Holy:"神圣",
	Ice:"冰",
	Illusion:"幻",
	Justice:"正义",
	Light:"光",
	Metal:"金属",
	Nature:"自然",
	Night:"夜",
	Rock:"岩石",
	Spirit:"灵",
	Water:"水",
	Wind:"风",
	CARDPOOL: "卡池",
	times: "次",
	MyCollection: "我的收藏",
	Signout: "登出",
	Disagree: "不同意",
	Agree: "同意",
	
	construction:"卡池正在建设中，所以请继续关注",
	confirm: "确认",
	
	ListingStatus: "上架状态",
	RARITY: "稀有度",
	RACE: "种族",
	ENDER: "性别",
	COINS: "铸造状态",
	Class: "职业",
	story: "故事背景",
	Skill: "技能",
	effect: "效果",
	
	ALL: "全部",
	OnShelves: "上架中",
	OffShelves: "已绝版",
	Common: "普通",
	rare: "稀有",
	epic: "史诗",
	Legendary: "传奇",
	Demons: "恶魔族",
	Angels: "天使族",
	Undead: "亡灵族",
	Vampires: "吸血鬼族",
	Beasts: "兽族",
	Dwarves: "矮人族",
	Humans: "人族",
	Elves: "精灵族",
	male: "男",
	female: "女",
	mint: "已铸造",
	nomint: "未铸造",
	
	PrivacyPolicy: "我们使用Cookie来改善您在我们网站上的体验。通过继续浏览，您同意我们使用Cookie。了解更多信息，包括如何控制Cookie，",
	PrivacyPolicy_link: "请参阅我们的隐私政策。",
	
	// earncoins
	EARNCOINS: "赚币",
	Freelegacy: "免费宝藏",
	dayslegacy56: "56天宝藏",
	Newbiecheckin: "新卡池送10连",
	LuckyDID: "幸运DID",
	Invitationrebates: "邀请返利",
	
	// FreeLegacy
	FreeLegacy_title: "累计签到领金币",
	FreeLegacy_subtitle: '首次累计购买10个金币或购买一次“56天宝藏”视为已订阅免费宝藏。',
	Coins: "金币",
	DayLegacy60: "56天宝藏",
	FreeLegacy_round: "本轮签到",
	FreeLegacy_countdown: "刷新倒计时",
	time_D: "D",
	time_H: "H",
	time_M: "M",
	FAQ: "FAQ:",
	FAQ_tit1: "为什么“免费宝藏”仍需要订阅后才可以签到领金币，它不是免费的吗？",
	FAQ_sub1: "这是为了设置一个参与的门槛，它可以有效的隔离女巫攻击。若完全开放，一旦被攻击则导致卡牌泛滥且贬值，损害玩家的利益。",
	FAQ_tit2: "订阅“免费宝藏”有时效性吗，它一共能领多少币？",
	FAQ_sub2: "“免费宝藏”是永久订阅，满足条件后自动解锁，每轮签到可以领6个金币，2周刷新1次。",
	FAQ_tit3: "“免费宝藏”不也会一定程度导致卡牌的膨胀吗，影响到付费玩家的利益？",
	FAQ_sub3: "“免费宝藏”主要是为了0氪，微氪玩家服务的，可以让大多数玩家可以免费获得卡牌，它会导致一定程度的卡牌膨胀，但更多的是低级别的卡牌，而高级别的卡牌，如传奇则需要大量的金币和一定的运气，随着cardaether的发展，我们会设计一些卡牌的消耗玩法，可以让不同层次的玩家产生共赢，毕竟任何一款游戏都需要庞大的玩家数量。",
	
	// DaysLegacy56
	DaysLegacy56_title: "“56天宝藏”签到领金币",
	DaysLegacy56_subtitle: "每次只能购买一份，56天订阅失效后才可继续购买。",
	DaysLegacy56_countdown: "结束倒计时",
	DaysLegacy56_rounds: "总共4轮",
	PURCHASE: "订阅",
	
	// NewbieCheckin
	NewbieCheckin_title: "新卡池更新免费送10连",
	NewbieCheckin_subtitle: "每次卡池更新均会开启1次免费送10连活动，参与条件：已经订阅“免费宝藏”。",
	CHECKEDIN: "签到",
	
	// LUCKYDID
	LUCKYDID_title: "幸运DID",
	LUCKYDID_subtitle1: "每次卡池更新更新卡牌及对应幸运DID，活动期间内凡铸造DID与与幸运DID相同，即可获得不等数量抽卡的金币，截图分享至推特并",
	LUCKYDID_subtitle2: "@Cardaether，然后在discord中联系管理员领取金币。",
	LUCKYDID_Draws: "金币",
	LUCKYDID_normal: "普通",
	EventEnded: "活动已结束",
	
	// INVITATION
	INVITATION_title: "邀请新玩家返ETH",
	INVITATION_subtitle: "邀请新玩家订阅“免费宝藏” 可获得最高20%的ETH返利，已订阅“免费宝藏”的玩家可获得自己的邀请码。",
	INVITATION_subtitle2: "玩家需要在discord中联系管理员核对并领取。",
	INVITATION_sign: "未订阅“免费宝藏”时，可输入推荐玩家邀请码。",
	INVITATION_BIND: "绑定",
	INVITATION_tip: "绑定后无法进行解绑，请谨慎输入，订阅“免费宝藏”后可获得自己的邀请码。",
	INVITATION_sign2: "我的邀请码",
	COPY: "复制",
	CopySuccessful: "复制成功",
	INVITATION_sign3: "已邀请的玩家",
	Playeraddress: "玩家地址",
	Whether: "订阅“免费宝藏”的状态",
	Subscribed: "已订阅",
	Notsubscribed: "未订阅",
	Gaintime: "订阅时间",
	Rebates: "返利状态",
	Rebated: "已返利",
	NotRebated: "未返利"
}