<template>
	<div class="com-content">
		<div class="group1">
			<div class="row1">{{ $t("FreeLegacy_title") }}
				<img class="huizhang" v-if="is_legacy" src="../../assets/earncoins/huizhang.svg" />
			</div>
			<div class="row2">{{ $t("FreeLegacy_subtitle") }}</div>
			
			<div class="row3" v-if="!is_legacy">
				<div class="num num1">
					<span class="txt">{{ coinCount }}/10</span>
					<div class="prog"><div class="c" :style="{ 'width': coinCount*10 + '%' }"></div></div>
					<span class="unit">{{ $t("Coins") }}</span>
				</div>
				<div class="num num2">
					<span class="txt">{{ legacy56Count }}/1</span>
					<div class="prog"><div class="c" :style="{ 'width': legacy56Count*100 + '%' }"></div></div>
					<span class="unit">{{ $t("DayLegacy60") }}</span>
				</div>
			</div>
			
			<img class="dbx" src="../../assets/earncoins/dbx.png"/>
			<div class="SUBSCRIBED" v-if="is_legacy" @click="subscribed_click"></div>
		</div>
		
		<div class="group2">
			<div class="row1">
				<div class="left">
					<span class="sp"></span>
					<span class="sign">{{ $t("FreeLegacy_round") }}</span>
				</div>
				<div class="right">
					<span class="refresh">{{ $t("FreeLegacy_countdown") }}</span>
					<div class="time">
						<span class="tm">{{ dd }}</span>
						<span class="un">{{ $t("time_D") }}</span>
						<span class="tm">{{ hh }}</span>
						<span class="un">{{ $t("time_H") }}</span>
						<span class="tm">{{ mm }}</span>
						<span class="un">{{ $t("time_M") }}</span>
					</div>
				</div>
			</div>
			<div class="row2">
				<div class="item" :class="{ 'curr': currCard==1, 'kai':checkNumber(1) }" @click="item_click(1)">
					<span class="num">01</span>
					<img class="pt2" v-if="checkNumber(1)" src="../../assets/earncoins/pt2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/pt1.png"/>
					<img class="qd" v-if="checkNumber(1)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==2, 'kai':checkNumber(2) }" @click="item_click(2)">
					<span class="num">02</span>
					<img class="pt2" v-if="checkNumber(2)" src="../../assets/earncoins/jb2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/jb1.png"/>
					<img class="qd" v-if="checkNumber(2)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==3, 'kai':checkNumber(3) }" @click="item_click(3)">
					<span class="num">03</span>
					<img class="pt2" v-if="checkNumber(3)" src="../../assets/earncoins/pt2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/pt1.png"/>
					<img class="qd" v-if="checkNumber(3)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==4, 'kai':checkNumber(4) }" @click="item_click(4)">
					<span class="num">04</span>
					<img class="pt2" v-if="checkNumber(4)" src="../../assets/earncoins/jb2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/jb1.png"/>
					<img class="qd" v-if="checkNumber(4)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==5, 'kai':checkNumber(5) }" @click="item_click(5)">
					<span class="num">05</span>
					<img class="pt2" v-if="checkNumber(5)" src="../../assets/earncoins/pt2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/pt1.png"/>
					<img class="qd" v-if="checkNumber(5)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==6, 'kai':checkNumber(6) }" @click="item_click(6)">
					<span class="num">06</span>
					<img class="pt2" v-if="checkNumber(6)" src="../../assets/earncoins/jb2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/jb1.png"/>
					<img class="qd" v-if="checkNumber(6)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==7, 'kai':checkNumber(7) }" @click="item_click(7)">
					<span class="num">07</span>
					<img class="pt2" v-if="checkNumber(7)" src="../../assets/earncoins/pt2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/pt1.png"/>
					<img class="qd" v-if="checkNumber(7)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==8, 'kai':checkNumber(8) }" @click="item_click(8)">
					<span class="num">08</span>
					<img class="pt2" v-if="checkNumber(8)" src="../../assets/earncoins/jb2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/jb1.png"/>
					<img class="qd" v-if="checkNumber(8)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==9, 'kai':checkNumber(9) }" @click="item_click(9)">
					<span class="num">09</span>
					<img class="pt2" v-if="checkNumber(9)" src="../../assets/earncoins/pt2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/pt1.png"/>
					<img class="qd" v-if="checkNumber(9)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==10, 'kai':checkNumber(10) }" @click="item_click(10)">
					<span class="num">10</span>
					<img class="pt2" v-if="checkNumber(10)" src="../../assets/earncoins/jb2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/jb1.png"/>
					<img class="qd" v-if="checkNumber(10)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==11, 'kai':checkNumber(11) }" @click="item_click(11)">
					<span class="num">11</span>
					<img class="pt2" v-if="checkNumber(11)" src="../../assets/earncoins/pt2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/pt1.png"/>
					<img class="qd" v-if="checkNumber(11)" src="../../assets/earncoins/qd.svg"/>
				</div>
				<div class="item" :class="{ 'curr': currCard==12, 'kai':checkNumber(12) }" @click="item_click(12)">
					<span class="num">12</span>
					<img class="pt2" v-if="checkNumber(12)" src="../../assets/earncoins/jb2.png"/>
					<img class="pt2" v-else src="../../assets/earncoins/jb1.png"/>
					<img class="qd" v-if="checkNumber(12)" src="../../assets/earncoins/qd.svg"/>
				</div>
				
			</div>
		</div>
		
		<div class="group3">
			<div class="item">
				<div class="left">{{ $t("FAQ") }}</div>
				<div class="right">
					<span class="tit">{{ $t("FAQ_tit1") }}</span>
					<span class="sub">{{ $t("FAQ_sub1") }}</span>
				</div>
			</div>
			<div class="item">
				<div class="left">{{ $t("FAQ") }}</div>
				<div class="right">
					<span class="tit">{{ $t("FAQ_tit2") }}</span>
					<span class="sub">{{ $t("FAQ_sub2") }}</span>
				</div>
			</div>
			<div class="item">
				<div class="left">{{ $t("FAQ") }}</div>
				<div class="right">
					<span class="tit">{{ $t("FAQ_tit3") }}</span>
					<span class="sub">{{ $t("FAQ_sub3") }}</span>
				</div>
			</div>
		</div>
		
		<div class="donghua" v-if="show_donghua">
			<img class="img" src="../../assets/earncoins/donghua.png"/>
		</div>
		
	</div>
</template>

<script>
	import args from "@/common/args.js"
	import {startLoading, endLoading} from "../../common/loading.js"
	
	let timeout_countdown = 0;
	
	function sleep(time){
		return new Promise((resolve) => setTimeout(resolve, time));
	}
	
	export default {
		data() {
			return {
				dd: 0,
				hh: 0,
				mm: 0,
				
				countdown: 0, // 倒计时总时间
				currRound: 0, // 当前第几轮
				currCard: 0, // 当前选框的卡片
				// kaiCard: 0, // 当前所开卡片最大号 
				kaiCards: [], // 当前所开卡片号
				
				coinCount: 0,  // 当前充值金币数量
				legacy56Count: 0, // 已购买 60 Day Legacy
				is_legacy: 0,
				
				show_donghua: false
			}
		},
		created() {
			
			startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
			
			this.getData();
			
			this.$bus.$on("FreeLegacyData", _ => this.getData());
			
			
			setTimeout(_ => endLoading(), 2000);
			
		},
		methods: {
			async getData() {
				let week2 = 14*args.day_home*3600;

				let rest = await this.$http.get("api-open/free_legacy/countdown");
				// console.log(111, rest);
				if(!rest.body.data) {
					return;
				}
				let st = rest.body.data.start_date;
				this.currRound = rest.body.data.round;
				// this.currCard = rest.body.data.day;

				let now = parseInt(new Date().getTime()/1000);
				let count = st+week2-now;
				this.setCountdown(count);
				
				this.countdown = count;
				// this.setCurrentCard(count);
				
				// console.log("countdown", this.countdown);
				
				this.getSingin();
			},
			async getSingin() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				if (!account) return;
				
				let rest = await this.$http.get(`api-open/free_legacy/sign-in?address=${account}&round=${this.currRound}`);
				
				if(!rest.body.data) return;
				
				if(rest.body.data.length == 0) this.currCard = 1;
				else {
					let obj = rest.body.data[rest.body.data.length - 1];
					let num = obj.number;
					let ct = obj.create_time;
					let cha = ((new Date().getUTCTime()) - (new Date(ct).getZero())) / 1000 / 3600;
					if(cha > args.day_home) {
						this.currCard = num + 1;
					}
				}
				
				this.kaiCards = [];
				rest.body.data.forEach(iv => {
					this.kaiCards.push(iv.number);
				});
				this.coinCount = rest.body.coins || 0;
				this.legacy56Count = rest.body.legacy56 || 0;
				this.is_legacy = rest.body.is_legacy;
				
				this.$forceUpdate();
			},
			checkNumber(num) {
				let ft = this.kaiCards.filter(iv => iv == num);
				return ft.length>0;
			},
			setCountdown(count) {
				let d = count / 24 / 3600;
				let dy = count % (24 * 3600);
				
				let h = dy / 3600;
				let hy = dy % 3600;
				
				let m = hy / 60;
				let my = hy % 60;
				
				d = parseInt(d);
				h = parseInt(h);
				m = parseInt(m);
				
				if(d < 0) d = 0;
				if(h < 0) h = 0;
				if(m < 0) m = 0;
				
				if(d < 10) d = "0" + d;
				if(h < 10) h = "0" + h;
				if(m < 10) m = "0" + m;
				
				this.dd = d;
				this.hh = h;
				this.mm = m;
				
				// console.log(count, d, h, m);
				
				count--;
				clearTimeout(timeout_countdown);
				timeout_countdown = setTimeout(_ => {
					this.setCountdown(count);
					if(count % 60 == 0) this.getData();
				}, 1000)
				
			},
			// setCurrentCard(count) {
			// 	let d = parseInt(count / 24 / 3600);
			// 	let curr = 12 - (d - 2);
			// 	this.currCard = curr;
			// },
			async item_click(num) {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				if (!account) {
					this.$bus.$emit("account_login");
					return;
				}
				
				if(this.currCard != num || this.checkNumber(num)) return;
				
				if(!this.checkParent(num-1)) {
					let zh = "昨日没签到";
					let en = "Didn't check in yesterday";
					this.$message({
						type: 'warning',
						message: this.$i18n.locale=='en-US'?en:zh
					});
					return;
				}
				
				startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
				
				let rest = await this.$http.post("api-open/free_legacy/sign-in", {
					address: account,
					round: this.currRound,
					number: this.currCard
				});
				
				await this.getData();
				this.$bus.$emit("getTimes");
				
				await sleep(2000);
				
				endLoading();
				
				if(rest.body.error) {
					this.$message({
						type: 'warning',
						message: this.$i18n.locale=='en-US'?rest.body.msg_en:rest.body.msg
					});
					return;
				}
				
				let coin = num % 2 == 0 ? 1 : 0; // 当前金币个数
				if(coin == 1) {
					this.show_donghua = true;
					setTimeout(_ => this.show_donghua = false, 1300);
				}
				
			},
			checkParent(num) {
				if (num == 0) {
					return true;
				} else if (num % 2 == 0) {
					return true;
				}
				let ft = this.kaiCards.filter(iv => iv == num);
				return ft.length>0;
			},
			
			subscribedStatus() {
				
			},
			subscribed_click() {
				
			}
		},
	}
</script>

<style lang="scss" scoped>
	.group1 {
		height: 200px;
		background: #1C2849;
		border-radius: 30px 30px 0px 0px;
		margin-top: 108px;
		box-sizing: border-box;
		padding: 40px;
		position: relative;
		
		.row1 {
			font-size: 40px;
			font-family: Impact;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
			display: flex;
			align-items: center;
			.huizhang {
				// width: 27px;
				object-fit: contain;
				height: 39px;
				margin-left: 19px;
			}
		}
		.row2 {
			width: 604px;
			height: 30px;
			background: linear-gradient(90deg, rgba(255,183,45,0.8), rgba(255,183,45,0));
			margin: 22px 0;
			box-sizing: border-box;
			padding: 0 10px;
			
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
			white-space: nowrap;
		}
		.row3 {
			display: flex;
			
			.num {
				display: flex;
				align-items: center;
				
				.txt {
					font-size: 20px;
					font-family: Alibaba PuHuiTi;
					font-weight: bold;
					color: #FFFFFF;
				}
				.prog {
					width: 185px;
					height: 7px;
					background: #57617D;
					border-radius: 4px;
					margin: 0 13px;
					.c {
						width: 0px;
						height: 7px;
						background: #FFB72D;
						border-radius: 4px;
					}
				}
				.unit {
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
				}
			}
			.num2 {
				margin-left: 62px;
			}
		}
		
		.dbx {
			position: absolute;
			right: 0%;
			top: 0%;
		}
		.SUBSCRIBED {
			width: 133px;
			height: 40px;
			position: absolute;
			right: 43px;
			bottom: 29px;
			text-align: center;
			line-height: 40px;
			background-image: url("../../assets/earncoins/anniu.svg");
		}
		.SUBSCRIBED.dis {
			background-color: #999;
			background-image: unset;
			cursor: unset;
		}
	}
	
	.group2 {
		width: 1300px;
		height: 700px;
		background: #1C2849;
		border-radius: 0px 0px 30px 30px;
		margin-top: 14px;
		padding: 36px 40px;
		box-sizing: border-box;
		
		.row1 {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			
			.left {
				display: flex;
				align-items: center;
				.sp {
					display: inline-block;
					width: 7px;
					height: 19px;
					background: #FFFFFF;
					border-radius: 4px;
				}
				.sign {
					font-size: 20px;
					font-family: Alibaba PuHuiTi;
					font-weight: bold;
					color: #FFFFFF;
					margin-left: 15px;
				}
			}
			.right {
				display: flex;
				align-items: center;
				.refresh {
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
					margin-right: 18px;
				}
				.time {
					display: flex;
					align-items: flex-end;
					.tm {
						width: 36px;
						height: 36px;
						line-height: 36px;
						text-align: center;
						background: #111A32;
						border-radius: 6px;
						// padding: 8px;
						box-sizing: border-box;
						margin-left: 6px;
						
						font-size: 20px;
						font-family: Alibaba PuHuiTi;
						font-weight: 400;
						color: #FFB72D;
					}
					.un {
						font-size: 14px;
						font-family: Alibaba PuHuiTi;
						font-weight: 400;
						color: #52618C;
						margin-left: 6px;
					}
				}
			}
		}
		
		.row2 {
			margin-top: 57px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			box-sizing: border-box;
			
			.item {
				width: 181px;
				height: 246px;
				background: #473A8C;
				border-radius: 10px;
				position: relative;
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				align-items: center;
				&:nth-child(n+7) {
					margin-top: 32px;
				}
				
				.num {
					font-size: 50px;
					font-family: Impact;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
					position: absolute;
					left: 11px;
					top: 13px;
				}
				.pt2 {
					width: 132px;
					height: 132px;
				}
				.qd {
					width: 30px;
					height: 30px;
					position: absolute;
					right: 12px;
					bottom: 12px;
				}
			}
			.curr {
				border: 2px solid #FFB72D;
				cursor: pointer;
			}
			.kai {
				background: #111A32;
				border: unset;
				cursor: unset;
				.num {
					color: #2F374D;
				}
			}
		}
		
	}
	
	.group3 {
		width: 1300px;
		height: 600px;
		background: #1C2849;
		border-radius: 20px;
		margin-top: 40px;
		box-sizing: border-box;
		padding: 30px 35px;
		
		.item {
			display: flex;
			margin-top: 30px;
			&:first-of-type {
				margin-top: 0px;
			}
			&:last-of-type {
				.right {
					border-bottom: unset;
				}
			}
		}
		
		.left {
			font-size: 20px;
			font-family: Alibaba PuHuiTi;
			font-weight: bold;
			color: #727D9B;
			line-height: 33px;
		}
		
		.right {
			display: flex;
			flex-direction: column;
			margin-left: 18px;
			border-bottom: 1px solid #57617D;
			padding-bottom: 29px;
			
			.tit {
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #727D9B;
				line-height: 33px;
			}
			.sub {
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 33px;
				margin-top: 6px;
			}
		}
	}
	
	
	.donghua {
		position: fixed;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		
		.img {
			
		}
	}
</style>