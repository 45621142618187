<template>
	<div class="container" :class="{isMobile:isMobile}">
		<!-- 头部 -->
		<div class="head" @mousedown="lang_out">
			<headbar></headbar>
			<img class="tou-img" src="../assets/tou.png" />
			<img class="logo" src="../assets/logo.svg" />
			<img class="xlxb" src="../assets/xlxb.svg" />
		</div>

		<!-- 世界背景 -->
		<div class="sjbj">
			<div class="sjcont"> <!-- :style="{height:sjcont_height+'px'}" -->
				<div class="sjbjbt">
					<img class="" :src="require(`../assets/${$i18n.locale}/sjbjbt.svg`)" />
				</div>
				<div class="sjbj-cont" v-if="$i18n.locale=='en-US'">
					<pre>
In a distant future, humanity unlocked the mysteries of Aether energy and discovered a mystical Aether crystal. Emitting potent Aether energy, these crystals granted unparalleled power and abilities to those who wielded them.
To harness the power of Aether, people created special cards known as "Cardaethers." Each Cardaether card contained a unique infusion of Aether energy, representing different elements, creatures, and magic.
Cardaether quickly became a popular competitive game, attracting players from around the world. They constructed their card decks, utilizing the powers of the cards to engage in fierce battles and strategic duels.
However, the existence of Aether energy also ignited conflicts and disputes. Various factions and organizations sought to control the power of Aether crystals for their own agendas. Players find themselves immersed in a world filled with desire and ambition, where they must rise above the rest through wit and skill in the Cardaether arena.
Beyond the arena, this world holds many enigmatic Aether ruins and lost temples, awaiting daring adventurers. These places conceal precious Cardaether cards and the secrets of the world, accessible only to those brave enough to explore.
Players assume the role of Cardaether champions, driven by the pursuit of Aether energy, embarking on an exhilarating and perilous adventure in the world of Cardaether. They engage in thrilling duels with other players, explore Aether ruins, unlock new cards and abilities, and ultimately unveil the truth behind the power of Aether.
Cardaether offers a captivating blend of competition, strategy, and exploration, where players experience unparalleled card battles and the allure of Aether energy. Are you ready to embrace the challenges and glory of Cardaether? Become a legendary Cardaether champion in this Aether-filled world!
					</pre>
				</div>
				<div class="sjbj-cont" v-if="$i18n.locale=='zh-CN'">
					<pre>
在遥远的未来，人类探索了乙太能量的奥秘，并发现了一种神秘的乙太晶体。这些晶体散发着强大的乙太能量，能够赋予人们无与伦比的力量和能力。
为了掌握乙太的力量，人们创造了特殊的卡片，称为 "Cardaethers"。每张 Cardaether 卡片都蕴含着一种独特的乙太能量，并代表着不同元素、生物和魔法。
Cardaether 成为了一种热门的竞技游戏，吸引了来自世界各地的玩家。他们组建自己的卡组，利用卡片的力量进行激烈的对战和战略性的决斗。
然而，乙太能量的存在也引发了许多争端和冲突。各种势力和组织试图掌控乙太晶体的力量，以实现自己的目的。玩家们将身处在这个充满欲望和野心的世界中，他们需要通过智慧和技巧，在 Cardaether 的竞技场上脱颖而出。
除了竞技场，这个世界还有许多神秘的乙太遗迹和迷失的圣殿等待着勇敢的冒险者。这些地方隐藏着珍贵的 Cardaether 卡片和世界的秘密，只有那些敢于探索的人才能获得乙太的神奇力量。
玩家将扮演一名追求乙太能量的卡牌使者，在 Cardaether 的世界中踏上一段令人兴奋和危险的冒险之旅。他们将与其他玩家竞技，探索乙太遗迹，解锁新的卡片和技能，并最终揭示乙太力量背后的真相。
Cardaether 的竞技、策略和探险将成为这个令人着迷的世界的核心，玩家将体验到无与伦比的卡牌对战和乙太能量的魅力。准备好迎接 Cardaether 的挑战和荣耀吗？在这个充满乙太的世界中，成为卡牌使者的传奇！
					</pre>
				</div>
				<div class="more1" @click="sjbj_more">{{$t("more")}} ></div>
			</div>

			<div class="sjbj-dialog" v-show="sjbj_show">
				<div class="mask" @click="sjbj_mask"></div>
					<transition name="fade">
					<div class="cont2" v-if="sjbj_show">
						<div class="div" v-if="$i18n.locale=='en-US'">
							<div>
								In a distant future, humanity unlocked the mysteries of Aether energy and discovered a
								mystical Aether crystal. Emitting potent Aether energy, these crystals granted
								unparalleled power and abilities to those who wielded them.
							</div>
							<div>
								To harness the power of Aether, people created special cards known as "Cardaethers."
								Each Cardaether card contained a unique infusion of Aether energy, representing
								different elements, creatures, and magic.
							</div>
							<div>
								Cardaether quickly became a popular competitive game, attracting players from around the
								world. They constructed their card decks, utilizing the powers of the cards to engage in
								fierce battles and strategic duels.
							</div>
							<div>
								However, the existence of Aether energy also ignited conflicts and disputes. Various
								factions and organizations sought to control the power of Aether crystals for their own
								agendas. Players find themselves immersed in a world filled with desire and ambition,
								where they must rise above the rest through wit and skill in the Cardaether arena.
							</div>
							<div>
								Beyond the arena, this world holds many enigmatic Aether ruins and lost temples,
								awaiting daring adventurers. These places conceal precious Cardaether cards and the
								secrets of the world, accessible only to those brave enough to explore.
							</div>
							<div>
								Players assume the role of Cardaether champions, driven by the pursuit of Aether energy,
								embarking on an exhilarating and perilous adventure in the world of Cardaether. They
								engage in thrilling duels with other players, explore Aether ruins, unlock new cards and
								abilities, and ultimately unveil the truth behind the power of Aether.
							</div>
							<div>
								Cardaether offers a captivating blend of competition, strategy, and exploration, where
								players experience unparalleled card battles and the allure of Aether energy. Are you
								ready to embrace the challenges and glory of Cardaether? Become a legendary Cardaether
								champion in this Aether-filled world!
							</div>
						</div>
						<div class="div" v-if="$i18n.locale=='zh-CN'">
							<div>
								在遥远的未来，人类探索了乙太能量的奥秘，并发现了一种神秘的乙太晶体。这些晶体散发着强大的乙太能量，能够赋予人们无与伦比的力量和能力。
							</div>
							<div>
								为了掌握乙太的力量，人们创造了特殊的卡片，称为 "Cardaethers"。每张 Cardaether 卡片都蕴含着一种独特的乙太能量，并代表着不同元素、生物和魔法。
							</div>
							<div>
								Cardaether 成为了一种热门的竞技游戏，吸引了来自世界各地的玩家。他们组建自己的卡组，利用卡片的力量进行激烈的对战和战略性的决斗。
							</div>
							<div>
								然而，乙太能量的存在也引发了许多争端和冲突。各种势力和组织试图掌控乙太晶体的力量，以实现自己的目的。玩家们将身处在这个充满欲望和野心的世界中，他们需要通过智慧和技巧，在
								Cardaether 的竞技场上脱颖而出。
							</div>
							<div>
								除了竞技场，这个世界还有许多神秘的乙太遗迹和迷失的圣殿等待着勇敢的冒险者。这些地方隐藏着珍贵的 Cardaether
								卡片和世界的秘密，只有那些敢于探索的人才能获得乙太的神奇力量。
							</div>
							<div>
								玩家将扮演一名追求乙太能量的卡牌使者，在 Cardaether
								的世界中踏上一段令人兴奋和危险的冒险之旅。他们将与其他玩家竞技，探索乙太遗迹，解锁新的卡片和技能，并最终揭示乙太力量背后的真相。
							</div>
							<div>
								Cardaether 的竞技、策略和探险将成为这个令人着迷的世界的核心，玩家将体验到无与伦比的卡牌对战和乙太能量的魅力。准备好迎接 Cardaether
								的挑战和荣耀吗？在这个充满乙太的世界中，成为卡牌使者的传奇！
							</div>
						</div>
					</div>
				</transition>
			</div>

		</div>

		<!-- 种族介绍 -->
		<div class="zzjs">
			<div class="zzcont">
				<div class="zzjsbt">
					<img :src="require(`../assets/${$i18n.locale}/zzjsbt.svg`)" />
				</div>
				<div class="quan" v-loading="zz_img_loading">
					<video v-if="!isMobile" class="video1 mp4" autoplay loop muted src="../assets/video1.mp4"></video>
					<img v-if="isMobile" class="video1 gif" src="../assets/1.gif"/>
					<img class="zzwz" :src="zzwzList[zzwzIndex].img" @load="zzwzImg_load" />
				</div>
				<div class="left">
					<div class="eidolon">
						<img class="bg" :src="require(`../assets/${$i18n.locale}/ETHNICITY.png`)" />
						<div class="cont" v-html="$i18n.locale=='en-US'?zzwzList[zzwzIndex].en:zzwzList[zzwzIndex].zh">

							<!-- The Elven race is one of the noble and enigmatic races in Cardaether. They dwell in lush
							forests and mystical lands, deeply connected to nature. Elves are renowned for their grace,
							agility, and wisdom, being regarded as the guardians and representatives of the natural
							world... -->
						</div>
						<div class="more" @click="zzjs_more">{{$t("more")}} ></div>

						<div class="zzjs-dialog" v-show="zzjs_show">
							<div class="mask" @click="zzjs_mask"></div>
								<transition name="fade">
								<div class="cont2" v-if="zzjs_show"
									v-html="$i18n.locale=='en-US'?zzwzList[zzwzIndex].en:zzwzList[zzwzIndex].zh">
								</div>
								</transition>
						</div>
					</div>
					<div class="element">
						<img class="bg" :src="require(`../assets/${$i18n.locale}/element.png`)" />
						<div class="siy">
							<div class="sanq">
								<div class="qq q1"></div>
								<div class="qq q2"></div>
								<div class="qq q3"></div>
							</div>
							<div class="r r1">
								<div>
									<img :src="require(`../assets/elem/${zzwzList[zzwzIndex].elem[0]}.svg`)" />
									<span>{{$t(zzwzList[zzwzIndex].elem[0])}}</span>
								</div>
							</div>
							<div class="r r2">
								<div>
									<img :src="require(`../assets/elem/${zzwzList[zzwzIndex].elem[4]}.svg`)" />
									<span>{{$t(zzwzList[zzwzIndex].elem[4])}}</span>
								</div>
								<div>
									<img :src="require(`../assets/elem/${zzwzList[zzwzIndex].elem[1]}.svg`)" />
									<span>{{$t(zzwzList[zzwzIndex].elem[1])}}</span>
								</div>
							</div>
							<div class="r r3">
								<div>
									<img :src="require(`../assets/elem/${zzwzList[zzwzIndex].elem[3]}.svg`)" />
									<span>{{$t(zzwzList[zzwzIndex].elem[3])}}</span>
								</div>
								<div>
									<img :src="require(`../assets/elem/${zzwzList[zzwzIndex].elem[2]}.svg`)" />
									<span>{{$t(zzwzList[zzwzIndex].elem[2])}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="zzbt" v-for="(item,index) in zzwzList" :key="index" @click="zzbt_click(index)">
						<span class="name">{{zzwzIndex==index?($i18n.locale=="en-US"?item.name:item.name_zh):""}}</span>
						<img v-if="zzwzIndex==index" :src="item.icon2" />
						<img v-else :src="item.icon1" />
					</div>
				</div>
			</div>
		</div>

		<!-- 卡池 -->
		<div class="kac">
			<div class="kccont">
				<div class="kcbt">
					<img :src="require(`../assets/${$i18n.locale}/kcbt.svg`)" />
				</div>
				<div class="sj">2023/07/25 ~ 2023/08/25</div>
				<div class="cka">
					<video v-if="!isMobile" class="video2 mp4" autoplay loop muted src="../assets/video2.mp4"></video>
					<img v-if="isMobile" class="video2 gif" src="../assets/2.gif"/>
					<div class="swiper">
						<swiper :options="swiperOption" v-if="imgList.length">
							<swiper-slide v-for="item of imgList" :key="item.id">
								<img class="slide-img" :src="$utils.getImage(item.image)" />
							</swiper-slide>
						</swiper>
					</div>
				</div>
			</div>
			<div class="draw">
				<img :src="require(`../assets/${$i18n.locale}/Draw1time.png`)" @click="draw1_click" />
				<img :src="require(`../assets/${$i18n.locale}/Draw10time.png`)" @click="draw10_click" />
			</div>
		</div>

		<!-- 更多介绍 -->
		<div class="cdr">
			<div class="cont">
				<div>
					<img class="img" src="../assets/gdhd.svg" />
					<span class="txt">{{$t("CARD_DRAW_RULES")}}</span>
				</div>
				<pre class="pre" v-if="$i18n.locale=='en-US'">
1. The initial Cardaether card pool contains 50 cards, categorized as Common Cards (Blue), Rare Cards (Purple), Epic Cards (Red), and Legendary Cards (Golden). Among these, Common Cards make up about 60% of the pool, totaling 30 cards; Rare Cards account for around 24%, totaling 12 cards; Epic Cards take up about 12%, totaling 6 cards; and Legendary Cards occupy about 4%, totaling 2 cards.

2. The probabilities of drawing Common Cards, Rare Cards, Epic Cards, and Legendary Cards are 70%, 21%, 8.4%, and 0.6% respectively. There is no guarantee mechanism.

3. The card pool will be updated every 2 months, and previous cards will be permanently removed, becoming "limited edition cards."
				</pre>
				<pre class="pre" v-if="$i18n.locale=='zh-CN'">
1. 初始的 Cardaether 卡池包含了50张卡牌，分为普通卡（蓝色）、稀有卡（紫色）、史诗卡（红色）以及传说卡（金色）。其中，普通卡大约占据了卡池的60%，即30张卡；稀有卡占据了约24%，即12张卡；史诗卡占据了约12%，即6张卡；传说卡则占据了约4%，即2张卡。

2. 抽取普通卡、稀有卡、史诗卡以及传说卡的概率分别为70%、21%、8.4%以及0.6%。没有保底机制。

3. 卡池每2个月会更换新卡池，过往卡牌将会永久下架并成为“绝版卡”。
				</pre>
				<span class="more" style="margin-top: 2vw;" @click="cdr_more">{{$t("more")}} ></span>

			</div>

			<div class="cdr-dialog" v-if="cdr_show">
				<div class="mask" @click="cdr_mask"></div>
					<transition name="fade">
					<div class="cont2">
						<pre class="pre" v-if="$i18n.locale=='en-US'">
1. The initial Cardaether card pool contains 50 cards, categorized as Common Cards (3 stars), Rare Cards (4 stars), Epic Cards (5 stars), and Legendary Cards (6 stars). Among these, Common Cards make up about 60% of the pool, totaling 30 cards; Rare Cards account for around 24%, totaling 12 cards; Epic Cards take up about 12%, totaling 6 cards; and Legendary Cards occupy about 4%, totaling 2 cards.

2. The probabilities of drawing Common Cards, Rare Cards, Epic Cards, and Legendary Cards are 70%, 21%, 8.4%, and 0.6% respectively. There is no guarantee mechanism.

3. The card pool will undergo rotation after a certain period. Cards with the highest draw count will be permanently removed and become "Limited Edition" cards, while new cards will be introduced to replenish the pool.

4. Common Cards rotate every week, with 2 cards being replaced each time; Rare Cards rotate every 2 weeks, with 2 cards being replaced each time; Epic Cards rotate every 4 weeks, with 1 card being replaced each time; and Legendary Cards rotate every 12 weeks, with 1 card being replaced each time.

5. At the beginning of each rotation cycle, if all cards had a draw count of 0 in the previous week, new cards will be added only to the corresponding card group. If multiple card groups have the same draw count, the card with the earliest introduction date will be removed. If the most drawn card is to be replaced in the recent rotation, it will be removed from the card pool, and this process continues.
						</pre>
						<pre class="pre" v-if="$i18n.locale=='zh-CN'">
1. 初始的 Cardaether 卡池包含了50张卡牌，分为普通卡（3星）、稀有卡（4星）、史诗卡（5星）以及传说卡（6星）。其中，普通卡大约占据了卡池的60%，即30张卡；稀有卡占据了约24%，即12张卡；史诗卡占据了约12%，即6张卡；传说卡则占据了约4%，即2张卡。

2. 抽取普通卡、稀有卡、史诗卡以及传说卡的概率分别为70%、21%、8.4%以及0.6%。没有保底机制。

3. 卡池会在一段时间后进行轮换。其中抽取次数最多的卡牌将会永久下架并成为“绝版卡”，替换的新卡牌将做为卡池的补充。

4.普通卡每周轮换一次，每次替换2张卡牌；稀有卡每2周轮换一次，每次替换2张卡牌；史诗卡每4周轮换一次，每次替换1张卡；传说卡每12周轮换一次，每次替换1张卡。

5. 在每个轮换周期开始时，如果上周所有卡牌抽取次数为 0，将只会在对应的卡组里增加新的卡牌；如果多组卡牌已抽取数量并列，则下架上传日期最早的卡牌；如果在最近周卡牌替换时，出现抽取次数最多的卡牌，则从卡组中下架该卡牌，以此类推。
						</pre>
					</div>
					</transition>
			</div>
		</div>


		<!-- 交易介绍 -->
		<div class="jyjs">
			<div class="jycont">
				<div class="jyjsbt">
					<img :src="require(`../assets/${$i18n.locale}/jyjsbt.svg`)" />
				</div>
				<div class="main">
					<img src="../assets/jyjs.png" />
					<div class="cont" v-if="$i18n.locale=='en-US'">
						<pre class="pre1">
In the grand world of Cardaether, owning rare cards is the dream of every adventurer and player. To meet the players' demands for rare cards, the trading system of Cardaether came into being.
The establishment of the trading system originated from a grand competitive event called "Cardaether Legacy." During this event, adventurers and powerful beings from all corners of the world gathered to compete for ownership of rare cards and powerful strategies. However, obtaining precious cards in the arena is not the only way. Some adventurers explore unique cards from ruins, mazes, and uncharted territories, cards with unprecedented power and rarity that fascinate people.
To allow players to trade and exchange more freely, Cardaether launched the trading system. Players can use the auction house or directly trade cards with other players to seek their desired cards or sell their own rare collectibles. The open trading system enriches interactions and exchanges among players, creating a whole new ecosystem for card trading.
Card trading in Cardaether has also become a special skill. Players need to use wisdom and strategy, grasp market dynamics and card values, to obtain the best deals in trading. At the same time, guarding against deceit and lies also becomes an indispensable factor in transactions.
The auction house has become a lively place for adventurers to trade fervently. Players can auction their own cards for sale or bid to acquire other precious cards. This bustling and intense market has become the source of legendary stories, where many powerful beings and players have left their own legendary tales.
The trading system in Cardaether injects new vitality into the game, allowing every adventurer to create their own legendary stories in the world of trading and become true card masters.
						</pre>
					</div>
					<div class="cont" v-if="$i18n.locale=='zh-CN'">
						<pre class="pre1">
在Cardaether的壮阔世界中，拥有稀有卡牌是每位冒险者和玩家的梦想。为了满足玩家对稀有卡牌的需求，Cardaether的交易系统应运而生。
交易系统的建立源自一场名为“卡牌传世”的盛大竞技盛典。在这场盛会上，各路冒险者和强者汇聚一堂，争夺着珍稀卡牌和强力战术的所有权。然而，竞技场中获得珍贵卡牌并不是唯一的方式。有些冒险者从遗迹、迷宫和未知领域中探索出独特卡牌，这些卡牌以前所未有的力量和珍稀程度让人着迷。
为了让玩家能够更自由地交易和互通有无，Cardaether推出了交易系统。玩家们可以通过拍卖行或者直接与其他玩家进行卡牌交易，以寻求心仪的卡牌，或是出售自己珍藏的稀有卡牌。交易系统的开放让玩家间的交流和互动更加丰富多彩，创造了一个全新的卡牌交易生态。
卡牌交易在Cardaether也成为了一门特殊技能。玩家们需要运用智慧和谋略，掌握市场动态和卡牌价值，以取得最优质的交易。同时，对于狡诈和谎言的防范也成为了交易中不可忽视的因素。
拍卖行成为了冒险者们热烈交易的场所，玩家们能够将自己的卡牌竞拍出售，或是出价竞得其他珍贵的卡牌。这个热闹而激烈的市场成为了传奇故事的源头，许多强者和玩家在拍卖行中留下了属于自己的传世传说。
Cardaether的交易系统也为游戏注入了新的活力，让每位冒险者都能在交易的世界中创造属于自己的传奇故事，成为真正的卡牌大师。
						</pre>
					</div>
					<div class="sand">
						<div class="s1"></div>
						<div class="s2"></div>
						<div class="s3"></div>
						<div class="s4"></div>
					</div>
					
					<span class="more1 more2" @click="jyjs_more">{{$t("more")}} ></span>
					<transition name="fade">
						<div class="jyjs-dialog" v-if="jyjs_show">
							<div class="mask" @click="jyjs_mask"></div>
							<div class="cont2">
								<div class="div" v-if="$i18n.locale=='en-US'">
									<pre class="pre2">
In the grand world of Cardaether, owning rare cards is the dream of every adventurer and player. To meet the players' demands for rare cards, the trading system of Cardaether came into being.

The establishment of the trading system originated from a grand competitive event called "Cardaether Legacy." During this event, adventurers and powerful beings from all corners of the world gathered to compete for ownership of rare cards and powerful strategies. However, obtaining precious cards in the arena is not the only way. Some adventurers explore unique cards from ruins, mazes, and uncharted territories, cards with unprecedented power and rarity that fascinate people.

To allow players to trade and exchange more freely, Cardaether launched the trading system. Players can use the auction house or directly trade cards with other players to seek their desired cards or sell their own rare collectibles. The open trading system enriches interactions and exchanges among players, creating a whole new ecosystem for card trading.

Card trading in Cardaether has also become a special skill. Players need to use wisdom and strategy, grasp market dynamics and card values, to obtain the best deals in trading. At the same time, guarding against deceit and lies also becomes an indispensable factor in transactions.

The auction house has become a lively place for adventurers to trade fervently. Players can auction their own cards for sale or bid to acquire other precious cards. This bustling and intense market has become the source of legendary stories, where many powerful beings and players have left their own legendary tales.

The trading system in Cardaether injects new vitality into the game, allowing every adventurer to create their own legendary stories in the world of trading and become true card masters.
									</pre>
								</div>
								<div class="div" v-if="$i18n.locale=='zh-CN'">
									<pre class="pre2">
在Cardaether的壮阔世界中，拥有稀有卡牌是每位冒险者和玩家的梦想。为了满足玩家对稀有卡牌的需求，Cardaether的交易系统应运而生。

交易系统的建立源自一场名为“卡牌传世”的盛大竞技盛典。在这场盛会上，各路冒险者和强者汇聚一堂，争夺着珍稀卡牌和强力战术的所有权。然而，竞技场中获得珍贵卡牌并不是唯一的方式。有些冒险者从遗迹、迷宫和未知领域中探索出独特卡牌，这些卡牌以前所未有的力量和珍稀程度让人着迷。

为了让玩家能够更自由地交易和互通有无，Cardaether推出了交易系统。玩家们可以通过拍卖行或者直接与其他玩家进行卡牌交易，以寻求心仪的卡牌，或是出售自己珍藏的稀有卡牌。交易系统的开放让玩家间的交流和互动更加丰富多彩，创造了一个全新的卡牌交易生态。

卡牌交易在Cardaether也成为了一门特殊技能。玩家们需要运用智慧和谋略，掌握市场动态和卡牌价值，以取得最优质的交易。同时，对于狡诈和谎言的防范也成为了交易中不可忽视的因素。

拍卖行成为了冒险者们热烈交易的场所，玩家们能够将自己的卡牌竞拍出售，或是出价竞得其他珍贵的卡牌。这个热闹而激烈的市场成为了传奇故事的源头，许多强者和玩家在拍卖行中留下了属于自己的传世传说。

Cardaether的交易系统也为游戏注入了新的活力，让每位冒险者都能在交易的世界中创造属于自己的传奇故事，成为真正的卡牌大师。
									</pre>
								</div>
							</div>
						</div>
					</transition>
				</div>
			</div>
		</div>

		<!-- 卡牌交换 -->
		<div class="kpjh">
			<div class="jhcont">
				<div class="left">
					<img src="../assets/gdhd.svg" />
					<div class="txt">CARD EXCHANGE</div>
					<div class="btn">STAY TUNED</div>
				</div>
				<div class="right">
					<div class="top">
						<div class="txt">CARD TRADING</div>
						<div class="btn">STAY TUNED</div>
					</div>
					<div class="bot">
						<img src="../assets/gdhd.svg" />
						<a href="https://twitter.com/Cardaether" target="_blank" class="btn">MORE</a>
					</div>
				</div>
			</div>
		</div>


		<div class="foot">
			<div class="div">
				<img src="../assets/foot.png" />
				<div class="copyright"><span>Copyright© 2023 Cardaether</span></div>
			</div>
		</div>


		
		<div class="tip-dialog" v-show="tip_show">
			<div class="mask" @click="tip_mask"></div>
			<transition name="fade">
				<div class="cont2" v-if="tip_show">
					<img src="../assets/dialog.svg" />
					<div class="txt">{{$t("construction")}}</div>
					<div class="conf" @click="tip_mask">{{$t("confirm")}}</div>
				</div>
			</transition>
		</div>

	</div>
</template>

<script>
	import headbar from "../components/headbar.vue"
	
	import {
		SwiperSlide,
		Swiper
	} from 'vue-awesome-swiper'
	import '/node_modules/swiper/css/swiper.min.css'

	let lang_time;

	export default {
		name: 'HelloWorld',
		components: {
			headbar,
			Swiper,
			SwiperSlide
		},
		props: {
			msg: String
		},
		data() {
			return {
				sjcont_height: 710,
				isMobile: false,

				// 图片列表可以写死（也可以从接口中获取）
				imgList: [],
				// 配置属性
				swiperOption: {
					direction: 'horizontal', // 滑动的方向(水平方向)
					loop: true, // 是否循环
					autoplay: {
						delay: 1500 // 自动播放的时长
					},
					slidesPerView: 'auto', // 设置可视区一共显示几张图片,设置auto,设置auto为自适应;
					centeredSlides: true, // 设置为true时,带有active-slide类名的图片会居中
					spaceBetween: 4 //  每张图片之间的间隔
				},

				zzwzList: [{
						name: "Humans",
						name_zh: "人族",
						icon1: require("../assets/zztb/rz1.svg"),
						icon2: require("../assets/zztb/rz2.svg"),
						img: require("../assets/zzwz/rz.png"),
						en: `The Human race is one of the most widespread and diverse races in Cardaether. They inhabit various regions, from vast cities to sprawling villages, showcasing their adaptability and diversity. Humans are renowned for their intelligence, creativity, and unity, making them one of the predominant races across the continent.
						<br/>
						Human society is rich and varied, encompassing a wide range of professions and cultures. They possess strong leadership and organizational skills, excelling in politics, commerce, and military affairs. The cooperation and alliances among Humans make them a formidable bulwark against dark forces.
						<br/>
						As natural explorers, Humans continuously venture into the unknown, seeking new knowledge and technologies. They have a broad interest in both magic and technology, achieving significant breakthroughs in both fields.
						<br/>
						However, Humans are not without flaws. They possess rich emotions and inner conflicts, sometimes leading to clashes for personal or racial interests. Nevertheless, their unity and mutual support have allowed them to stand strong throughout history, leaving a profound impact on the world of Cardaether.`,
						zh: `人族是Cardaether中最为广泛和多样的种族之一。他们居住在各种不同的地域，从宽广的城市到蔓延的乡村，展现着他们的多样性和适应力。人族以其智慧、创造力和团结力而闻名，成为整个大陆上最主要的种族之一。
						<br/>
						人族社会丰富多彩，包含各种不同的职业和文化。他们拥有强大的领导能力和组织才能，使得人族在政治、商业和军事领域都有卓越的表现。人族之间的合作和联盟使他们成为抵御黑暗势力的坚固堡垒。
						<br/>
						作为天生的探索家，人族不断探索着未知的领域，寻找新的知识和技术。他们对于魔法和科技都有着广泛的兴趣，并且在这两个领域取得了许多突破。
						<br/>
						然而，人族并非完美无缺。他们有着丰富的情感和内心的矛盾，有时会为了个人或种族的利益而发生冲突。尽管如此，他们的团结力和相互扶持使得人族始终屹立不倒，并且在Cardaether的历史上留下了深远的影响。`,
						elem: ["Wind", "Fire", "Water", "Light", "Dark"],
						elem_zh: ["风", "火", '水', "光", "暗"]
					},
					{
						name: "Elves",
						name_zh: "精灵族",
						icon1: require("../assets/zztb/jl1.svg"),
						icon2: require("../assets/zztb/jl2.svg"),
						img: require("../assets/zzwz/jl.png"),
						en: `The Elven race is one of the noble and enigmatic races in Cardaether. They dwell in lush forests and mystical lands, deeply connected to nature. Elves are renowned for their grace, agility, and wisdom, being regarded as the guardians and representatives of the natural world.
						<br/>
						Elves possess a long lifespan, intricately intertwined with the cycles of nature. They listen to the whispers of the wind through the leaves, dance with flocks of birds, and display unparalleled elegance in the morning sunlight.
						<br/>
						As guardians of the forests, the Elven race protects the mystical forces of nature and wields powerful nature magic. They excel in manipulating plants and elements, summoning the power of nature to shield allies and punish enemies.
						<br/>
						While Elves often keep a distance from other races, they unite in the face of common threats. They establish friendly relations with other races and offer assistance and guidance when needed.
						<br/>
						The Elven race boasts a long and mysterious history, preserving many ancient traditions and wisdom. Their social structure is highly sophisticated, respecting the laws of nature, making them one of the oldest and most venerable races in the entire world of Cardaether.`,
						zh: `精灵族是Cardaether中高贵而神秘的种族之一。他们生活在茂密的森林和神秘的大地之中，与自然紧密相连。精灵以其优雅、敏捷和智慧而著称，被誉为大自然的守护者和代表。
						<br/>
						精灵族有着漫长的寿命，他们的生命与大自然的循环息息相关。他们倾听风吹过树叶的声音，与群鸟共舞，在清晨的阳光中显露出无与伦比的优雅。
						<br/>
						作为森林的守护者，精灵族保护着神秘的自然力量，并且具有强大的自然魔法。他们擅长操控植物和元素，可以召唤大自然的力量来保护友军和惩罚敌人。
						<br/>
						尽管精灵族通常与其他种族保持距离，但他们在面对共同威胁时会团结一致。他们与其他种族建立友好的关系，并在需要时提供援助和指导。
						<br/>
						精灵族的历史悠久而神秘，他们保留着许多古老的传统和智慧。他们的社会结构高度发达，尊重自然法则，是整个Cardaether世界中最古老和庄重的种族之一。`,
						elem: ["Nature", "Wind", "Light", "Ice", "Earth"],
						elem_zh: ["自然", "风", "光", "冰", "土"]
					},
					{
						name: "Dwarves",
						name_zh: "矮人族",
						icon1: require("../assets/zztb/ar1.svg"),
						icon2: require("../assets/zztb/ar2.svg"),
						img: require("../assets/zzwz/ar.png"),
						en: `The Dwarven race is one of the resilient and artisan races in Cardaether. They dwell deep within the sturdy mines and majestic fortresses in the mountains, renowned for their industry and creativity. Dwarves take pride in their unwavering determination and unmatched craftsmanship, being outstanding engineers and blacksmiths.
						<br/>
						Dwarves have an unending pursuit of ores and gemstones, with their mines hiding abundant treasures. They excel in mining ores and transforming them into splendid jewels and sturdy weapons. Dwarven blacksmithing is unparalleled, crafting weapons and armors renowned for their indestructibility.
						<br/>
						As brave warriors, Dwarves are steadfast shields and powerful attackers on the battlefield. They possess innate skills in combat, adeptly wielding various weapons and tactics. Dwarves never forsake their oaths, and their loyalty and steadfastness are truly admirable.
						<br/>
						Dwarven society places high importance on family and tradition, passing down precious history and skills through generations. While Dwarves generally maintain caution with outsiders, their friendships and alliances are unyielding. Throughout Cardaether's history, the Dwarven race has made significant contributions in building magnificent cities and forging powerful kingdoms.`,
						zh: `矮人族是Cardaether中坚韧而工匠的种族之一。他们居住在山脉深处的坚固矿洞和雄伟城堡中，以其勤劳和创造力而著称。矮人以其坚定的意志和无与伦比的工艺技艺而自豪，是最出色的工程师和铁匠。
						<br/>
						矮人族对矿石和珠宝有着无尽的追求，他们的矿洞里隐藏着丰富的宝藏。他们擅长开采矿石，将其转化为华丽的珠宝和坚固的武器。矮人的铁匠工艺无与伦比，他们制造的武器和防具被誉为最坚不可摧。
						<br/>
						作为勇敢的战士，矮人族是战场上的坚实盾牌和有力的攻击者。他们对于战斗有着天生的本领，善于使用各种武器和战术。矮人永远不会背弃自己的誓言，他们的忠诚和坚定令人钦佩。
						<br/>
						矮人族的社会高度重视家庭和传统，他们世代相传着珍贵的历史和技艺。虽然矮人通常保持谨慎对外，但他们与朋友和盟友之间的友情是坚不可摧的。在Cardaether的历史上，矮人族为建造辉煌城市和铸造强大王国作出了巨大贡献。`,
						elem: ["Earth", "Fire", "Metal", "Rock", "Craftsmanship"],
						elem_zh: ["地", "火", "金属", "岩石", "工艺"]
					},
					{
						name: "Beasts",
						name_zh: "兽族",
						icon1: require("../assets/zztb/sr1.svg"),
						icon2: require("../assets/zztb/sr2.svg"),
						img: require("../assets/zzwz/sr.png"),
						en: `The Beastfolk race is one of the wild and powerful races in Cardaether. They dwell in lush forests, vast grasslands, and rugged mountains, closely connected to wild animals and nature. Beastfolk are renowned for their ferocity, strength, and courage, being one of the most primal and formidable races in the natural world.
						<br/>
						Beastfolk often live in harmony with beasts and animals, able to communicate and cooperate with them. They excel in hunting and gathering, relying on natural resources for sustenance. Beastfolk warriors adopt beast-like tactics and agility in battle, displaying formidable offensive and defensive capabilities.
						<br/>
						As guardians of nature, Beastfolk protect the environment and ecological balance. Their reverence and respect for nature make them allies of many creatures.
						<br/>
						The social structure of Beastfolk is relatively simple, emphasizing alliances between tribes. They possess strong group cohesion, collectively defending against external threats. While Beastfolk engage less frequently with other races, their loyalty and combat prowess command awe and respect from others.
						<br/>
						The history of Beastfolk is deeply influenced by nature, respecting natural laws and mysterious elven powers. Their culture and beliefs are closely tied to nature and animals, making them one of the most primal and enigmatic races in the world of Cardaether.`,
						zh: `兽族是Cardaether中野性而强大的种族之一。他们生活在茂密的森林、辽阔的草原和荒凉的山地中，与野生动物和自然紧密相连。兽族以其野性、力量和勇气而著称，是自然界中最为原始和强大的种族之一。
						<br/>
						兽族通常与野兽和动物结伴生活，能够与它们沟通和协作。他们擅长捕猎和采集，以自然的产物为生。兽族的战士在战斗中采用野兽般的战术和敏捷，具有强大的进攻和防御力。
						<br/>
						作为自然的守护者，兽族保护着自然环境和生态平衡。他们对于大自然的珍视和尊重使得他们成为了许多生灵的盟友。
						<br/>
						兽族的社会结构通常较为简单，注重部族之间的联合。他们有着强大的群体凝聚力，共同抵御外部的威胁。尽管兽族在与其他种族的交往中较少，但他们的忠诚和战斗力使得其他种族对他们敬畏和尊重。
						<br/>
						兽族的历史深受大自然的影响，他们尊重自然规律和神秘的精灵力量。他们的文化和信仰紧密与自然和动物相关，是Cardaether世界中最为原始和神秘的种族之一。`,
						elem: ["Nature", "Earth2", "Fire", "Grass", "Beast"],
						elem_zh: ["自然", "土", "火", "草", "野兽"]
					},
					{
						name: "Vampires",
						name_zh: "吸血鬼族",
						icon1: require("../assets/zztb/xxg1.svg"),
						icon2: require("../assets/zztb/xxg2.svg"),
						img: require("../assets/zzwz/xxg.png"),
						en: `The Vampire race is one of the mysterious and charismatic races in Cardaether. They lurk in the dark of the night, feeding on the essence of life. Vampires are known for their extraCommon strength, speed, and bloodthirsty nature, making them the most fearsome and alluring beings of the night.
						<br/>
						Legend has it that Vampires originated from a powerful and ancient bloodline, gaining their strength from consuming the blood of other creatures. They seek out potent hosts to turn into new vampires and establish a symbiotic relationship.
						<br/>
						Vampires rely on blood to sustain their existence, otherwise, they will gradually weaken. However, they are also adept at beguiling and enticing other races, luring the innocent with their charming appearance and irresistible allure.
						<br/>
						Despite being seen as symbols of darkness and evil, Vampires possess a rich culture and wisdom. They wield unique magic and bloodline abilities, harnessing powers beyond the reach of other races.
						<br/>
						The Vampire society is extremely secretive, skilled at concealing their tracks. They emerge in the depths of the night to fulfill their mysterious missions. While Vampires are often perceived as solitary beings, they also have complex political and social relationships amongst themselves.`,
						zh: `吸血鬼族是Cardaether中神秘而富有魅力的种族之一。他们潜伏在黑暗的夜晚，以吸取生命之源为生。吸血鬼因其超乎寻常的力量、速度和嗜血本性而闻名，是夜晚中最为可怕和诱人的存在。
						<br/>
						传说中，吸血鬼起源于强大而古老的血族，其力量源自吸取其他生物的鲜血。他们寻找着有力的宿主，让其变成新的吸血鬼，并与之建立主从关系。
						<br/>
						吸血鬼族必须依赖鲜血维持生命，否则会逐渐衰弱。然而，他们同时也擅长迷惑和诱惑其他种族，通过迷人的外表和迷人的魅力吸引无辜者。
						<br/>
						尽管吸血鬼被视为黑暗和邪恶的象征，但他们也有着丰富的文化和智慧。他们拥有独特的魔法和血族技能，可以掌握其他种族无法企及的力量。
						<br/>
						吸血鬼社会极其隐秘，善于隐藏自己的踪迹。他们在深夜中出现，完成他们神秘的使命。尽管吸血鬼被视为孤独的存在，但他们之间也有着复杂的政治和社交关系。`,
						elem: ["Dark", "Blood", "Night", "Curse", "Illusion"],
						elem_zh: ["暗", "血", "夜", "咒", "幻"]
					},
					{
						name: "Undead",
						name_zh: "亡灵族",
						icon1: require("../assets/zztb/ml1.svg"),
						icon2: require("../assets/zztb/ml2.svg"),
						img: require("../assets/zzwz/ml.png"),
						en: `The Undead race is one of the mysterious and shadowy races in Cardaether. They are the souls that refuse to depart the world after death, driven by dark forces. Undead are known for their gloomy and immortal nature, being the most enigmatic and terrifying beings at the boundary of life and death.
						<br/>
						The origin of the Undead is surrounded by unknown secrets. Some Undead linger in the world due to strong desires or obsessions, while others are enslaved by malevolent forces and become servants of the Undead.
						<br/>
						As Undead, their appearance is typically pale and chilling, with eyes glinting with cold light. They walk in the darkness, able to manipulate shadows and eerie energies. Immune to the ravages of time and harm, the Undead are indifferent to the deaths and lives of mortals.
						<br/>
						Undead society is often concealed in dark places, keeping a distance from other races. However, some individual Undead yearn to understand the world of the living and attempt to assimilate into it.
						<br/>
						While the Undead have a dark side, they also possess profound contemplation and wisdom. Some Undead seek redemption, correcting their past mistakes, while others pursue power and revenge.
						<br/>
						The history of the Undead is trapped in eternal mist, their existence remaining a mystery to other races. They are both symbols of death and beings bound by mysterious forces.`,
						zh: `亡灵族是Cardaether中神秘而幽暗的种族之一。他们是那些死后却拒绝离开世界的灵魂，由黑暗的力量所驱使。亡灵族以其阴郁和不朽的特性而著称，是生死交界中最神秘和恐怖的存在。
						<br/>
						亡灵族的起源环绕着不为人知的秘密。有些亡灵是因为强大的愿望或执念而留在世界上，有些则是被邪恶力量所奴役，成为亡灵的仆从。
						<br/>
						作为亡灵，他们的外表通常苍白而骇人，眼眸中闪烁着冰冷的光芒。他们行走于黑暗之中，能够操控幽影和诡异的能量。亡灵不受时间和伤害的摧残，对常人的死亡和生命已无动于衷。
						<br/>
						亡灵族的社会通常隐匿在阴暗之处，与其他种族保持距离。然而，一些亡灵个体渴望理解生者的世界，并试图融入其中。
						<br/>
						虽然亡灵族有着阴暗的一面，但他们也有着深刻的思考和智慧。有些亡灵倾向于寻求救赎，纠正他们生前的错误，而另一些则寻求力量和复仇。
						<br/>
						亡灵族的历史被困在永恒的迷雾中，他们的存在对于其他种族而言是一个谜。他们既是死亡的象征，也是被神秘力量所束缚的生命。`,
						elem: ["Dark", "Death", "Ice", "Curse", "Spirit"],
						elem_zh: ["暗", "死", "冰", "咒", "灵"]
					},
					{
						name: "Angels",
						name_zh: "天使族",
						icon1: require("../assets/zztb/ts1.svg"),
						icon2: require("../assets/zztb/ts2.svg"),
						img: require("../assets/zzwz/ts.png"),
						en: `The Angel race is one of the bright and divine races in Cardaether. They are guardians from the realm of high heavens, possessing pure and virtuous nature. Angels are known for their beautiful wings and miraculous magic, being beings of divine power.
						<br/>
						Angels originated from the mystical heavens, serving as messengers and servants of the gods. They are entrusted with the important mission of protecting life and maintaining order, driving out evil and darkness through divine power.
						<br/>
						Angels usually have glorious appearances, with pure white wings and awe-inspiring radiance. They walk on the mortal plane, spreading light and hope, bringing solace and shelter to suffering souls.
						<br/>
						As denizens of heaven, Angels are closely connected and united. They assist each other, jointly fulfilling the sacred missions bestowed by the divine. Angels possess formidable magical abilities, capable of casting healing and protective spells, as well as combating and expelling dark forces.
						<br/>
						Despite being symbols of light, Angels face challenges from dark forces. They fear no danger, resolutely defending justice and truth, willing to sacrifice their lives to protect the weak and innocent.
						<br/>
						The history of the Angel race is closely tied to the gods and heaven, evoking awe and reverence. Their mission remains eternal, spreading light and blessings to every corner of Cardaether.`,
						zh: `天使族是Cardaether中光明和神圣的种族之一。他们是来自高天之域的守护者，拥有纯洁善良的本质。天使以其美丽的羽翼和神奇的魔法而著称，是拥有神圣力量的存在。
						<br/>
						天使族起源于神秘的天堂，他们是众神的使者和仆人。他们被赋予了守护生命和维护秩序的重要使命，通过神圣的力量驱逐邪恶和黑暗。
						<br/>
						天使通常拥有辉煌的外貌，纯白的羽翼，和令人惊叹的光芒。他们行走于凡间，宣扬着光明和希望，为受苦的灵魂带来慰藉和庇护。
						<br/>
						作为天堂的居民，天使族之间的联系紧密而团结。他们相互协助，共同完成神明所赋予的神圣使命。天使具有强大的魔法能力，可以施展治愈和保护的法术，也能够战斗驱逐黑暗势力。
						<br/>
						尽管天使是光明的象征，但他们也面临着来自黑暗势力的挑战。他们不畏惧危险，坚定地捍卫着正义和真理，不计牺牲自己的生命来保护弱者和无辜者。
						<br/>
						天使族的历史与神明和天堂紧密相连，他们的存在令人敬畏和崇拜。他们的使命永恒不变，将光明和祝福带给Cardaether的每个角落。`,
						elem: ["Light", "Holy", "Wind", "Justice", "Healing"],
						elem_zh: ["光", "神圣", "风", "正义", "治愈"]
					},
					{
						name: "Demons",
						name_zh: "恶魔族",
						icon1: require("../assets/zztb/em1.svg"),
						icon2: require("../assets/zztb/em2.svg"),
						img: require("../assets/zzwz/em.png"),
						en: `The Demon race is one of the dark and malevolent races in Cardaether. They are fallen angels who have forsaken the light, pursuing power and dark forces. Demons are known for their malevolent nature and formidable magic, being the most challenging and dangerous beings.
						<br/>
						Legend has it that Demons originated from heaven, but they questioned the will of the gods. Their desire for power and freedom led them to betray the divine order and tread the path of darkness.
						<br/>
						Demons usually have fierce appearances, horn-shaped horns, black wings, and eyes emanating a dark aura. They walk between hell and the mortal plane, spreading temptation and destruction, seeking to enslave souls.
						<br/>
						As fallen angels, the relationships among the Demon race are complex and ever-changing. There are conflicts and power struggles among them, as they vie for more power and status at all costs.
						<br/>
						Demons excel in using dark magic and curses, capable of inflicting endless pain and destruction. Their goal is to spread evil, plunging life into endless fear and despair.
						<br/>
						Despite being symbols of evil, Demons also possess captivating charm and wisdom. Some Demons yearn to break free from the shackles of darkness, seeking redemption and change, while others have committed irreversible sins, fully embracing their malevolence.
						<br/>
						The history of the Demon race is filled with bloodshed and intrigue, causing other races to deeply fear and dread the power of darkness.`,
						zh: `恶魔族是Cardaether中黑暗和邪恶的种族之一。他们是堕落的天使，背弃了光明，追求着权力和黑暗的力量。恶魔以其邪恶的本性和强大的魔法而著称，是最具挑战性和危险性的存在。
						<br/>
						传说中，恶魔起源于天堂，但他们对于神明的意愿产生了质疑。他们对权力和自由的渴望，使得他们背叛了神圣的秩序，而选择了踏入黑暗的道路。
						<br/>
						恶魔通常拥有凶狠的外貌，角状的角和黑色的羽翼，以及散发着黑暗气息的目光。他们行走于地狱和凡间，传播着诱惑和毁灭，寻求着灵魂的奴役。
						<br/>
						作为堕落的天使，恶魔族之间的关系复杂而多变。他们之间存在着争斗和勾心斗角，为了争夺更多的力量和地位而不惜一切。
						<br/>
						恶魔擅长使用黑暗魔法和诅咒，能够带来无尽的痛苦和毁灭。他们的目标是传播邪恶，使生命陷入无尽的恐惧和绝望。
						<br/>
						尽管恶魔是邪恶的象征，但他们也有着迷人的魅力和智慧。有些恶魔渴望摆脱黑暗的束缚，寻求救赎和改变，而另一些则铸成不可挽回的罪孽，将邪恶发挥到极致。
						<br/>
						恶魔族的历史充满着血腥和阴谋，他们的存在让其他种族对于黑暗的力量深感忌惮和恐惧。`,
						elem: ["Dark", "Fire", "Evil", "Chaos", "Corruption"],
						elem_zh: ["暗", "火", "邪", "混沌", "堕落"]
					},
				],
				zzwzIndex: 0,

				sjbj_show: false,
				zzjs_show: false,
				cdr_show: false,
				jyjs_show: false,
				tip_show: false,
				
				zz_img_loading: true

			}
		},
		created() {
			
		},
		mounted() {
			this.isMobile = document.isMobile;
			
			// window.onresize = () => {
			// 	if(this.isMobile != window.innerWidth < 1200) {
			// 		location.reload();
			// 	}
			// 	console.log(window.innerWidth);
			// 	this.isMobile = window.innerWidth < 1200;
				
			// 	// if(Math.abs(window.innerWidth-1200) < 10) {
			// 	// 	console.log(123123123)
			// 	// 	location.reload();
			// 	// }
			// 	// this.sjcont_height = window.innerWidth / 2.7;
			// }

			// this.$refs.video1.play();
			// this.$refs.video2.play();
			
			// console.log(this.$i18n.locale);
			
			this.getData();
		},
		methods: {
			getData() {
				this.$http.get("api-open/card_pool/list").then(result => {
					// console.log(result.body);
					this.imgList = result.body;
				}).catch(error => {
					console.log(error);
					this.$message({
						type: 'error',
						message: '数据获取失败'
					});
				})
			},
			mousemove() {
				this.$refs.video.play();
			},
			zzbt_click(idx) {
				this.zzwzIndex = idx;
				this.zz_img_loading = true;
			},
			zzwzImg_load() {
				this.zz_img_loading = false;
			},
			lang_click() {
				this.showLang = !this.showLang;
				clearTimeout(lang_time);
				lang_time = setTimeout(_ => this.showLang = false, 3000);
			},
			lang_over() {
				// this.showLang = true;
				// clearTimeout(lang_time);
				// lang_time = setTimeout(_ => this.showLang=false, 3000);
			},
			lang_out() {
				this.showLang = false;
			},
			personItem_click(idx) {
				if(idx == 0) {
					
				} else if(idx == 1) {
					
				}
			},
			sjbj_more() {
				this.sjbj_show = true;
			},
			sjbj_mask() {
				this.sjbj_show = false;
			},
			zzjs_more() {
				this.zzjs_show = true;
			},
			zzjs_mask() {
				this.zzjs_show = false;
			},
			cdr_more() {
				this.cdr_show = true;
			},
			cdr_mask() {
				this.cdr_show = false;
			},
			jyjs_more() {
				this.jyjs_show = true;
			},
			jyjs_mask() {
				this.jyjs_show = false;
			},
			tip_click() {
				this.tip_show = true;
			},
			tip_mask() {
				this.tip_show = false;
			},
			draw1_click() {
				if(this.isMobile) {
					this.$message.closeAll();
				
					this.$message({
						type: 'warning',
						message: this.$i18n.locale=='en-US'?"Mobile devices currently do not have this feature":'移动端暂无此功能'
					});
					return;
				}
				this.$router.push("cardpool")
				// this.tip_show = true;
			},
			draw10_click() {
				if(this.isMobile) {
					this.$message.closeAll();
				
					this.$message({
						type: 'warning',
						message: this.$i18n.locale=='en-US'?"Mobile devices currently do not have this feature":'移动端暂无此功能'
					});
					return;
				}
				this.$router.push("cardpool")
				// this.tip_show = true;
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.container {
		min-width: 1430px;
	}
	
	body::-webkit-scrollbar {
		width: 0 !important;
	}
	
	
	::-webkit-scrollbar {
		width: 0px;
		height: 4px;
	}
	
	::-webkit-scrollbar-track {
		background-color: transparent;
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}
	
	::-webkit-scrollbar-thumb {
		background-color: rgba(147, 147, 153, 0.5);
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}
	
	/* 头部 */
	.head {
		width: 100%;
		min-height: 500px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.isMobile {
		.head {
			min-height: 1024px;
		}
	}

	.tou-img {
		width: 100%;
	}

	.logo {
		top: 0;
		/* left: 0; */
		position: absolute;
		width: 29%;
		margin-top: 19%;
	}

	.xlxb {
		bottom: 0;
		position: absolute;
		width: 2%;
		margin-bottom: 3%;
	}

	/* 世界背景 */
	.sjbj {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 76px;
	}

	.sjcont {
		width: 1330px;
		height: 710px;
		background-image: url("../assets/sjbj.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
	}
	.isMobile {
		.sjcont {
			width: 96%;
		}
	}

	.sjbjbt {
		width: 60%;
		margin: auto;
	}

	.sjbj-cont {
		/* position: absolute; */
		color: #fff;
		font-size: 14px;
		box-sizing: border-box;
		padding: 3% 6%;
		/* letter-spacing: 20px; */
		line-height: 33px;
		max-height: 500px;
		/* height: 200px; */

		overflow-x: hidden;
		overflow-y: scroll;
	}
	.sjbj-cont pre {
		white-space: pre-wrap;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
		margin: unset;
	}

	/* 种族介绍 */
	.zzjs {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 160px;
	}

	.zzcont {
		width: 1300px;
		/* height: 710px; */
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.zzjsbt {
		width: 60%;
		margin: auto;
	}

	.quan {
		/* background-image: url("../assets/zzjsq.png"); */
		width: 870px;
		height: 870px;
		margin-top: 180px;
		text-align: center;
		position: relative;
		overflow: hidden;
	}

	.video1 {
		position: absolute;
		top: 0;
		left: 0;
		width: 870px;
		height: 489px;
		margin-top: 140px;
		transform: scale(210%);
	}

	.zzwz {
		width: 500px;
		position: absolute;
		top: -90px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 10px;
		border: 1px solid #fff;
		
		// &::after {
		// 	content: "";
		// 	width: 100%;
		// 	height: 100%;
		// 	position: absolute;
		// 	top: 0%;
		// 	left: 0%;
		// 	right: 0%;
		// 	bottom: 0%;
		// 	background: rgba(255, 255, 255, 0.5);
		// }
	}

	/* 左边 */
	.zzjs .left {
		position: absolute;
		/* top: 0; */
		left: 0;
		bottom: 90px;
	}

	.zzjs .left img {
		width: 236px;
	}

	.zzjs .left .cont {
		width: 236px;
		height: 361px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
		border-radius: 0px 20px 0px 20px;
		box-sizing: border-box;
		padding: 25px 30px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
		/* overflow: hidden; */
		overflow-y: scroll;
		margin-top: 19px;
	}

	.more {
		font-size: 14px;
		font-family: Impact;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
		float: right;
	}

	.element {
		margin-top: 68px;
	}

	.sanq {
		width: 211px;
		height: 211px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 63px;
	}

	.qq {
		position: absolute;
	}

	.zzjs .left .q1 {
		width: 100%;
		height: 100%;
		background: #FFFFFF;
		opacity: 0.2;
		border-radius: 50%;
	}

	.zzjs .left .q2 {
		width: 83.9%;
		height: 83.9%;
		background: #FFFFFF;
		opacity: 0.1;
		border-radius: 50%;
	}

	.zzjs .left .q3 {
		width: 61.6%;
		height: 61.6%;
		background: #FFFFFF;
		opacity: 0.1;
		border-radius: 50%;
	}

	.siy {
		position: relative;
	}

	.zzjs .left .r {
		width: 211px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		/* line-height: 33px; */
		position: absolute;
	}

	.zzjs .left .r img {
		width: 24px;
		height: 24px;
	}

	.zzjs .left .r div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.zzjs .left .r1 {
		top: 6px;
	}

	.zzjs .left .r2 {
		top: 40%;
		transform: translateY(-50%);
		display: flex;
		justify-content: space-between;
		padding: 6px;
		box-sizing: border-box;
	}

	.zzjs .left .r3 {
		bottom: 6px;
		display: flex;
		justify-content: space-between;
		padding: 16px 32px;
		box-sizing: border-box;
	}

	/* 右边 */
	.zzjs .right {
		position: absolute;
		/* top: 0; */
		right: 0;
		bottom: 90px;
	}

	.zzjs .right .zzbt {
		width: 200px;
		display: flex;
		align-items: center;
		margin-top: 35px;
	}

	.zzjs .right .zzbt .name {
		width: 100px;
		overflow: hidden;
		text-align: end;
	}

	.zzjs .right span {
		font-size: 20px;
		font-family: Impact;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
	}

	.zzjs .right img {
		width: 80px;
		height: 80px;
		background: #2A344E;
		border-radius: 50%;
		margin-left: 24px;
	}

	/* 卡池 */
	.kac {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 22vw;
	}

	.draw {
		width: 1150px;
		margin-top: 89px;
		display: flex;
		justify-content: space-between;
		z-index: 0;
	}

	.draw img {
		width: 475px;
		height: 120px;
		cursor: pointer;
	}


	.kccont {
		width: 1300px;
		/* height: 710px; */
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.kcbt {
		width: 60%;
		margin: auto;
	}

	.kac .sj {
		font-size: 20px;
		font-family: Impact;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
	}

	.cka {
		width: 1189px;
		height: 400px;
		margin-top: 406px;
		overflow: hidden;
		/* background-image: url("../assets/ckdz.png"); */
		/* border: 2px solid; */
		/* border-image: linear-gradient(0deg, #FFFFFF, #111A32) 10 10;
		background: linear-gradient(0deg, #FFFFFF 0%, #111A32 100%);
		border-radius: 50%; */
	}

	.cka .video2 {
		position: absolute;
		width: 1189px;
		// bottom: -190px;
		transform: scale(120%);
		// z-index: -1;
	}

	.cka .swiper {
		width: 100%;
		left: 0;
		bottom: 200px;
		position: absolute;
		box-sizing: border-box;
		padding: 0 160px;
	}

	.swiper-slide {
		width: 35%;
		height: 542px;
		display: flex;
		justify-content: center;
	}

	.swiper-slide img {
		width: 304px;
		height: 542px;
		/* background: #D9001B; */
		border: 1px solid #ECECEC;
		display: none;
	}

	/* 如果想要两边的图片显示时缩小加上一下css代码 */
	/* 默认中间的图片会带有类名 : .swiper-slide-acive; */
	/* 默认中间的图片的上一张图片会带有类 名:  .swiper-slide-prev ; */
	/* 默认中间的图片的下一张会带有类名: .swiper-slide-next ;. */
	.swiper-slide-next {
		transform: scale(.8);
	}
	.swiper-slide-next img {
		display: block;
	}
	.swiper-slide-active img {
		display: block;
	}
	.swiper-slide-prev {
		transform: scale(.8);
	}
	.swiper-slide-prev img {
		display: block;
	}


	/* 更多活动 */
	.cdr {
		display: flex;
		justify-content: center;
		margin-top: 86px;
	}

	.cdr .cont {
		width: 1300px;
		/* height: 180px; */
		background: linear-gradient(90deg, rgba(30, 36, 53, 0.8), rgba(17, 26, 50, 0.8));
		border-radius: 0px 20px 0px 20px;
		box-sizing: border-box;
		padding: 0px 14px;
		padding-top: 30px;
		overflow: hidden;
	}

	.cdr .cont pre {
		white-space: pre-wrap;
		padding-left: 40px;
	}

	.cdr .img {
		width: 19px;
		height: 19px;
	}

	.cdr .txt {
		font-size: 20px;
		font-family: Impact;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
		margin-left: 13px;
	}

	.cdr .pre {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
	}

	/* 交易介绍 */
	.jyjs {
		display: flex;
		justify-content: center;
		margin-top: 204px;
	}

	.jyjs .jycont {
		width: 1300px;
		/* height: 710px; */
		/* display: flex; */
		/* flex-direction: column; */
		/* justify-content: center; */
		/* align-items: center; */
		position: relative;
	}

	.jyjs .jyjsbt {
		width: 60%;
		margin: auto;
	}

	.jyjs .main {
		width: 1300px;
		height: 628px;
		background: linear-gradient(90deg, #1E2435, rgba(17, 26, 50, 0));
		/* opacity: 0.2; */
		border-radius: 0px 20px 0px 20px;
		display: flex;
		margin-top: 120px;
	}

	.jyjs .main img {
		transform: scale(1.2) translate(30px, 50px);
	}

	.jyjs .cont {
		/* width: 783px; */
		height: 600px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
		overflow-y: scroll;
		padding: 38px;
		box-sizing: border-box;
		margin-left: 70px;
	}

	.jyjs .cont div {
		margin-bottom: 30px;
		width: 100%;
	}
	.jyjs .sand {
		width: 822px;
		height: 31px;
		background: linear-gradient(90deg, #1E2435, rgba(17, 26, 50, 0));
		/* opacity: 0.5; */
		border-radius: 0px 20px 0px 0px;
		position: absolute;
		/* background-color: #D9001B; */
		bottom: -70px;
		right: 0;
		display: flex;
	}

	.jyjs .sand .s1 {
		width: 31px;
		height: 31px;
		background: #FFFFFF;
		border-radius: 50%;
		margin-right: 12px;
	}

	.jyjs .sand .s2 {
		width: 31px;
		height: 31px;
		background: #FFFFFF;
		opacity: 0.5;
		border-radius: 50%;
		margin-right: 12px;
	}

	.jyjs .sand .s3 {
		width: 31px;
		height: 31px;
		background: #FFFFFF;
		opacity: 0.3;
		border-radius: 50%;
		margin-right: 12px;
	}

	.jyjs .sand .s4 {
		width: 31px;
		height: 31px;
		background: #FFFFFF;
		opacity: 0.1;
		border-radius: 50%;
		margin-right: 12px;
	}


	/* 卡牌交换 */
	.kpjh {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 241px;
	}

	.jhcont {
		width: 1300px;
		/* height: 710px; */
		display: flex;
		justify-content: space-between;
		/* justify-content: center; */
		/* align-items: center; */
		position: relative;
	}

	.kpjh .left {
		background-image: url("../assets/kpjh.png");
		background-size: 100% 100%;
		width: 522px;
		height: 628px;
		background-color: #1C2849;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.kpjh .left img {
		width: 119px;
		height: 119px;
		background: rgba(255, 2255, 255, 0.2);
		border-radius: 50%;
	}

	.kpjh .left .txt {
		font-size: 40px;
		font-family: Impact;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
		margin-top: 49px;
	}

	.kpjh .left .btn {
		width: 171px;
		height: 40px;
		border: 1px solid #FFFFFF;
		border-radius: 20px;
		font-size: 20px;
		font-family: Impact;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 40px;
		text-align: center;
		margin-top: 34px;
	}

	.kpjh .right {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.kpjh .right .top {
		background-image: url("../assets/kppm.png");
		background-size: 100% 100%;
		width: 745px;
		height: 299px;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.kpjh .right .bot {
		background-image: url("../assets/gdhd.png");
		background-size: 100% 100%;
		width: 745px;
		height: 299px;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		box-sizing: border-box;
		padding-right: 61px;
	}

	.kpjh .right .top .txt {
		font-size: 40px;
		font-family: Impact;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
	}

	.kpjh .right .top .btn {
		width: 171px;
		height: 40px;
		border: 1px solid #FFFFFF;
		border-radius: 20px;
		font-size: 20px;
		font-family: Impact;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 40px;
		text-align: center;
		margin-top: 31px;
	}

	.kpjh .right .bot img {
		width: 102px;
		height: 102px;
	}

	.kpjh .right .bot .btn {
		width: 171px;
		height: 40px;
		border: 1px solid #FFFFFF;
		border-radius: 20px;
		font-size: 20px;
		font-family: Impact;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 40px;
		text-align: center;
		margin-top: 58px;
	}

	.foot {
		width: 100%;
		position: absolute;
		left: 0;
		margin-top: -400px;
		z-index: -1;
	}
	.foot .div {
		position: relative;
		z-index: -1;
	}
	.foot .copyright {
		color: #fff;
		position: absolute;
		left: 10px;
		bottom: 10px;
	}

	.foot img {
		width: 100%;
	}

	.more1 {
		display: none;
	}

	.sjbj-dialog {
		display: none;
	}



	.tip-dialog {
		display: block;
		position: fixed;
		top: 0%;
		right: 0%;
		bottom: 0%;
		left: 0%;
		z-index: 9;
	}

	.tip-dialog .mask {
		position: absolute;
		top: 0%;
		left: 0%;
		width: 100%;
		height: 100%;
		background-color: rgba(37, 35, 40, 0.6);
	}

	.tip-dialog .cont2 {
		width: 400px;
		height: 170px;
		background: #FFFFFF;
		border-radius: 20px 0px 20px 0px;
		color: #000;
		background-color: #FFFFFF;
		overflow: hidden;
		padding: 15px;
		padding-top: 25px;
		padding-bottom: 30px;
		box-sizing: border-box;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		transform: translate(calc(50vw - 200px), calc(50vh - 80px));
	}

	.tip-dialog .cont2 img {
		width: 33px;
		height: 33px;
	}

	.tip-dialog .cont2 .txt {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 33px;
		margin: 10px 0;
	}

	.tip-dialog .cont2 .conf {
		width: 110px;
		height: 30px;
		background: #111A32;
		border-radius: 15px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 30px;
	}

	.more {
		display: none;
	}


	.pre1 {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
		white-space: pre-wrap;
		
		/* width: 100%;
		overflow:hidden; 
		text-overflow:ellipsis; 
		display:-webkit-box; 
		-webkit-box-orient:vertical; 
		-webkit-line-clamp:6;
		font-size: 3.2vw;
		font-family: PingFang SC;
		font-weight: 400; */
	}
	

	.isMobile.container {
		min-width: unset;
	}
	
	.isMobile {
		
		.more {
			display: block;
		}
		.head {
			min-height: 200px;
		}
		.pre1 {
			width: 100%;
			white-space: pre-wrap;
			overflow:hidden; 
			text-overflow:ellipsis; 
			display:-webkit-box; 
			-webkit-box-orient:vertical; 
			-webkit-line-clamp:8;
			font-size: 3.2vw;
			line-height: 6vw;
			font-family: PingFang SC;
			font-weight: 400;
		}
		.pre2 {
			width: 100%;
			white-space: pre-wrap;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 3.2vw;
			font-family: PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 8vw;
			/* padding: 4vw; */
		}
		

		.sjbj {
			margin-top: 40px;
		}

		.sjcont {
			height: 95vw;
		}

		.sjbj-cont {
			padding-top: 3%;
			height: 66%;
		}
		
		.sjbj-cont pre {
			white-space: pre-wrap;
			font-size: 3.5vw;;
			font-family: PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 6vw;
			margin: unset;
			overflow: hidden;
			text-overflow:ellipsis;
			display:-webkit-box; 
			-webkit-box-orient:vertical; 
			-webkit-line-clamp:9;
		}

		.zzjs {
			margin-top: 60px;
		}

		.zzcont,
		.kccont,
		.cdr .cont,
		.jyjs .jycont,
		.jyjs .main,
		.jhcont {
			width: 100vw;
		}
		.zzcont {
			min-height: 959px;
		}

		.zzjs .right {
			top: 15vw;
			left: 0;
			right: 0;
			display: flex;
			flex-wrap: wrap;
			bottom: unset;
		}

		.zzjs .left .r3 {
			padding: 4.1vw 8.2vw;
		}
		.zzjs .right .zzbt {
			width: 24vw;
			height: 12vw;
		}

		.zzjs .right .zzbt img {
			width: 14vw;
			height: 14vw;
		}

		.zzjs .right .name {
			display: none;
		}

		.quan,
		.quan {
			width: 100vw;
		}

		.video1 {
			width: 80vw;
			height: 45vw;
			margin-top: 46vw;
			margin-left: -15vw;
		}

		.quan {
			height: 200vw;
		}
		.quan,
		.quan .zzwz {
			margin-top: 35vw;
			width: 50vw;
		}
		.quan .zzwz {
			top: -20vw;
		}

		.zzjs .left {
			bottom: -40px;
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 0 20px;
			left: 0%;
			right: 0%;
		}

		.sanq {
			width: 40vw;
			height: 40vw;
		}

		.zzjs .left .r {
			width: 40vw;
			font-size: 12px;
		}

		.zzjs .left .bg {
			width: 35vw;
		}

		.zzjs .left .element {
			margin-top: unset;
		}

		.zzjs .left .element .siy {}

		.zzjs .left .eidolon .cont {
			width: 45vw;
			height: 61vw;
			padding: 10px;
			overflow: hidden;
			font-size: 3.5vw;
			line-height: 6vw;
			
			/* font-size: 20px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px; */
		}
		.zzjs .left .eidolon .more {
			font-size: 3.5vw;
			margin-top: 2vw;
			/* line-height: 6vw; */
		}


		/* .quan, .quan img {
			width: 359px;
			height: 631px;
			background: #0C2C84;
			border: 2px solid #FFFFFF;
			border-radius: 30px;
		} */

		.cka {
			width: 100vw;
			height: unset;
			margin-top: 80vw;
		}

		.draw {
			width: 90vw;
			margin-top: unset;
		}

		.draw img {
			width: 40vw;
			height: 11vw;
		}

		.cka .video2 {
			width: 100vw;
			transform: unset;
			bottom: 0;
			left: 0;
			right: 0;
			transform: scale(120%);
		}

		.cka .swiper {
			padding: 0 60px;
			bottom: 130px;
		}

		.swiper-slide {
			width: 24vw;
			height: 41.34vw;
			display: flex;
			justify-content: center;
			/* height: 542px; */
			/* text-align: center; */
		}

		.swiper-slide img {
			width: 23vw;
			height: unset;
			/* background: #D9001B; */
			border: 1px solid #ECECEC;
			object-fit: contain;
		}

		.cdr {
			margin-top: 0px;
		}

		.cdr .cont {
			width: 100vw;
			margin: 20px;
		}

		.cdr .cont .more {
			color: #fff;
			font-size: 3.5vw;
		}

		.cdr .txt {
			font-size: 3.5vw;
		}

		.cdr .pre {
			font-size: 12px;
			height: 53vw;
			margin-bottom: 0;
			font-size: 3.5vw;
			line-height: 6vw;
			overflow: hidden;
			/* text-overflow:ellipsis;
			display:-webkit-box; 
			-webkit-box-orient:vertical; 
			-webkit-line-clamp:8; */
		}


		.sjbjbt,
		.zzjsbt,
		.kcbt,
		.jyjs .jyjsbt {
			width: 100%;
		}


		.jyjs .jycont {
			width: 100vw;
		}

		.jyjs .main {
			width: 100vw;
			height: unset;
			margin-top: 50px;
		}

		.jyjs .main img {
			width: 35vw;
			height: 100%;
		}

		.jyjs .cont {
			height: 60vw;
			margin-left: 5.1vw;
			overflow: hidden;
		}

		.jyjs .sand {
			width: 50vw;
			height: 20px;
		}

		.jyjs .sand .s1 {
			width: 20px;
			height: 20px;
		}

		.jyjs .sand .s2 {
			width: 20px;
			height: 20px;
		}

		.jyjs .sand .s3 {
			width: 20px;
			height: 20px;
		}

		.jyjs .sand .s4 {
			width: 20px;
			height: 20px;
		}


		.kpjh {
			width: 100vw;
			margin-top: 150px;
			box-sizing: border-box;
			padding: 3vw;
		}

		.kpjh .jhcont {
			padding: 8px;
			box-sizing: border-box;
		}

		.kpjh .left {
			width: 38vw;
			height: unset;
		}

		.kpjh .left img {
			width: 10vw;
			height: 10vw;
		}

		.kpjh .left .txt {
			font-size: 4vw;
			margin-top: 8vw;
		}

		.kpjh .left .btn {
			width: 15vw;
			height: unset;
			font-size: 2vw;
			line-height: 6vw;
		}

		.kpjh .right .top {
			width: 48vw;
			height: unset;
		}

		.kpjh .right .top .txt {
			font-size: 4vw;
			margin-top: 8vw;
		}

		.kpjh .right .top .btn {
			width: 15vw;
			height: unset;
			font-size: 2vw;
			line-height: 6vw;
			margin-top: 5vw;
			margin-bottom: 5vw;
		}

		.kpjh .right .bot {
			width: 48vw;
			height: unset;
			padding-right: 6vw;
			margin-top: 5vw;
		}

		.kpjh .right .bot img {
			width: 10vw;
			height: 10vw;
			margin-top: 3vw;
		}

		.kpjh .right .bot .btn {
			width: 15vw;
			height: unset;
			font-size: 2vw;
			line-height: 6vw;
			margin-top: 5vw;
			margin-bottom: 5vw;
		}

		.jyjs {
			margin-top: 10vw;
		}

		.foot {
			margin-top: -20vw;
			position: unset;
		}

		.more1 {
			display: block;
			color: #fff;
			margin: 0vw 6vw;
			font-size: 3.5vw;
		}
		.more2 {
			position: absolute;
			bottom: -40px;
			right: 10px;
		}

		.sjbj-dialog {
			display: block;
			position: fixed;
			top: 0%;
			right: 0%;
			bottom: 0%;
			left: 0%;
			z-index: 9;
		}

		.sjbj-dialog .mask {
			position: absolute;
			top: 0%;
			left: 0%;
			width: 100%;
			height: 100%;
			background-color: rgba(37, 35, 40, 0.6);
		}

		.sjbj-dialog .cont2 {
			width: 90vw;
			height: 66vh;
			/* top: 50%; */
			transform: translate(5vw, 20%);
			border-radius: 20px;
			color: #000 !important;
			background-color: #FFFFFF;
			overflow: hidden;
			padding: 15px;
			box-sizing: border-box;
			font-size: 3.5vw;
			line-height: 6vw;
		}

		.sjbj-dialog .cont2 .div {
			width: 100%;
			height: 100%;
			overflow-y: scroll;
		}


		.zzjs-dialog {
			display: block;
			position: fixed;
			top: 0%;
			right: 0%;
			bottom: 0%;
			left: 0%;
			z-index: 9;
		}

		.zzjs-dialog .mask {
			position: absolute;
			top: 0%;
			left: 0%;
			width: 100%;
			height: 100%;
			background-color: rgba(37, 35, 40, 0.6);
		}

		.zzjs-dialog .cont2 {
			width: 90vw;
			height: 120vw;
			/* top: 50%; */
			transform: translate(5vw, 20%);
			border-radius: 20px;
			color: #000 !important;
			background-color: #FFFFFF;
			overflow-y: scroll;
			padding: 15px;
			box-sizing: border-box;
		}


		.cdr-dialog {
			display: block;
			position: fixed;
			top: 0%;
			right: 0%;
			bottom: 0%;
			left: 0%;
			z-index: 9;
		}

		.cdr-dialog .mask {
			position: absolute;
			top: 0%;
			left: 0%;
			width: 100%;
			height: 100%;
			background-color: rgba(37, 35, 40, 0.6);
		}

		.cdr-dialog .cont2 {
			width: 90vw;
			height: 66%;
			/* top: 50%; */
			transform: translate(5vw, 20%);
			border-radius: 20px;
			color: #000;
			background-color: #FFFFFF;
			overflow: hidden;
			padding: 15px;
			padding-bottom: 30px;
			box-sizing: border-box;
		}

		.cdr-dialog .cont2 .pre {
			color: #000;
			white-space: pre-wrap;
			width: 100%;
			height: 100%;
			overflow-y: scroll;
			font-size: 3.5vw;
			line-height: 6vw;
		}

		
		.jyjs-dialog {
			display: block;
			position: fixed;
			top: 0%;
			right: 0%;
			bottom: 0%;
			left: 0%;
			z-index: 9;
		}
		
		.jyjs-dialog .mask {
			position: absolute;
			top: 0%;
			left: 0%;
			width: 100%;
			height: 100%;
			background-color: rgba(37, 35, 40, 0.6);
		}
		
		.jyjs-dialog .cont2 {
			width: 90vw;
			height: 66vh;
			/* top: 50%; */
			transform: translate(5vw, 20%);
			border-radius: 20px;
			color: #000 !important;
			background-color: #FFFFFF;
			overflow: hidden;
			padding: 15px;
			box-sizing: border-box;
		}
		
		.jyjs-dialog .cont2 .div {
			width: 100%;
			height: 100%;
			overflow-y: scroll;
		}
		

		.tip-dialog {
			display: block;
			position: fixed;
			top: 0%;
			right: 0%;
			bottom: 0%;
			left: 0%;
			z-index: 9;
		}

		.tip-dialog .mask {
			position: absolute;
			top: 0%;
			left: 0%;
			width: 100%;
			height: 100%;
			background-color: rgba(37, 35, 40, 0.6);
		}

		.tip-dialog .cont2 {
			width: 70.7vw;
			height: 38.7vw;
			transform: translate(14.65vw, 30vh);
			border-radius: 20px;
			color: #000;
			background-color: #FFFFFF;
			overflow: hidden;
			padding: 15px;
			padding-top: 25px;
			padding-bottom: 30px;
			box-sizing: border-box;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			/* margin-top: 10px; */
		}

		.tip-dialog .cont2 img {
			width: 4.4vw;
			height: 4.4vw;
		}

		.tip-dialog .cont2 .txt {
			font-size: 3.2vw;
			font-family: PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 3vw;
			padding: 3vw;
		}

		.tip-dialog .cont2 .conf {
			width: 26.7vw;
			height: 8vw;
			background: #111A32;
			border-radius: 30px;
			font-size: 3.2vw;
			font-family: PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 8vw;
		}
		
		.quan {
			overflow: unset;
		}
	}


	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.3s;
		transform: scale(1) 0.3s;
		transition-delay: 0s;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
		transform: scale(0);
		transition-delay: 0s;
	}
</style>

<style>
	.quan .el-loading-mask {
		background-color: unset;
	}
</style>