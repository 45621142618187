import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import cardpool from '../views/cardpool.vue'
import mycollection from '../views/mycollection.vue'
import mintNfts from '../views/mintNfts.vue'
import policy from "../views/policy.vue"
import earncoins from "../views/earncoins"

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: home
	},
	{
		path: '/cardpool',
		name: 'cardpool',
		component: cardpool
	},
	{
		path: '/mycollection',
		name: 'mycollection',
		component: mycollection
	},
	{
		path: '/mintNfts',
		name: 'mintNfts',
		component: mintNfts
	},
	{
		path: '/policy',
		name: 'policy',
		component: policy
	},
	{
		path: '/earncoins',
		name: 'earncoins',
		component: earncoins
	},
	// {
	//   path: '/about',
	//   name: 'about',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	// }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: () => ({ y: 0 }), //滚动到顶端
	routes,
})
// router.beforeEach((to, from, next) => {
// 	document.body.scrollTop = 0 // firefoxdocument.documentElement.scrollTop = 0next()
// })

export default router