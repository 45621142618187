export default {
    lang: "EN",
	LinkWallets:"Sign in",
	
	more:"more",
	CARD_DRAW_RULES:"CARD DRAW RULES",
	Beast:"Beast",
	Blood:"Blood",
	Chaos:"Chaos",
	Corruption:"corruption",
	Craftsmanship:"Craftsmanship",
	Curse:"Curse",
	Dark:"Dark",
	Death:"Death",
	Earth:"Earth",
	Earth2:"Earth",
	Evil:"Evil",
	Fire:"Fire",
	Grass:"Grass",
	Healing:"Healing",
	Holy:"Holy",
	Ice:"Ice",
	Illusion:"Illusion",
	Justice:"Justice",
	Light:"Light",
	Metal:"Metal",
	Nature:"Nature",
	Night:"Night",
	Rock:"Rock",
	Spirit:"Spirit",
	Water:"Water",
	Wind:"Wind",
	CARDPOOL: "CARD POOL",
	times: "TIMES",
	MyCollection: "My Collection",
	Signout: "Sign out",
	Disagree: "Disagree",
	Agree: "Agree",
	
	construction:"The card pool is under construction, so stay tuned",
	confirm: "CONFIRM",
	
	ListingStatus: "LISTING STATUS",
	RARITY: "RARITY",
	RACE: "RACE",
	ENDER: "GENDER",
	COINS: "COINS",
	Class: "Class",
	story: "Story background",
	Skill: "Skill",
	effect: "Effect",
	
	ALL: "ALL",
	OnShelves: "On shelves",
	OffShelves: "Out of print",
	Common: "Common",
	rare: "Rare",
	epic: "Epic",
	Legendary: "Legendary",
	Demons: "Demons",
	Angels: "Angels",
	Undead: "Undead",
	Vampires: "Vampires",
	Beasts: "Beasts",
	Dwarves: "Dwarves",
	Humans: "Humans",
	Elves: "Elves",
	male: "Male",
	female: "Female",
	mint: "Mint",
	nomint: "No mint",
	
	PrivacyPolicy: "We use cookies to enhance your experience on our website. By continuing to browse, you agree to our use of cookies. Learn more, including how to control cookies, ",
	PrivacyPolicy_link: "in our Privacy Policy.",
	
	// earncoins
	EARNCOINS: "EARN COINS",
	Freelegacy: "Free Treasure",
	dayslegacy56: "56-Day Treasure",
	Newbiecheckin: "10 Consecutive",
	LuckyDID: "Lucky DID",
	Invitationrebates: "Referral Rebate",
	
	// FreeLegacy
	FreeLegacy_title: "CUMULATIVE CHECK-IN FOR COINS",
	FreeLegacy_subtitle: 'First-time cumulative purchase of 10 coins or the purchase of "56-Day Treasure" is considered a subscription to the Free Treasure.',
	Coins: "Coins",
	DayLegacy60: "56-Day Treasure",
	FreeLegacy_round: "Current check-in",
	FreeLegacy_countdown: "Refresh countdown",
	time_D: "D",
	time_H: "H",
	time_M: "M",
	FAQ: "FAQ:",
	FAQ_tit1: "Why does \"Free Treasure\" still require a subscription to sign in and receive coins? Isn't it free?",
	FAQ_sub1: "This is to set a participation threshold, which can effectively isolate witch attacks. If it is completely open, once attacked, it will lead to the proliferation and devaluation of cards, harming the interests of players.",
	FAQ_tit2: "Is the subscription to \"Free Treasure\" time-limited, and how many coins can be claimed in total?",
	FAQ_sub2: "\"Free Treasure\" is a permanent subscription that unlocks automatically upon meeting the conditions. You can claim 6 coins per round of check-ins, and it refreshes every 2 weeks.",
	FAQ_tit3: "\"Free Treasure\" won't it to some extent lead to the inflation of cards, affecting the interests of paying players, right?",
	FAQ_sub3: "\"Free Treasure\" is primarily designed for zero-cost and micro-cost players. It allows most players to obtain cards for free, causing some degree of card inflation, mainly with lower-level cards. Higher-level cards, such as legendary ones, require a significant amount of coins and some luck. As Cardaether develops, we plan to introduce card consumption mechanisms that create a win-win situation for players of different levels. After all, any game requires a large player base.",
	
	// DaysLegacy56
	DaysLegacy56_title: "\"56-DAY TREASURE\" - EARN COINS BY CHECKING IN",
	DaysLegacy56_subtitle: "You can only purchase one at a time, and you can only buy another one after the 56-day subscription expires.",
	DaysLegacy56_countdown: "Countdown Ends",
	DaysLegacy56_rounds: "Total of 4 Rounds",
	PURCHASE: "SUBSCRIPTION",
	
	// NewbieCheckin
	NewbieCheckin_title: "NEW CARD POOL UPDATE, FREE 10 DRAWS.",
	NewbieCheckin_subtitle: "Every time the card pool is updated, there will be a free 10 draws event. Participation condition: Subscribed to \"Free Treasure.\"",
	CHECKEDIN: "CHECK-IN",
	
	// LUCKYDID
	LUCKYDID_title: "LUCKY DID",
	LUCKYDID_subtitle1: "During each card pool update, for every DID forged matching the Lucky DID, you can receive a varying amount of card-drawing coins. Take a screenshot, share it on Twitter, tag ",
	LUCKYDID_subtitle2: "@Cardaether, and then contact the administrator on Discord to claim your coins.",
	LUCKYDID_Draws: "Coins",
	LUCKYDID_normal: "Common",
	EventEnded: "Event Ended",
	
	// INVITATION
	INVITATION_title: "INVITE NEW PLAYERS AND GET ETH REBATE.",
	INVITATION_subtitle: "Inviting new players to subscribe to \"Free Treasure\" can get up to 20% ETH rebate. Players who have subscribed to \"Free Treasure\" can obtain their own invitation code. ",
	INVITATION_subtitle2: "Players need to contact the administrator on Discord to verify and claim the reward.",
	INVITATION_sign: "When not subscribed to \"Free Treasure,\" you can enter the referral code of the recommending player.",
	INVITATION_BIND: "BIND",
	INVITATION_tip: "After binding, it cannot be unbound. Please enter carefully. After subscribing to \"Free Treasure,\" you can obtain your own invitation code.",
	INVITATION_sign2: "My invitation code",
	COPY: "COPY",
	CopySuccessful: "Copy Successful",
	INVITATION_sign3: "Invited players",
	Playeraddress: "Player address",
	Whether: "Subscription status of \"Free Treasure\"",
	Subscribed: "Subscribed",
	Notsubscribed: "Not subscribed",
	Gaintime: "Subscription time",
	Rebates: "Rebate status",
	Rebated: "Rebated",
	NotRebated: "Not Rebated"
}