import { Loading } from 'element-ui';

let loading;
 
//定义加载动画
function startLoading(title) {
    // Loading.service(options); options 参数为 Loading 的配置项
    // 使用loading变量来接收Loading.service返回的实例
   
    loading = Loading.service({
        lock:true,    //是否锁定
        text: title,   //显示在加载图标下方的加载文案
        background:'rgba(0,0,0,.7)',   //遮罩背景色
    });
}
 
//结束加载动画，调用该实例的 close 方法来关闭
function endLoading(){
    loading.close();
}

export {
	startLoading, endLoading
}