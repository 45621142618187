<template>
	<div class="container">
		<headbar></headbar>
		
		<div class="center">
			<div class="left">
				<img class="img" :src="$utils.getImage(pageData.image)" />
				<div class="top">
					<img class="shangjia" src="../assets/shangjia.svg" />
					<img class="putong" :src="$utils.getImage('/styles/'+rarity[pageData.rarity]+'.svg')" />
				</div>
				<div class="bot">
					<img class="jingling" :src="$utils.getImage('/styles/'+pageData.race_en+'.svg')" />
				</div>
			</div>
			
			<div class="right">
				<div class="title">MINT NFTS</div>
				<div class="d1">
					<span class="s1">Blockchain</span>
					<span class="val">ETH</span>
				</div>
				<div class="d2">
					<span class="s1">DID</span>
					<span>{{pageData.did_prefix}}</span>
					<span class="val">{{formatDidNumber(pageData.did_number)}}</span>
				</div>
				<div class="word">
					The same card casting each random casting before 1~50 numbers, 1~50 all casting, will open 51~100 numbers, and so on
				</div>
				<div class="cost">
					Estimated cost
				</div>
				<div class="eth">
					{{cost}} ETH
				</div>
				<div class="btn">
					<span class="s1" style="cursor:pointer;" @click="return_click">RETURN</span>
					<span class="s2 disabled" v-if="pageData.did_number">MINT</span>
					<span class="s2" style="cursor:pointer;" v-else @click="mint_click">MINT</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import headbar from "../components/headbar.vue"
	import {startLoading, endLoading} from "../common/loading.js"
	import { getBalance, mintNft, getTransactionReceipt, getMintFee } from "../common/web3/index.js"
	
	import args from "@/common/args.js"
	
	// const { deployContract } = require("../common/web3/helper.js")
	
	// const {
	//   deployContract,
	//   isContractTransferSuccess
	// } = require('../scripts/utils/helper')
	
	
	function sleep(time){
		return new Promise((resolve) => setTimeout(resolve, time));
	}
	
	
	export default {
		components: {
			headbar,
		},
		data() {
			return {
				rarity: {"普通":"Common","稀有":"rare","史诗":"epic","传奇":"Legendary"},
				pageData: {
					blockchain: "FTH",
					did_prefix: "CA-A-GW",
					did_number: "",
					did: "CA-A-GW-0000001",
					remark: "The same card casting each random casting before 1~50 numbers, 1~50 all casting, will open 51~100 numbers, and so on"
				},
				cost: 0,
				
				pageRange: ""
			}
		},
		async created() {
			// startLoading("数据加载中...");
			
			this.getSetting();
			
			let id = this.$route.query.id;
			let result = await this.$http.get("api-open/draw_card/byid?id="+id);
			this.pageData = result.body.data;
			if(!this.pageData || !this.pageData.id) {
				this.$router.push('mycollection').catch(err => err);
				this.$message({ type: "warning", message: this.$i18n.locale=='en-US'?"No data found":"没有查询到数据" });
				return;
			}
			
			console.log(this.pageData);
			
			let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
			let range = await this.$http.get("api-open/did/getRange?address="+account+"&prefix="+this.pageData.did_prefix);
			if(range.body.error) {
				return;
			}
			this.pageRange = range.body.data.min + "~" + range.body.data.max;
			console.log(range.body.data);
			
			// endLoading();
		},
		methods: {
			async getSetting() {
				// let ret = await this.$http.get("api-open/draw_price");
				// if(ret.body.error) {
				// 	return;
				// }
				// let data = ret.body.data;
				// // console.log(data);
				// window.setting = { };
				// window.setting.price = data.price;
				// window.setting.cost = data.cost;
				
				this.cost = await getMintFee();
			},
			return_click() {
				this.$router.push("mycollection");
			},
			async mint_click() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				if(!account) return;
				
				let balance = await getBalance(account);
				if(!balance || balance=="0" || balance<this.cost) {
					this.$message({ type: "warning", message: this.$i18n.locale=='en-US'?"Insufficient ETH balance":"ETH余额不足" });
					return;
				}
				
				if(this.pageData.did_number) {
					this.$message({
						type: 'warning',
						message: (this.$i18n.locale=='en-US'?result.body.msg_en:result.body.msg) || (this.$i18n.locale=='en-US'?"Already cast":'已经铸造')
					});
					return;
				}
				
				try{
					startLoading(this.$i18n.locale=='en-US'?"Call up Metamask":"调起Metamask...");
					
					let ret = await this.$http.get("api-open/did/getNumber?address="+account+"&id="+this.pageData.id+"&prefix="+this.pageData.did_prefix);
					if(ret.body.error) {
						this.$message({
							type: 'warning',
							message: (this.$i18n.locale=='en-US'?ret.body.msg_en:ret.body.msg) || "DID error"
						});
						endLoading();
						return;
					}
					let num = ret.body.data;
					// console.log("getNumber", num);
					if(num <= 0) {
						this.$message({
							type: 'warning',
							message: 'DID error'
						});
						endLoading();
						return;
					}
					
					// 转char，匹配tokenid类型
					let prefix = this.pageData.did_prefix + "-";
					let asciiArray = [];
					
					for (var i = 0; i < prefix.length; i++) {
						asciiArray.push(prefix.charCodeAt(i));
					}
					asciiArray.push(this.formatDidNumber(num));
					
					// let full_did = this.pageData.did_prefix + "-" + this.formatDidNumber(num);
					let full_did = asciiArray.join('');
					// end -----------
					
					let uri = this.$utils.getImage(this.pageData.image);
					
					await this.addMaticNetwork();
					
					let retmint = await mintNft(account, full_did, uri);
					// console.log("mintNft",retmint);
					endLoading();
					if(!retmint) {
						// let range = await this.$http.get("api-open/did/getRange?address="+account+"&prefix="+this.pageData.did_prefix);
						// this.pageRange = range.body.data.min + "~" + range.body.data.max;
						
						endLoading();
						this.$message({
							type: 'warning',
							message: 'Mint error'
						});
						return;
					}
					startLoading(this.$i18n.locale=='en-US'?"Minting in progress":"正在铸造中...");
					
					
					// 废弃 ===================================================
					// // 存入任务里
					// await this.$http.post("api-open/mint_task/install", {
					// 	address: account,
					// 	chain_info: retmint,
					// 	did: this.pageData.did_prefix+"-"+this.formatDidNumber(num),
					// 	drawCardId: this.pageData.id
					// });
					
					
					
					// let recp = await getTransactionReceipt(retmint);
					// if(!recp) {
					// 	let range = await this.$http.get("api-open/did/getRange?address="+account+"&prefix="+this.pageData.did_prefix);
					// 	this.pageRange = range.body.data.min + "~" + range.body.data.max;
						
					// 	endLoading();
					// 	this.$message({
					// 		type: 'warning',
					// 		message: 'Mint error'
					// 	});
					// 	return;
					// }
					
					// await this.$http.post("api-open/did/saveNumber", {
					// 	id: this.pageData.id,
					// 	address: account
					// });
					// 废弃 ===================================================
					
					
					await this.$http.post("api-open/coin_log/save", {
						address: account,
						name: this.pageData.name_zh,
						rarity: this.pageData.rarity,
						race: this.pageData.race_zh,
						did: this.pageData.did_prefix+"-"+this.formatDidNumber(num),
						chain_info: retmint,
						image: this.pageData.image,
						drawCardId: this.pageData.id
					});
					
					let mint_status = 0;
					while(mint_status == 0) {
						await sleep(2000);
						let ret_status = await this.$http.get("api-open/coin_log/status?drawCardId="+this.pageData.id);
						mint_status = ret_status.body.data || 0;
					}
					
					// this.pageData.did_number = num;
					
					endLoading();
					
					this.$router.push("mycollection");
				}catch(e){
					console.log(e);
					endLoading();
				}
			},
			async addMaticNetwork() {
				try {
					await window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: args.wallet_chain.chainId }],
					});
					
				} catch (e) {
					console.log("wallet_chain",e);
					
					if(e.code == 4902) {
						await window.ethereum.request({
						  method: "wallet_addEthereumChain",
						  params: [{
							chainId: args.wallet_chain.chainId,
							rpcUrls: args.wallet_chain.rpcUrls,
							chainName: args.wallet_chain.chainName,
							nativeCurrency: args.wallet_chain.nativeCurrency,
							blockExplorerUrls: args.wallet_chain.blockExplorerUrls
						  }]
						});
					}
				}
			},
			
			formatDidNumber(num) {
				// || "　"
				if(num) {
					let num_all = ("0000000" + num).substr(-7);
					return num_all;
				} else {
					return this.pageRange;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		min-width: 1300px;
		overflow: hidden;
		background-image: url('../assets/cjbj.png');
		background-repeat: no-repeat;
		background-size: 100%;
	}
	
	.center {
		width: 100%;
		height: 100vh;
		min-height: 700px;
		display: flex;
		justify-content: center;
		align-items: center;
		// margin-top: 100px;
	}
	.left {
		position: relative;
		display: inline-block;
	
		img {
			width: 333px;
			height: 523px;
			border-radius: 12px;
		}
	
		.top {
			position: absolute;
			top: 0;
			display: flex;
			justify-content: space-between;
			width: 100%;
	
			.shangjia {
				width: 35px;
				height: 35px;
				border-radius: 12px 0px 12px 0px;
			}
	
			.putong {
				width: 35px;
				height: 35px;
				border-radius: 0px 12px 0px 12px;
			}
		}
	
		.bot {
			position: absolute;
			bottom: 0;
			text-align: center;
			width: 100%;
	
			.jingling {
				width: 54px;
				height: 63px;
			}
		}
	}
	
	.right {
		height: 523px;
		margin-left: 55px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.title {
			font-size: 30px;
			font-family: Alibaba PuHuiTi;
			font-weight: 800;
			color: #FFFFFF;
			line-height: 33px;
		}
		.d1 {
			span {
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 33px;
			}
			.val {
				display: inline-block;
				width: 259px;
				height: 40px;
				background: #2A3550;
				border-radius: 6px;
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 40px;
				border: unset;
				box-sizing: border-box;
				padding: 0 19px;
			}
			.s1 {
				width: 100px;
				display: inline-block;
			}
		}
		.d2 {
			display: flex;
			align-items: center;
			span {
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 33px;
			}
			.val {
				display: inline-block;
				width: 186px;
				height: 40px;
				background: #2A3550;
				border-radius: 6px;
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 40px;
				border: unset;
				margin-left: 8px;
				box-sizing: border-box;
				padding: 0 19px;
				margin-left: auto;
			}
			.s1 {
				width: 100px;
				display: inline-block;
			}
		}
		.word {
			width: 360px;
			min-height: 60px;
			background: #1B243D;
			border-radius: 6px;
			font-size: 12px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #586480;
			padding: 10px;
			box-sizing: border-box;
		}
		.cost {
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
		}
		.eth {
			font-size: 30px;
			font-family: Alibaba PuHuiTi;
			font-weight: 800;
			color: #FFFFFF;
			line-height: 33px;
		}
		.btn {
			margin-top: 150px;
			.s1 {
				width: 129px;
				height: 40px;
				background: #2A3550;
				border-radius: 6px;
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 40px;
				text-align: center;
				display: inline-block;
			}
			.s2 {
				width: 129px;
				height: 40px;
				background: #FFFFFF;
				border-radius: 6px;
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #111A32;
				line-height: 40px;
				text-align: center;
				display: inline-block;
				margin-left: 15px;
			}
			.disabled {
				background-color: #999;
			}
		}
	}
</style>