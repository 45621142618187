<template>
	<div class="com-content">
		<div class="group1">
			<div class="row1">{{ $t("LUCKYDID_title") }}</div>
			<div class="row2 row21">{{ $t("LUCKYDID_subtitle1") }}</div>
			<div class="row2 row22">{{ $t("LUCKYDID_subtitle2") }}</div>
			
			<div class="row3 countdown">
				<span class="refresh">{{ $t("DaysLegacy56_countdown") }}</span>
				<div class="time" v-if="dd>0 || hh>0 || mm>0">
					<span class="tm">{{ dd }}</span>
					<span class="un">{{ $t("time_D") }}</span>
					<span class="tm">{{ hh }}</span>
					<span class="un">{{ $t("time_H") }}</span>
					<span class="tm">{{ mm }}</span>
					<span class="un">{{ $t("time_M") }}</span>
				</div>
				<div class="time" v-else>
					<span class="tm" style="width: 140px;">{{ $t("EventEnded") }}</span>
				</div>
			</div>
			
			<!-- <img class="dbx" src="../../assets/earncoins/dbx.png"/> -->  
		</div>
		
		<div class="group2">
			<div class="draw draw100">
				<div class="tit">
					<span class="sp"></span>
					<span class="sign">100 {{ $t("LUCKYDID_Draws") }} ({{ $t("Legendary") }})</span>
				</div>
				<div class="list">
					<div class="item" v-for="(item,index) in pageList.legendary" :key="index">
						<div class="pic" v-if="item.image">
							<img :src="$utils.getImage(item.image)" />
							<div class="top">
								<span class="shangjia"></span>
								<img class="new" src="../../assets/NEW.svg" v-if="item.is_new"/>
								<img class="putong" :src="$utils.getImage('/styles/'+rarity[item.rarity]+'.svg')" />
							</div>
							<div class="bot">
								<img class="jingling" :src="$utils.getImage('/styles/'+item.race_en+'.svg')" />
							</div>
						</div>
						<!-- <div class="name">{{$i18n.locale=='en-US'?item.name_en:item.name_zh}}</div> -->
						<div class="name">{{formatDidNumber2(item)}}</div>
					</div>
				</div>
			</div>
			
			<div class="draw draw30">
				<div class="tit">
					<span class="sp"></span>
					<span class="sign">30 {{ $t("LUCKYDID_Draws") }} ({{ $t("epic") }})</span>
				</div>
				<div class="list">
					<div class="item" v-for="(item,index) in pageList.epic" :key="index">
						<div class="pic" v-if="item.image">
							<img :src="$utils.getImage(item.image)" />
							<div class="top">
								<span class="shangjia"></span>
								<img class="new" src="../../assets/NEW.svg" v-if="item.is_new"/>
								<img class="putong" :src="$utils.getImage('/styles/'+rarity[item.rarity]+'.svg')" />
							</div>
							<div class="bot">
								<img class="jingling" :src="$utils.getImage('/styles/'+item.race_en+'.svg')" />
							</div>
						</div>
						<!-- <div class="name">{{$i18n.locale=='en-US'?item.name_en:item.name_zh}}</div> -->
						<div class="name">{{formatDidNumber2(item)}}</div>
					</div>
				</div>
			</div>
			
			<div class="draw draw10">
				<div class="tit">
					<span class="sp"></span>
					<span class="sign">10 {{ $t("LUCKYDID_Draws") }} ({{ $t("rare") }})</span>
				</div>
				<div class="list">
					<div class="item" v-for="(item,index) in pageList.rare" :key="index">
						<div class="pic" v-if="item.image">
							<img :src="$utils.getImage(item.image)" />
							<div class="top">
								<span class="shangjia"></span>
								<img class="new" src="../../assets/NEW.svg" v-if="item.is_new"/>
								<img class="putong" :src="$utils.getImage('/styles/'+rarity[item.rarity]+'.svg')" />
							</div>
							<div class="bot">
								<img class="jingling" :src="$utils.getImage('/styles/'+item.race_en+'.svg')" />
							</div>
						</div>
						<!-- <div class="name">{{$i18n.locale=='en-US'?item.name_en:item.name_zh}}</div> -->
						<div class="name">{{formatDidNumber2(item)}}</div>
					</div>
				</div>
			</div>
			
			<div class="draw draw5">
				<div class="tit">
					<span class="sp"></span>
					<span class="sign">5 {{ $t("LUCKYDID_Draws") }} ({{ $t("LUCKYDID_normal") }})</span>
				</div>
				<div class="list">
					<div class="item" v-for="(item,index) in pageList.normal" :key="index">
						<div class="pic" v-if="item.image">
							<img :src="$utils.getImage(item.image)" />
							<div class="top">
								<span class="shangjia"></span>
								<img class="new" src="../../assets/NEW.svg" v-if="item.is_new"/>
								<img class="putong" :src="$utils.getImage('/styles/'+rarity[item.rarity]+'.svg')" />
							</div>
							<div class="bot">
								<img class="jingling" :src="$utils.getImage('/styles/'+item.race_en+'.svg')" />
							</div>
						</div>
						<!-- <div class="name">{{$i18n.locale=='en-US'?item.name_en:item.name_zh}}</div> -->
						<div class="name">{{formatDidNumber2(item)}}</div>
					</div>
				</div>
			</div>
			
			
		</div>
		
		
		<!-- 标记需要显示返回顶部按钮 -->
		<div id="scrolltop" style="display: none;"></div>
		
	</div>
</template>

<script>
	import args from "@/common/args.js"
	import {startLoading, endLoading} from "../../common/loading.js"
	
	export default {
		data() {
			return {
				dd: 0,
				hh: 0,
				mm: 0,
				
				rarity: {"普通":"Common","稀有":"rare","史诗":"epic","传奇":"Legendary"},
				
				pageList: { 
					legendary: [], // 传奇
					epic: [], // 史诗
					rare: [], // 稀有
					normal: [] // 普通
				}
			}
		},
		mounted() {
			
			startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
			
			this.getData();
			
			endLoading();
			
		},
		methods: {
			async getData() {
				let rest = await this.$http.get("api-open/lucky_did/list");
				
				rest.body.data.forEach(im => {
					// console.log(im.rarity);
					if(im.rarity == "传奇") {
						this.pageList.legendary.push(im);
					} else if(im.rarity == "史诗") {
						this.pageList.epic.push(im);
					} else if(im.rarity == "稀有") {
						this.pageList.rare.push(im);
					} else if(im.rarity == "普通") {
						this.pageList.normal.push(im);
					}
				});
				this.comp(this.pageList.legendary);
				this.comp(this.pageList.epic);
				this.comp(this.pageList.rare);
				this.comp(this.pageList.normal);
				
				
				let month2 = 56*args.day_home*3600;
				let rest2 = await this.$http.get("api-open/lucky_did/countdown");
				// console.log(111, rest2); 
				if(rest2.body.data) {
					let st = rest2.body.data.start_date;
					// console.log(1234, st);    
					
					let now = parseInt(new Date().getTime()/1000);
					let count = st+month2-now;
					this.setCountdown(count);
				}
				
			},
			comp(list) {
				let len = list.length;
				let nn = 4 - (len % 4);
				if(nn < 3) {
					for(let i=0; i<nn; i++) {
						list.push({});
					}
				}
			},
			formatDidNumber2(item) {
				if(!item.lucky_did) {
					return "";
				}
				let num_all = ("0000000" + item.lucky_did).substr(-7);
				return item.did_prefix + "-" + num_all;
			},
			setCountdown(count) {
				let d = count / 24 / 3600;
				let dy = count % (24 * 3600);
				
				let h = dy / 3600;
				let hy = dy % 3600;
				
				let m = hy / 60;
				let my = hy % 60;
				
				d = parseInt(d);
				h = parseInt(h);
				m = parseInt(m);
				
				if(d < 0) d = 0;
				if(h < 0) h = 0;
				if(m < 0) m = 0;
				
				if(d < 10) d = "0" + d;
				if(h < 10) h = "0" + h;
				if(m < 10) m = "0" + m;
				
				this.dd = d;
				this.hh = h;
				this.mm = m;
				
				count--;
				setTimeout(_ => {
					this.setCountdown(count);
				}, 1000)
				
			}
		},
	}
</script>

<style lang="scss" scoped>
	.countdown {
		display: flex;
		align-items: center;
		.refresh {
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			margin-right: 18px;
		}
		.time {
			display: flex;
			align-items: flex-end;
			.tm {
				width: 36px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				background: #111A32;
				border-radius: 6px;
				// padding: 8px;
				box-sizing: border-box;
				margin-left: 6px;
				
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFB72D;
			}
			.un {
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #52618C;
				margin-left: 6px;
			}
		}
	}
	
	.group1 {
		height: 220px;
		background: #1C2849;
		border-radius: 30px 30px 0px 0px;
		margin-top: 108px;
		box-sizing: border-box;
		padding: 40px;
		padding-top: 35px;
		position: relative;
		background-image: url("../../assets/earncoins/dbx.png");
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position-x: right;
		
		.row1 {
			font-size: 40px;
			font-family: Impact;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
		}
		.row2 {
			width: 604px;
			min-height: 30px;
			background: linear-gradient(90deg, rgba(255,183,45,0.8), rgba(255,183,45,0));
			margin: 22px 0;
			box-sizing: border-box;
			padding: 0 10px;
			
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
			white-space: nowrap;
		}
		.row21 {
			margin-bottom: 0px;
		}
		.row22 {
			margin-top: 0px;
			margin-bottom: 10px;
		}
		.row3 {
			display: flex;
			
			.num {
				display: flex;
				align-items: center;
				
				.txt {
					font-size: 20px;
					font-family: Alibaba PuHuiTi;
					font-weight: bold;
					color: #FFFFFF;
				}
				.prog {
					width: 185px;
					height: 7px;
					background: #57617D;
					border-radius: 4px;
					margin: 0 13px;
					.c {
						width: 93px;
						height: 7px;
						background: #FFB72D;
						border-radius: 4px;
					}
				}
				.unit {
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
				}
			}
			.num2 {
				margin-left: 62px;
			}
		}
		
		.dbx {
			position: absolute;
			right: 0%;
			top: 0%;
		}
	}
	
	.group2 {
		width: 1300px;
		// height: 2595px;
		background: #1C2849;
		border-radius: 0px 0px 30px 30px;
		margin-top: 14px;
		padding: 36px 40px;
		box-sizing: border-box;
		.draw {
			.tit {
				.sp {
					display: inline-block;
					width: 7px;
					height: 19px;
					background: #FFFFFF;
					border-radius: 4px;
				}
				.sign {
					font-size: 20px;
					font-family: Alibaba PuHuiTi;
					font-weight: bold;
					color: #FFFFFF;
					margin-left: 15px;
				}
			}
		}
		.list {
			// width: 1300px;
			// height: 1100px;
			// overflow-y: scroll;
			margin: auto;
			margin-top: 29px;
			margin-bottom: 52px;
			display: flex;
			flex-wrap: wrap;
			// padding: 56px 270px;
			// gap: 12px;
			justify-content: space-between;
			box-sizing: border-box;
			
			.item {
				width: 250px;
				height: 392px;
				margin-bottom: 110px;
				font-size: 18px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 33px;
			
				.pic {
					position: relative;
			
					img {
						width: 250px;
						height: 392px;
						// background: #F3BCBC;
						border-radius: 12px;
					}
			
					.top {
						position: absolute;
						top: 0;
						display: flex;
						justify-content: space-between;
						width: 100%;
			
						.shangjia {
							width: 35px;
							height: 35px;
							border-radius: 12px 0px 12px 0px;
						}
						
						.new {
							width: 63px;
							height: 20px;
							margin-top: 7px;
						}
						
						.putong {
							width: 35px;
							height: 35px;
							border-radius: 0px 12px 0px 12px;
						}
					}
			
					.bot {
						position: absolute;
						bottom: 0;
						text-align: center;
						width: 100%;
			
						.jingling {
							width: 54px;
							height: 63px;
						}
					}
				}
			
				.name {
					text-align: center;
				}
			
				.quan {
					text-align: left;
				}
			}
		}
	}
	
</style>