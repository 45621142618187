import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引入插件和语言包
import VueI18n from 'vue-i18n'
import zh from '@/i18n/langs/zh'
import en from '@/i18n/langs/en'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import utils from "@/common/utils.js";
import VueResource from 'vue-resource';
Vue.use(VueResource);

import args from "./common/args.js"
Vue.http.options.root=args.server_host;
Vue.http.options.emulateJSON=true;

Vue.use(VueI18n);
Vue.use(ElementUI);

Vue.prototype.$utils = utils;

// // 获取屏幕分辨率的宽 / 10
//  document.documentElement.style.fontSize = window.screen.width / 10 + 'px'


// 自动设置多语言 默认选择浏览器系统语言
// const navLang       = navigator.language;    //判断当前浏览器使用的语言
// const localLang     = (navLang === 'zh-CN' || navLang === 'en-US') ? navLang : false;
let lang            = localStorage.getItem('language') || 'en-US';    //如果有缓存，取缓存的语言
const i18n          = new VueI18n({
    locale: lang,
    messages:{
        'en': en, // 英文语言包
        'zh': zh // 中文语言包
    },
    silentTranslationWarn: true
});
localStorage.setItem('language',lang);    //最后缓存当前使用的语言

Vue.config.productionTip = false

const device = navigator.userAgent.toLowerCase();
if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(device)) {
	//移动端
	document.isMobile = true;
} else {
	//pc端
}

new Vue({
	router,
	i18n, // 注册并挂载
	render: h => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this//安装全局事件总线
	}
}).$mount('#app')
