<template>
	<div class="com-content">
		<div class="group1">
			<div class="row1">{{ $t("INVITATION_title") }}</div>
			<div class="row2 row21">{{ $t("INVITATION_subtitle") }}</div>
			<div class="row2 row22">{{ $t("INVITATION_subtitle2") }}</div>
			
			<!-- <img class="dbx" src="../../assets/earncoins/dbx.png"/> -->
		</div>
		
		<div class="group2" v-if="!is_legacy">
			<div class="tit">
				<span class="sp"></span>
				<span class="sign">{{ $t("INVITATION_sign") }}</span>
			</div>
			<div class="search">
				<input class="input" v-if="exist_parent" type="text" disabled v-model="parent_invitation_code" placeholder="Please enter the invitation code" />
				<input class="input" v-else type="text" v-model="parent_invitation_code" placeholder="Please enter the invitation code" />
				<div class="bind click" v-if="!exist_parent" @click="bind_click" @mouseenter="btnTipVis=true">{{ $t("INVITATION_BIND") }}</div>
				<!-- <div class="bind" v-else>{{ $t("INVITATION_BIND") }}</div> -->
			</div>
			
			<div class="tip" v-if="btnTipVis && !exist_parent">
				<span class="txt">{{ $t("INVITATION_tip") }}</span>
				<span class="cls" @click="btnTipVis=false">x</span>
			</div>
		</div>
		
		<div class="group2" v-else>
			<div class="tit">
				<span class="sp"></span>
				<span class="sign">{{ $t("INVITATION_sign2") }}</span>
			</div>
			<div class="search">
				<!-- <input class="input" type="text" disabled placeholder="Please enter the invitation code" /> -->
				<div class="input">{{ invitation_code }}</div>
				<div class="bind click" @click="copy_click">{{ $t("COPY") }}</div>
			</div>
			 
			<div class="tit" style="margin-top: 35px;">
				<span class="sp"></span>
				<span class="sign">{{ $t("INVITATION_sign3") }}</span>
			</div>
			<div class="table">
				<div class="row head">
					<div class="col col1">{{ $t("Playeraddress") }}</div>
					<div class="col col2">{{ $t("Whether") }}</div>
					<div class="col col3">{{ $t("Gaintime") }}</div>
					<div class="col col4">{{ $t("Rebates") }}</div>
				</div>
				<div class="row body" v-for="(item,index) in invitationList">
					<div class="col col1">{{ item.address }}</div>
					<div class="col col2">{{ $t(item.legacy_benefits) }}</div>
					<div class="col col3">{{ item.gain_time }}</div>
					<div class="col col4">{{ $t(item.claimed) }}</div>
				</div>
				
			</div>
			
		</div>
		
	</div>
</template>

<script>
	import {
		startLoading, endLoading
	} from "../../common/loading.js"
	
	export default {
		data() {
			return {
				invitation_code: "",
				parent_invitation_code: "",
				
				exist_parent: false,
				is_legacy: 0,
				
				btnTipVis: false,
				
				invitationList: []
			}
		},
		created() {
			
			startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
			
			this.getData();
			
			endLoading();
			
		},
		methods: {
			async getData() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				let rest = await this.$http.get("api-open/invitation_rebates/code?address="+account);
				// console.log(121,rest); 
				if(rest.body.data) {
					this.invitation_code = rest.body.data.invitation_code || "";
					this.parent_invitation_code = rest.body.data.parent_invitation_code || "";
					this.is_legacy = rest.body.data.is_legacy || "";
					this.exist_parent = this.parent_invitation_code!="";
				}
				
				if(this.invitation_code) {
					this.getInvitationList();
				}
			},
			async bind_click() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				if (!account) {
					this.$bus.$emit("account_login");
					return;
				}
				
				startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
				
				let rest = await this.$http.post(`api-open/invitation_rebates/code?address=${account}&code=${this.parent_invitation_code}`);
				// console.log(223, rest);
				
				if(rest.body.error) {
					endLoading();
					this.$message({
						type: 'warning',
						message: this.$i18n.locale=='en-US'?rest.body.msg_en:rest.body.msg
					});
					return;
				}
				
				this.getData();
				
				endLoading();
			},
			async getInvitationList() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				let rest = await this.$http.get(`api-open/invitation_rebates/list?address=${account}&code=${this.invitation_code}`);
				this.invitationList = rest.body.data;
				
				this.invitationList.forEach(iv => {
					let currentDate = new Date(iv.gain_time);
					// 获取当前时区的偏移量（以分钟为单位）
					let timezoneOffset = currentDate.getTimezoneOffset();
					// 将偏移量转换为小时
					let timezoneOffsetInHours = Math.floor(timezoneOffset / 60);
					// 将时间调整为加上时区的时间
					currentDate.setMinutes(currentDate.getMinutes() - timezoneOffset);
					iv.gain_time = currentDate.format();
				})
				
				// console.log(123123,this.invitationList);
			},
			copy_click() {
				let oInput = document.createElement('input');
				oInput.value = this.invitation_code;
				document.body.appendChild(oInput);
				oInput.select(); // 选择对象;
				console.log(oInput.value)
				document.execCommand("Copy"); // 执行浏览器复制命令
				this.$message({
					message: this.$t("CopySuccessful"),
					type: 'success'
				});
				oInput.remove()
		
			}
		},
	}
</script>

<style lang="scss" scoped>
	.countdown {
		display: flex;
		align-items: center;
		.refresh {
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			margin-right: 18px;
		}
		.time {
			display: flex;
			align-items: flex-end;
			.tm {
				width: 36px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				background: #111A32;
				border-radius: 6px;
				// padding: 8px;
				box-sizing: border-box;
				margin-left: 6px;
				
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFB72D;
			}
			.un {
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #52618C;
				margin-left: 6px;
			}
		}
	}
	
	.group1 {
		height: 200px;
		background: #1C2849;
		border-radius: 30px 30px 0px 0px;
		margin-top: 108px;
		box-sizing: border-box;
		padding: 40px;
		position: relative;
		background-image: url("../../assets/earncoins/dbx.png");
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position-x: right;
		
		.row1 {
			font-size: 40px;
			font-family: Impact;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
		}
		.row2 {
			width: 604px;
			height: 30px;
			background: linear-gradient(90deg, rgba(255,183,45,0.8), rgba(255,183,45,0));
			margin: 22px 0;
			box-sizing: border-box;
			padding: 0 10px;
			
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
			white-space: nowrap;
		}
		.row21 {
			margin-bottom: 0px;
		}
		.row22 {
			margin-top: 0px;
			margin-bottom: 10px;
		}
		.row3 {
			display: flex;
			
			.num {
				display: flex;
				align-items: center;
				
				.txt {
					font-size: 20px;
					font-family: Alibaba PuHuiTi;
					font-weight: bold;
					color: #FFFFFF;
				}
				.prog {
					width: 185px;
					height: 7px;
					background: #57617D;
					border-radius: 4px;
					margin: 0 13px;
					.c {
						width: 93px;
						height: 7px;
						background: #FFB72D;
						border-radius: 4px;
					}
				}
				.unit {
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
				}
			}
			.num2 {
				margin-left: 62px;
			}
		}
		
		.dbx {
			position: absolute;
			right: 0%;
			top: 0%;
		}
	}
	 
	.group2 {
		width: 1300px;
		min-height: 250px;
		background: #1C2849;
		border-radius: 0px 0px 30px 30px;
		margin-top: 14px;
		padding: 36px 40px;
		box-sizing: border-box;
		.tit {
			.sp {
				display: inline-block;
				width: 7px;
				height: 19px;
				background: #FFFFFF;
				border-radius: 4px;
			}
			.sign {
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: bold;
				color: #FFFFFF;
				margin-left: 15px;
			}
		}
		.search {
			display: flex;
			align-items: center;
			margin-top: 33px;
			
			.input {
				width: 462px;
				height: 40px;
				background: #2A385E;
				border-radius: 6px;
				border: unset;
				padding: 14px 20px;
				box-sizing: border-box;
				
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #5F74AB;
				// line-height: 40px;
			}
			.bind {
				width: 130px;
				height: 40px;
				background: #4961A0;
				border-radius: 6px;
				margin-left: 17px;
				text-align: center;
				
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 40px;
			}
			.click {
				cursor: pointer;
			}
		}
		
		.tip {
			width: 606px;
			height: 64px;
			background: #111A32;
			border-radius: 6px;
			position: relative;
			box-sizing: border-box;
			padding: 9px 15px;
			margin-top: 25px;
			&:before {
				content: "";
				width: 13px;
				height: 13px;
				background: #111A32;
				position: absolute;
				top: -6.5px;
				right: 20px;
				transform: rotate(45deg);
			}
			
			.txt {
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #3C4B76;
			}
			.cls {
				width: 11px;
				height: 11px;
				line-height: 11px;
				color: #1C2744;
				background-color: #3A4872;
				display: inline-block;
				font-size: 12px;
				font-weight: 900;
				border-radius: 50%;
				position: absolute;
				top: 15px;
				right: 9px;
				text-align: center;
				box-sizing: border-box;
				padding: 0px;
				display: inline-block;
				cursor: pointer;
				padding-bottom: 2px;
				display: flex;
				justify-content: center;
				align-items: center;
				user-select: none;
			}
		}
		
		.table {
			margin-top: 30px;
			
			.head {
				font-size: 14px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #5F74AB;
			}
			
			.row {
				width: 1220px;
				height: 50px;
				background: #2A385E;
				display: flex;
				
				.col {
					padding-left: 21px;
					line-height: 50px;
					
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #5F74AB;
				}
				.col1 {
					width: 380px;
				}
				.col2 {
					width: 350px;
				}
				.col3 {
					width: 250px;
				}
				.col4 {
					// width: 325px;
				}
				
			}
			.body {
				&:nth-child(even) {
					background-color: #1C2849;
				}
				.col {
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
		}
		
	}
	
</style>