<template>
	<div id="app" class="app" @scroll="scrollEvent" :class="{isMobile:isMobile}">
		
		<router-view />
		
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {
			// home, cardpool
		},
		data() {
			return {
				isMobile: false,
				
				loading_vis: true,
				img1_vis: false,
				img2_vis: false,
				img3_vis: false
			}
		},
		created() {
			this.isMobile = document.isMobile;
		},
		methods: {
			img2_load() {
				this.img1_vis = true;
				setTimeout(_ => {
					this.img2_vis = true;
				}, 2000);
			},
			
			scrollEvent(e) {
				// console.log(e);
			}
			
		},
	}
</script>

<style lang="scss">
	/* #app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px;
	} */
	
	html,body,.app {
		width: 100%;
		height: 100%;
	}
	body {
		margin: 0;
		padding: 0;
		background-color: #111A32;
		overflow-x: auto;
	}
	/* .copyright {
		color: #fff;
		position: absolute;
		margin: 130px 0 0 30px;
	} */
	
	// body::-webkit-scrollbar {
	// 	width: 0 !important;
	// }
	
	
	::-webkit-scrollbar {
		width: 0px;
		height: 0px;
		appearance: none;  /* 清除默认样式 */
	}
	
	::-webkit-scrollbar-track {
		background-color: transparent;
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}
	
	::-webkit-scrollbar-thumb {
		background-color: rgba(147, 147, 153, 0.5);
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}
	
	
	.el-popup-parent--hidden {
		padding-right: unset !important;
	}
	.el-dialog {
		background: transparent !important;
		.el-dialog__header {
			display: none;
		}
		.el-dialog__body {
			padding: 0;
		}
	}
	
	.loading {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: #111A32;
		z-index: 999999;
		display: flex;
		justify-content: center;
		align-items: center;
		.imgl {
			position: absolute;
		}
	}
	
	
	.isMobile {
		overflow-x: hidden;
	}
</style>