<template>
	<div class="container">
		<headbar></headbar>
		<!-- <div class="bg"></div> -->

		<div class="mycn">
			<div class="details">
				<img class="dtbt" :src="require(`../assets/${$i18n.locale}/wdsc.svg`)" />

				<div class="bar">
					<div class="left">
						<el-dropdown class="kuang" @command="where_status">
							<span class="el-dropdown-link">
								{{$t(str_status)}}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="ListingStatus">{{$t('ALL')}}</el-dropdown-item>
								<el-dropdown-item command="OnShelves">{{$t('OnShelves')}}</el-dropdown-item>
								<el-dropdown-item command="OffShelves">{{$t('OffShelves')}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<el-dropdown class="kuang" @command="where_rarity">
							<span class="el-dropdown-link">
								{{$t(str_rarity)}}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="RARITY">{{$t('ALL')}}</el-dropdown-item>
								<el-dropdown-item command="Common">{{$t('Common')}}</el-dropdown-item>
								<el-dropdown-item command="rare">{{$t('rare')}}</el-dropdown-item>
								<el-dropdown-item command="epic">{{$t('epic')}}</el-dropdown-item>
								<el-dropdown-item command="Legendary">{{$t('Legendary')}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<el-dropdown class="kuang" @command="where_race_en">
							<span class="el-dropdown-link">
								{{$t(str_race_en)}}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="RACE">{{$t('ALL')}}</el-dropdown-item>
								<el-dropdown-item command="Demons">{{$t('Demons')}}</el-dropdown-item>
								<el-dropdown-item command="Angels">{{$t('Angels')}}</el-dropdown-item>
								<el-dropdown-item command="Undead">{{$t('Undead')}}</el-dropdown-item>
								<el-dropdown-item command="Vampires">{{$t('Vampires')}}</el-dropdown-item>
								<el-dropdown-item command="Beasts">{{$t('Beasts')}}</el-dropdown-item>
								<el-dropdown-item command="Dwarves">{{$t('Dwarves')}}</el-dropdown-item>
								<el-dropdown-item command="Humans">{{$t('Humans')}}</el-dropdown-item>
								<el-dropdown-item command="Elves">{{$t('Elves')}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<el-dropdown class="kuang" @command="where_ender">
							<span class="el-dropdown-link">
								{{$t(str_ender)}}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="ENDER">{{$t('ALL')}}</el-dropdown-item>
								<el-dropdown-item command="mint">{{$t('mint')}}</el-dropdown-item>
								<el-dropdown-item command="nomint">{{$t('nomint')}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						
						
						<!-- <div class="kuang status">
							<span>LISTING STATUS</span>
							<img class="qieh" src="../assets/qiehuan.svg" @click="qieh_click('status')" />
						</div>
						<div class="kuang status">
							<span>RARITY</span>
							<img class="qieh" src="../assets/qiehuan.svg" @click="qieh_click('rarity')" />
						</div>
						<div class="kuang status">
							<span>RACE</span>
							<img class="qieh" src="../assets/qiehuan.svg" @click="qieh_click('race_en')" />
						</div>
						<div class="kuang status">
							<span>COINS</span>
							<img class="qieh" src="../assets/qiehuan.svg" @click="qieh_click('')" />
						</div> -->
					</div>
					<div class="right">
						<input type="text" placeholder="Search for a name" v-model="search" />
						<img src="../assets/fuwei.svg" style="cursor:pointer;" @click="search_click" />
					</div>
				</div>

				<div class="list">
					<div class="item" v-for="(item,index) in imgList" :key="index" @click="item_click(item)">
						<div class="pic" v-if="item.image">
							<img :src="$utils.getImage(item.image)" />
							<div class="top">
								<img class="shangjia" src="../assets/shangjia.svg" />
								<img class="new" src="../assets/NEW.svg" v-if="item.is_new"/>
								<img class="putong" :src="$utils.getImage('/styles/'+rarity[item.rarity]+'.svg')" />
							</div>
							<div class="bot">
								<img class="jingling" :src="$utils.getImage('/styles/'+item.race_en+'.svg')" />
							</div>
						</div>
						<div class="name">{{$i18n.locale=='en-US'?item.name_en:item.name_zh}}</div>
						<div class="name">{{formatDidNumber2(item)}}</div>
					</div>
					<!-- <div class="tc"></div> -->
				</div>
			</div>

		</div>


		<!-- details dialog -->
		<el-dialog custom-class="details-dialog" :show-close="false" :visible.sync="detailsDialogVisible"
			width="1300px"> <!-- :before-close="handleClose"-->
			<div class="content">
				<div class="left">
					<img class="img" :src="$utils.getImage(currentItem.image)" />
					<div class="top">
						<img class="shangjia" src="../assets/shangjia.svg" />
						<img class="putong" :src="$utils.getImage('/styles/'+rarity[currentItem.rarity]+'.svg')" />
					</div>
					<div class="bot">
						<img class="jingling" :src="$utils.getImage('/styles/'+currentItem.race_en+'.svg')" />
					</div>
				</div>
				<div class="right">
					<div class="title">
						<img src="../assets/gdhd.svg" />
						<span>{{$i18n.locale=='en-US'?currentItem.name_en:currentItem.name_zh}}</span>
					</div>
					<div class="text">{{$t("RACE")}}: {{$i18n.locale=='en-US'?currentItem.race_en:currentItem.race_zh}}</div>
					<div class="text">{{$t("Class")}}: {{$i18n.locale=='en-US'?currentItem.career_en:currentItem.career_zh}}</div>
					<div class="word">
					<pre>{{$i18n.locale=='en-US'?currentItem.story_en:currentItem.story_zh}}</pre>
					</div>
					<!-- <div class="text">{{$t("story")}}</div> -->
					<div class="text">{{$t("Skill")}}: {{$i18n.locale=='en-US'?currentItem.skill_en:currentItem.skill_zh}}</div>
					<div class="text">{{$t("effect")}}: {{$i18n.locale=='en-US'?currentItem.effect_en:currentItem.effect_zh}}</div>
					
					<span class="pending" v-if="mint_status==0">Pending</span>
					<span class="did" v-if="mint_status==1 && currentItem.did_number">DID: {{currentItem.did}}</span>
					<span class="mint" v-if="mint_status==-1" @click="mint_click">Mint NFTs ▶</span>
				</div>
			</div>
		</el-dialog>

		
		<!-- 标记需要显示返回顶部按钮 -->
		<div id="scrolltop" style="display: none;"></div>
				
	</div>
</template>

<script>
	import headbar from "../components/headbar.vue"
	import {startLoading, endLoading} from "../common/loading.js"
	
	let desc = "";
	export default {
		components: {
			headbar,
		},
		data() {
			return {
				rarity: {"普通":"Common","稀有":"rare","史诗":"epic","传奇":"Legendary"},
				sort: "",
				search: "",
				imgList: [],
				
				detailsDialogVisible: false,
				currentItem: {},
				isMint: true,
				
				
				str_status: "ListingStatus",
				str_rarity: "RARITY",
				str_race_en: "RACE",
				str_ender: "COINS",
				
				status_where: "",
				rarity_where: "",
				race_en_where: "",
				ender_where: "",
				
				mint_status: 0
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			getData() {
				// startLoading("数据加载中...");
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				if(!account) {
					// endLoading();
					this.$message({
						type: "warning",
						message: "请先登录钱包"
					});
					this.$router.push("/");
					return;
				}
				
				this.$http.get("api-open/draw_card/list?address="+account).then(result => {
					if(result.body.error) {
						return;
					}
					this.imgList = result.body.data;
					this.comp();
					// console.log(123123,this.imgList);
					// endLoading();
				})
			},
			mint_click() {
				if(!this.currentItem) return;
				this.$router.push({name:"mintNfts", query:{ id:this.currentItem.id }});
			},
			// qieh_click(name) {
			// 	startLoading("数据加载中...");
			// 	let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				
			// 	this.sort = name;
			// 	this.$http.get("api-open/draw_card/list?sort="+this.sort+"&desc="+desc+"&search="+this.search+"&address="+account).then(result => {
			// 		if(result.body.error) {
			// 			return;
			// 		}
			// 		this.imgList = result.body.data;
			// 		this.comp();
					
			// 		endLoading();
			// 	})
			// 	if(desc) desc = "";
			// 	else desc = "desc";
			// },
			// search_click() {
			// 	startLoading("数据加载中...");
			// 	let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				
			// 	this.sort = name;
			// 	this.$http.get("api-open/draw_card/list?sort="+this.sort+"&desc="+desc+"&search="+this.search+"&address="+account).then(result => {
			// 		if(result.body.error) {
			// 			return;
			// 		}
			// 		this.imgList = result.body.data;
			// 		this.comp();
					
			// 		endLoading();
			// 	})
			// },
			where_status(str) {
				let obj = { OnShelves: 1, OffShelves: 0 };
				this.str_status = str;
				if(obj[str] == 0 || obj[str] == 1) {
					this.status_where = "a.status=" + obj[str];
				} else {
					this.status_where = "";
				}
				this.search_click();
			},
			where_rarity(str) {
				let obj = { Common: "普通", rare: "稀有", epic: "史诗", Legendary: "传奇", };
				this.str_rarity = str;
				if(obj[str]) {
					this.rarity_where = "a.rarity='" + obj[str] + "'";
				} else {
					this.rarity_where = "";
				}
				this.search_click();
			},
			where_race_en(str) {
				let obj = { Demons:"Demons",Angels:"Angels",Undead:"Undead",Vampires:"Vampires",Beasts:"Beasts",Dwarves:"Dwarves",Humans:"Humans",Elves:"Elves" };
				this.str_race_en = str;
				if(obj[str]) {
					this.race_en_where = "a.race_en='" + obj[str] + "'";
				} else {
					this.race_en_where = "";
				}
				this.search_click();
			},
			where_ender(str) {
				// let obj = { mint: 1, nomint: 0 };
				this.str_status = str;
				if(str == "mint") {
					this.ender_where = "IFNULL(did_number,0)>0";
				} else if(str == "nomint") {
					this.ender_where = "IFNULL(did_number,0)=0";
				} else {
					this.ender_where = "";
				}
				
				this.search_click();
			},
			search_click() {
				startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				
				let whe = "";
				if(this.status_where) whe += " and " + this.status_where;
				if(this.rarity_where) whe += " and " + this.rarity_where;
				if(this.race_en_where) whe += " and " + this.race_en_where;
				if(this.ender_where) whe += " and " + this.ender_where;
				// console.log(121212,whe);
				
				this.$http.get("api-open/draw_card/list?where="+whe+"&search=" + this.search+"&address="+account)
					.then(result => {
						// console.log(result.body);
						this.imgList = result.body.data;
						this.comp();
				
						endLoading();
					})
			},
			comp() {
				let len = this.imgList.length;
				let nn = 5 - (len % 5);
				if(nn < 4) {
					for(let i=0; i<nn; i++) {
						this.imgList.push({});
					}
				}
			},
			async item_click(item) {
				console.log(item);
				
				startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
				try{
					
					let ret_mint = await this.$http.get("api-open/coin_log/status?drawCardId="+item.id);
					this.mint_status = ret_mint.body.data;
					
				}catch(e){
					//TODO handle the exception
				}
				endLoading();
				
				if(!item || !item.id) return;
				this.detailsDialogVisible=true;
				this.currentItem = item;
				this.currentItem.did = this.currentItem.did_prefix + "-" + this.formatDidNumber(this.currentItem.did_number);
				
				if(item.is_new) {
					let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
					this.$http.post("api-open/draw_card/updateNew", {
						id: item.id,
						address: account
					}).then(_ => {
						this.getData();
						this.$bus.$emit("getNewStatus");
					});
				}
			},
			
			formatDidNumber(num) {
				// || "　"
				if(num) {
					let num_all = ("0000000" + num).substr(-7);
					return num_all;
				} else {
					return "1~50";
				}
			},
			formatDidNumber2(item) {
				if(!item.did_number) {
					return "";
				}
				let num_all = ("0000000" + item.did_number).substr(-7);
				return item.did_prefix + "-" + num_all;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.container {
		min-width: 1305px;
		min-height: 100%;
		background-image: url('../assets/cjbj.png');
		background-repeat: no-repeat;
		background-size: 100%;
	}

	// .bg {
	// 	width: 100%;
	// 	min-width: 1305px;
	// 	height: 1076px;
	// 	background-image: url('../assets/scbj.png');
	// 	background-size: 100% 100%;
	// 	position: absolute;
	// 	// z-index: -1;
	// }

	.mycn {
		text-align: center;

		.mycnbt {
			height: 111px;
			object-fit: contain;
			margin-top: 184px;
		}

	}


	.details {
		text-align: center;

		.dtbt {
			height: 111px;
			object-fit: contain;
			margin-top: 184px;
		}

		.bar {
			width: 1302px;
			margin: auto;
			margin-top: 70px;
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
			// padding: 0 270px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.left {
				display: flex;

				.kuang {
					width: 164px;
					height: 50px;
					border: 1px solid #FFFFFF;
					border-radius: 6px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 16px;
					box-sizing: border-box;
					margin-right: 32px;

					.el-dropdown-link {
						color: #FFFFFF;
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						.el-icon-arrow-down {
							// float: right;
						}
					}

					.qieh {
						width: 14px;
						height: 17px;
						cursor: pointer;
					}
				}
			}

			.right {
				display: flex;
				align-items: center;

				input {
					width: 380px;
					height: 50px;
					border: 1px solid #FFFFFF;
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
					color: #FFFFFF;
					background: rgba(255, 255, 255, 0);
					box-sizing: border-box;
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
					padding: 0 20px;
					overflow: hidden;
				}

				img {
					width: 50px;
					height: 50px;
					background: #FFFFFF;
					border-radius: 0px 6px 6px 0px;
					padding: 15px;
					box-sizing: border-box;
				}
			}
		}

		.list {
			width: 1302px;
			// height: 1100px;
			// overflow-y: scroll;
			margin: auto;
			margin-top: 56px;
			margin-bottom: 162px;
			display: flex;
			flex-wrap: wrap;
			// padding: 56px 270px;
			// gap: 12px;
			justify-content: space-between;

			.item {
				width: 250px;
				height: 392px;
				margin-bottom: 110px;
				font-size: 18px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 33px;

				.pic {
					position: relative;

					img {
						width: 250px;
						height: 392px;
						// background: #F3BCBC;
						border-radius: 12px;
					}

					.top {
						position: absolute;
						top: 0;
						display: flex;
						justify-content: space-between;
						width: 100%;

						.shangjia {
							width: 35px;
							height: 35px;
							border-radius: 12px 0px 12px 0px;
						}
						
						.new {
							width: 63px;
							height: 20px;
							margin-top: 7px;
						}
						
						.putong {
							width: 35px;
							height: 35px;
							border-radius: 0px 12px 0px 12px;
						}
					}

					.bot {
						position: absolute;
						bottom: 0;
						text-align: center;
						width: 100%;

						.jingling {
							width: 54px;
							height: 63px;
						}
					}
				}

				.name {
					text-align: left;
				}

				.quan {
					text-align: left;
				}
			}
		}
	}



	.details-dialog {
		.content {
			width: 1300px;
			height: 638px;
			background: #323748;
			border-radius: 20px;
			background-image: url("../assets/xingzhuang.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
			box-sizing: border-box;
			padding: 28px 31px;
			display: flex;
			.left {
				width: 370px;
				min-width: 370px;
				height: 581px;
				display: flex;
				align-items: center;
				position: relative;
				.img {
					width: 100%;
					height: 100%;
					// background: #F3BCBC;
					border: 1px solid #FFFFFF;
					border-radius: 12px;
					// object-fit: contain;
				}
				.top {
					position: absolute;
					top: 0;
					display: flex;
					justify-content: space-between;
					width: 100%;
					.shangjia {
						width: 52px;
						height: 52px;
						background: #FFFFFF;
						border-radius: 12px 0px 12px 0px;
					}
					.putong {
						width: 52px;
						height: 52px;
						background: #FFFFFF;
						border-radius: 0px 12px 0px 12px;
					}
				}
				.bot {
					position: absolute;
					bottom: 0;
					text-align: center;
					width: 100%;
					.jingling {
						width: 80px;
						height: 93px;
					}
				}
			}
			.right {
				margin-left: 36px;
				position: relative;
				.title {
					margin-top: 21px;
					img {
						width: 19px;
						height: 19px;
					}
					span{
						font-size: 30px;
						font-family: Alibaba PuHuiTi;
						font-weight: 800;
						color: #FFFFFF;
						line-height: 33px;
						margin-left: 11px;
					}
				}
				.text {
					font-size: 18px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
					word-break: keep-all;
					margin-left: 32px;
				}
				.word {
					width: 772px;
					height: 300px;
					background: #292D3C;
					border-radius: 6px;
					margin: 18px 0;
					overflow-x: hidden;
					overflow-y: scroll;
					margin-left: 32px;
					padding: 20px;
					box-sizing: border-box;
					pre {
						width: 100%;
						font-size: 14px;
						font-family: Alibaba PuHuiTi;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 33px;
						white-space: pre-wrap;
						margin: unset;
						// overflow-y: scroll;
						// overflow: hidden;
						// text-overflow:ellipsis;
						// display:-webkit-box; 
						// -webkit-box-orient:vertical; 
						// -webkit-line-clamp:9;
					}
				}
				.did, .mint, .pending {
					width: 230px;
					height: 35px;
					background: #FFFFFF;
					border-radius: 6px;
					font-size: 18px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #292D3C;
					line-height: 33px;
					position: absolute;
					top: 95px;
					right: 32px;
					text-align: center;
				}
				.mint {
					width: 120px;
					cursor: pointer;
				}
				.pending {
					width: 120px;
				}
			}
		}
	}


	::-webkit-scrollbar {
		width: 0px;
	}
</style>