<template>
	<div class="container">
		<headbar></headbar>
		<!-- <div class="bg"></div> -->

		<div class="kac">
			<img class="kcbt" :src="require(`../assets/${$i18n.locale}/kcbt.svg`)" />
			<div class="fgx1">
				<div class="d1">
					<div class="d2"></div>
				</div>
				<div class="d3"></div>
			</div>

			<div class="carousel">
				<swiper class="swiper" :options="swiperOption">
					<swiper-slide v-for="(item,index) in swiperList" :key="index">
						<img class="img" :src="$utils.getImage(item.image)" />
					</swiper-slide>
				</swiper>


				<!-- <div class="list">
					<div class="card" v-for="(item,index) in imgList">
						<img class="img" :src="item.picUrl" />
					</div>
				</div> -->

				<!-- <el-carousel :interval="4000" type="card" width="1290px" height="478px">
					<el-carousel-item v-for="(item,index) in imgList" :key="index">
						<img class="img" :src="item.picUrl"/>
					</el-carousel-item>
				</el-carousel> -->
			</div>

			<div class="fgx2">
				<div class="d3"></div>
				<div class="d1">
					<div class="d2"></div>
				</div>
			</div>

			<div class="draw">
				<img :src="require(`../assets/${$i18n.locale}/Draw1time.png`)" @click="draw1_click" />
				<img :src="require(`../assets/${$i18n.locale}/Draw10time.png`)" @click="draw10_click" />
			</div>
			
			<div class="gdhd-view">
				<div class="gdhd-d">
					<img class="img" style="width: 19px; height: 19px;" src="../assets/gdhd.svg"/>
					<span>{{$t("CARD_DRAW_RULES")}}</span>
				</div>
				<pre class="pre" v-if="$i18n.locale=='en-US'">
1. The initial Cardaether card pool contains 50 cards, categorized as Common Cards (Blue), Rare Cards (Purple), Epic Cards (Red), and Legendary Cards (Golden). Among these, Common Cards make up about 60% of the pool, totaling 30 cards; Rare Cards account for around 24%, totaling 12 cards; Epic Cards take up about 12%, totaling 6 cards; and Legendary Cards occupy about 4%, totaling 2 cards.

2. The probabilities of drawing Common Cards, Rare Cards, Epic Cards, and Legendary Cards are 70%, 21%, 8.4%, and 0.6% respectively. There is no guarantee mechanism.

3. The card pool will be updated every 2 months, and previous cards will be permanently removed, becoming "limited edition cards."
				</pre>
				<pre class="pre" v-if="$i18n.locale=='zh-CN'">
1. 初始的 Cardaether 卡池包含了50张卡牌，分为普通卡（蓝色）、稀有卡（紫色）、史诗卡（红色）以及传说卡（金色）。其中，普通卡大约占据了卡池的60%，即30张卡；稀有卡占据了约24%，即12张卡；史诗卡占据了约12%，即6张卡；传说卡则占据了约4%，即2张卡。

2. 抽取普通卡、稀有卡、史诗卡以及传说卡的概率分别为70%、21%、8.4%以及0.6%。没有保底机制。

3. 卡池每2个月会更换新卡池，过往卡牌将会永久下架并成为“绝版卡”。
				</pre>
			</div>
		</div>

		<div class="details">
			<img class="dtbt" :src="require(`../assets/${$i18n.locale}/kcyq.svg`)" />

			<div class="bar">
				<div class="left">
					<el-dropdown class="kuang" @command="where_status">
						<span class="el-dropdown-link">
							{{$t(str_status)}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="ListingStatus">{{$t('ALL')}}</el-dropdown-item>
							<el-dropdown-item command="OnShelves">{{$t('OnShelves')}}</el-dropdown-item>
							<el-dropdown-item command="OffShelves">{{$t('OffShelves')}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-dropdown class="kuang" @command="where_rarity">
						<span class="el-dropdown-link">
							{{$t(str_rarity)}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="RARITY">{{$t('ALL')}}</el-dropdown-item>
							<el-dropdown-item command="Common">{{$t('Common')}}</el-dropdown-item>
							<el-dropdown-item command="rare">{{$t('rare')}}</el-dropdown-item>
							<el-dropdown-item command="epic">{{$t('epic')}}</el-dropdown-item>
							<el-dropdown-item command="Legendary">{{$t('Legendary')}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-dropdown class="kuang" @command="where_race_en">
						<span class="el-dropdown-link">
							{{$t(str_race_en)}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="RACE">{{$t('ALL')}}</el-dropdown-item>
							<el-dropdown-item command="Demons">{{$t('Demons')}}</el-dropdown-item>
							<el-dropdown-item command="Angels">{{$t('Angels')}}</el-dropdown-item>
							<el-dropdown-item command="Undead">{{$t('Undead')}}</el-dropdown-item>
							<el-dropdown-item command="Vampires">{{$t('Vampires')}}</el-dropdown-item>
							<el-dropdown-item command="Beasts">{{$t('Beasts')}}</el-dropdown-item>
							<el-dropdown-item command="Dwarves">{{$t('Dwarves')}}</el-dropdown-item>
							<el-dropdown-item command="Humans">{{$t('Humans')}}</el-dropdown-item>
							<el-dropdown-item command="Elves">{{$t('Elves')}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-dropdown class="kuang" @command="where_ender">
						<span class="el-dropdown-link">
							{{$t(str_ender)}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="ENDER">{{$t('ALL')}}</el-dropdown-item>
							<el-dropdown-item command="male">{{$t('male')}}</el-dropdown-item>
							<el-dropdown-item command="female">{{$t('female')}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<!-- <div class="kuang status">
						<span>LISTING STATUS</span>
						<img class="qieh" src="../assets/qiehuan.svg" @click="qieh_click('status')" />
					</div> -->
					<!-- <div class="kuang rarity">
						<span>RARITY</span>
						<img class="qieh" src="../assets/qiehuan.svg" @click="qieh_click('rarity')" />
					</div> -->
					<!-- <div class="kuang race">
						<span>RACE</span>
						<img class="qieh" src="../assets/qiehuan.svg" @click="qieh_click('race_en')" />
					</div> -->
					<!-- <div class="kuang status">
						<span>ENDER</span>
						<img class="qieh" src="../assets/qiehuan.svg" @click="qieh_click('')" />
					</div> -->
				</div>
				<div class="right">
					<input type="text" placeholder="Search for a name" v-model="search" />
					<img src="../assets/fuwei.svg" style="cursor:pointer;" @click="search_click" />
				</div>
			</div>

			<div class="list">
				<div class="item" v-for="(item,index) in imgList" :key="index" @click="item_click(item)">
					<div class="pic" v-if="item.image">
						<img :src="$utils.getImage(item.image)" />
						<div class="top">
							<img class="shangjia" v-if="item.status" src="../assets/shangjia.svg" />
							<img class="shangjia" v-if="!item.status" src="../assets/xiajia.svg" />
							<img class="putong" :src="$utils.getImage('/styles/'+rarity[item.rarity]+'.svg')" />
						</div>
						<div class="bot">
							<img class="jingling" :src="$utils.getImage('/styles/'+item.race_en+'.svg')" />
						</div>
					</div>
					<div class="name" v-if="item.image">{{$i18n.locale=='en-US'?item.name_en:item.name_zh}}</div>
					<div class="quan" v-if="item.image">{{$i18n.locale=='en-US'?'Drawn':'已抽出'}} {{item.quantity || 0}}</div>
				</div>
				<!-- <div class="tc"></div> -->
			</div>
		</div>


		<!-- details dialog -->
		<el-dialog custom-class="details-dialog" :show-close="false" :visible.sync="detailsDialogVisible"
			width="1300px"> <!-- :before-close="handleClose"-->
			<div class="content">
				<div class="left">
					<img class="img" :src="$utils.getImage(currentItem.image)" />
					<div class="top">
						<img class="shangjia" src="../assets/shangjia.svg" />
						<img class="putong" :src="$utils.getImage('/styles/'+rarity[currentItem.rarity]+'.svg')" />
					</div>
					<div class="bot">
						<img class="jingling" :src="$utils.getImage('/styles/'+currentItem.race_en+'.svg')" />
					</div>
				</div>
				<div class="right">
					<div class="title">
						<img src="../assets/gdhd.svg" />
						<span>{{$i18n.locale=='en-US'?currentItem.name_en:currentItem.name_zh}}</span>
					</div>
					<div class="text">{{$t("RACE")}}: {{$i18n.locale=='en-US'?currentItem.race_en:currentItem.race_zh}}</div>
					<div class="text">{{$t("Class")}}: {{$i18n.locale=='en-US'?currentItem.career_en:currentItem.career_zh}}</div>
					<div class="word">
						<pre>{{$i18n.locale=='en-US'?currentItem.story_en:currentItem.story_zh}}</pre>
					</div>
					<!-- <div class="text">{{$t("story")}}</div> -->
					<div class="text">{{$t("Skill")}}: {{$i18n.locale=='en-US'?currentItem.skill_en:currentItem.skill_zh}}</div>
					<div class="text">{{$t("effect")}}: {{$i18n.locale=='en-US'?currentItem.effect_en:currentItem.effect_zh}}</div>
					<!-- <span class="did">DID: CA-A-GW-0000001</span> -->
				</div>
			</div>
		</el-dialog>

		<div class="drawimg-1" v-show="drawimg_1" @click="card_card">
			<div class="card-div" v-if="drawimg_1_card"><!-- --> 
				<div class="card22">
					<img class="card" :src="$utils.getImage(draw_1_res.image)"/>	<!-- /uploads/LunaStonehammer.png -->  <!-- $utils.getImage(draw_1_res.image) -->
					<!-- <div class="top">
						<img class="shangjia" src="@/assets/shangjia.svg" />
						<img class="putong" :src="$utils.getImage('/styles/'+rarity[draw_1_res.rarity]+'.svg')" />
					</div>
					<div class="bot">
						<img class="jingling" :src="$utils.getImage('/styles/'+draw_1_res.race_en+'.svg')" />
					</div> -->
				</div>
			</div>
			<img class="guyun" v-show="drawimg_1_card" v-if="rarity[draw_1_res.rarity]" :src='"../assets/card_dj/"+rarity[draw_1_res.rarity]+".png"'/>
			<img class="draw-ani" v-show="draw_ani_1" src="@/assets/draw_1.png"/>
		</div>
		
		<div class="drawimg-2" v-show="drawimg_2" @click="card_2_card">
			<div class="card-vw">
				<div class="card-div" v-if="drawimg_2_card">
					<div class="item" v-for="(item, index) in draw_2_res"> <!-- draw_2_res -->
						<img class="card" :src="$utils.getImage(item.image)"/><!-- '/uploads/LunaStonehammer.png' -->
						<!-- <div class="top">
							<img class="shangjia" src="@/assets/shangjia.svg" />
							<img class="putong" :src="$utils.getImage('/styles/'+rarity[item.rarity]+'.svg')" />
						</div>
						<div class="bot">
							<img class="jingling" :src="$utils.getImage('/styles/'+item.race_en+'.svg')" />
						</div> -->
					</div>
				</div>
				<div class="guyun" v-show="drawimg_2_card">
					<img class="img" v-if="rarity[item.rarity]" :src='"../assets/card_dj/"+rarity[item.rarity]+".png"' v-for="(item, index) in draw_2_res" />
				</div>
			</div>
			<img class="draw-ani" v-show="draw_ani_2" src="@/assets/draw_10.png"/>
		</div>
		
		
		<!-- 标记需要显示返回顶部按钮 -->
		<div id="scrolltop" style="display: none;"></div>
		
	</div>

</template>

<script>
	import headbar from "../components/headbar.vue"
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	import {
		startLoading,
		endLoading
	} from "../common/loading.js"

	let desc = "";
	export default {
		components: {
			headbar,
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				rarity: {
					"普通": "Common",
					"稀有": "rare",
					"史诗": "epic",
					"传奇": "Legendary"
				},
				card_dj: {
					// "c1": 
				},
				sort: "",
				search: "",
				swiperList: [],
				imgList: [],

				swiperOption: {
					slidesPerView: 5,
					spaceBetween: 100,
					loop: true,
					autoplay: {
						delay: 2500,
						disableOnInteraction: false
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
				},

				detailsDialogVisible: false,
				currentItem: {},
				
				drawimg_1: false,
				drawimg_1_card: false,
				draw_ani_1: true,
				draw_1_res: { image: "" },
				
				drawimg_2: false,
				drawimg_2_card: false,
				draw_ani_2: true,
				draw_2_res: [],
				
				
				str_status: "ListingStatus",
				str_rarity: "RARITY",
				str_race_en: "RACE",
				str_ender: "GENDER",
				
				status_where: "",
				rarity_where: "",
				race_en_where: "",
				ender_where: "",
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			getData() {
				// startLoading("数据加载中...");
				this.$http.get("api-open/card_pool/list").then(result => {
					console.log(123123,result.body);
					this.imgList = result.body;
					this.comp();
					// endLoading();
				})
				this.$http.get("api-open/card_pool/list?where=%20and%20status=1").then(result => {
					// console.log(result.body);
					this.swiperList = result.body;
					this.comp();
					// endLoading();
				})
			},
			draw1_click() {
				// this.drawimg_1 = true;
				// this.drawimg_1_card = true;
				// this.draw_ani_1 = false;
				
				// return;
				
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				if (!account) {
					this.$bus.$emit("account_login");
					return;
				}
				
				startLoading(this.$i18n.locale=='en-US'?"Waiting for lottery":"等待开奖中...");
				
				this.draw_1_res = { image: "" };
				let url = `api-open/draw_card/draw?address=${account}&times=1`;
				this.$http.get(url).then(result => {
					this.$bus.$emit("getTimes");
					endLoading();
					
					console.log(result.body);
					if (result.body.error) {
						this.card_card();
						this.$message({
							type: 'warning',
							message: (this.$i18n.locale=='en-US'?result.body.msg_en:result.body.msg) || (this.$i18n.locale=='en-US'?"Card draw failed":'抽卡失败')
						});
						// endLoading();
						return;
					}
					this.draw_1_res = result.body.data;
					
					this.$bus.$emit("getNewStatus");
					
					// 开奖动画
					this.drawimg_1 = true;
					setTimeout(_ => {
						this.drawimg_1_card = true;
						setTimeout(_ => {
							this.draw_ani_1 = false;
						}, 2000)
					}, 3000);
				})
			},
			draw10_click() {
				let account = this.$utils.getCookie("CHAIN_ADDRESS") || "";
				if (!account) {
					this.$bus.$emit("account_login");
					return;
				}
				
				startLoading(this.$i18n.locale=='en-US'?"Waiting for lottery":"等待开奖中...");
				
				this.draw_2_res = [];
				let url = `api-open/draw_card/draw?address=${account}&times=10`;
				this.$http.get(url).then(result => {
					this.$bus.$emit("getTimes");
					endLoading();
					
					console.log(result.body);
					if (result.body.error) {
						this.card_2_card();
						this.$message({
							type: 'warning',
							message: (this.$i18n.locale=='en-US'?result.body.msg_en:result.body.msg) || (this.$i18n.locale=='en-US'?"Card draw failed":'抽卡失败')
						});
						return;
					}
					this.draw_2_res = result.body.data;
					
					this.$bus.$emit("getNewStatus");
					
					// 开奖动画
					this.drawimg_2 = true;
					setTimeout(_ => {
						this.drawimg_2_card = true;
						setTimeout(_ => {
							this.draw_ani_2 = false;
						}, 2000)
					}, 3000);
					
				})
			},
			qieh_click(name) {
				startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");

				this.sort = name;
				this.$http.get("api-open/card_pool/list?sort=" + this.sort + "&desc=" + desc + "&search=" + this.search)
					.then(result => {
						// console.log(result.body);
						this.imgList = result.body;
						this.comp();

						endLoading();
					})
				if (desc) desc = "";
				else desc = "desc";
			},
			// search_click() {
			// 	startLoading("数据加载中...");

			// 	this.sort = name;
			// 	this.$http.get("api-open/card_pool/list?sort=" + this.sort + "&desc=" + desc + "&search=" + this.search)
			// 		.then(result => {
			// 			// console.log(result.body);
			// 			this.imgList = result.body;
			// 			this.comp();

			// 			endLoading();
			// 		})
			// },
			where_status(str) {
				let obj = { OnShelves: 1, OffShelves: 0 };
				this.str_status = str;
				if(obj[str] == 0 || obj[str] == 1) {
					this.status_where = "status=" + obj[str];
				} else {
					this.status_where = "";
				}
				this.search_click();
			},
			where_rarity(str) {
				let obj = { Common: "普通", rare: "稀有", epic: "史诗", Legendary: "传奇", };
				this.str_rarity = str;
				if(obj[str]) {
					this.rarity_where = "rarity='" + obj[str] + "'";
				} else {
					this.rarity_where = "";
				}
				this.search_click();
			},
			where_race_en(str) {
				let obj = { Demons:"Demons",Angels:"Angels",Undead:"Undead",Vampires:"Vampires",Beasts:"Beasts",Dwarves:"Dwarves",Humans:"Humans",Elves:"Elves" };
				this.str_race_en = str;
				if(obj[str]) {
					this.race_en_where = "race_en='" + obj[str] + "'";
				} else {
					this.race_en_where = "";
				}
				this.search_click();
			},
			where_ender(str) {
				let obj = { male:"男",female:"女" };
				this.str_ender = str;
				if(obj[str]) {
					this.ender_where = "ender='" + obj[str] + "'";
				} else {
					this.ender_where = "";
				}
				this.search_click();
			},
			search_click() {
				startLoading(this.$i18n.locale=='en-US'?"Loading data...":"数据加载中...");
				
				let whe = "";
				if(this.status_where) whe += " and " + this.status_where;
				if(this.rarity_where) whe += " and " + this.rarity_where;
				if(this.race_en_where) whe += " and " + this.race_en_where;
				if(this.ender_where) whe += " and " + this.ender_where;
				// console.log(121212,whe);
				
				this.$http.get("api-open/card_pool/list?where="+whe+"&search=" + this.search)
					.then(result => {
						// console.log(result.body);
						this.imgList = result.body;
						this.comp();
				
						endLoading();
					})
			},
			comp() {
				let len = this.imgList.length;
				let nn = 5 - (len % 5);
				if (nn < 4) {
					for (let i = 0; i < nn; i++) {
						this.imgList.push({});
					}
				}
			},
			item_click(item) {
				console.log(123123,item);
				if (!item || !item.id) return;
				this.detailsDialogVisible = true;
				this.currentItem = item;
			},
			card_card() {
				if(this.draw_ani_1) return;
				
				this.drawimg_1 = false;
				this.drawimg_1_card = false;
				this.draw_ani_1 = true;
			},
			card_2_card() {
				if(this.draw_ani_2) return;
				
				this.drawimg_2 = false;
				this.drawimg_2_card = false;
				this.draw_ani_2 = true;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.container {
		min-width: 1305px;
		background-image: url('../assets/cjbj.png');
		background-repeat: no-repeat;
		background-size: 100%;
	}

	// .bg {
	// 	width: 100%;
	// 	min-width: 1305px;
	// 	height: 1076px;
	// 	background-image: url('../assets/cjbj.png');
	// 	background-size: 100% 100%;
	// 	position: absolute;
	// 	// z-index: -1;
	// }

	.kac {
		text-align: center;

		.kcbt {
			height: 111px;
			object-fit: contain;
			margin-top: 184px;
		}

		.fgx1 {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 47px;

			.d1 {
				width: 16px;
				height: 16px;
				border: 1px solid #FFFFFF;
				border-radius: 50%;
				position: relative;
			}

			.d2 {
				width: 8px;
				height: 8px;
				background: #FFFFFF;
				border-radius: 50%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}

			.d3 {
				width: 1285px;
				height: 4px;
				background: linear-gradient(90deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
			}
		}

		.fgx2 {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 53px;

			.d1 {
				width: 16px;
				height: 16px;
				border: 1px solid #FFFFFF;
				border-radius: 50%;
				position: relative;
			}

			.d2 {
				width: 8px;
				height: 8px;
				background: #FFFFFF;
				border-radius: 50%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}

			.d3 {
				width: 1285px;
				height: 4px;
				background: linear-gradient(-90deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
			}
		}

		.carousel {
			width: 1290px;
			height: 478px;
			margin: 53px auto;
			overflow: hidden;

			.swiper {
				-webkit-mask-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 10%);

				.img {
					width: 262px;
					height: 478px;
					background: #C2D0F4;
					border: 1px solid #ECECEC;
					border-radius: 20px;
				}
			}

			// .list {
			// 	display: flex;

			// 	// filter: opacity(70%);
			// 	.card {
			// 		min-width: 262px;
			// 		height: 478px;
			// 		margin-right: 19px;

			// 		.img {
			// 			width: 100%;
			// 			height: 100%;
			// 			background: #C2D0F4;
			// 			border: 1px solid #ECECEC;
			// 			border-radius: 20px;
			// 		}
			// 	}
			// }



			// .el-carousel {
			// 	.el-carousel__item {
			// 		width: 262px;
			// 		height: 478px;
			// 		background: #C2D0F4;
			// 		border: 1px solid #ECECEC;
			// 		border-radius: 20px;
			// 		overflow: hidden;
			// 	}
			// 	.img {
			// 		width: 100%;
			// 		height: 100%;
			// 	}
			// }
		}


		.draw {
			width: 1150px;
			margin: auto;
			margin-top: 84px;
			display: flex;
			justify-content: space-between;
			// padding: 0 398px;
			box-sizing: border-box;
		}

		.draw img {
			width: 475px;
			height: 120px;
			cursor: pointer;
		}

	}

	.gdhd-view {
		width: 1300px;
		// height: 450px;
		background: linear-gradient(90deg, rgba(30, 36, 53, 0.8), rgba(17, 26, 50, 0.8));
		border-radius: 0px 20px 0px 20px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		overflow: hidden;
		margin: 116px auto;
		padding: 0px 10px;
		padding-top: 30px;
		box-sizing: border-box;
		.gdhd-d {
			font-size: 20px;
			font-family: Impact;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
			span {
				margin-left: 13px;
			}
		}
		pre {
			white-space: pre-wrap;
			padding-left: 40px;
			text-align: left;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
		}
	}

	.details {
		text-align: center;
		overflow: hidden;

		.dtbt {
			height: 111px;
			object-fit: contain;
			// margin-top: 184px;
		}

		.bar {
			width: 1302px;
			margin: auto;
			margin-top: 70px;
			font-size: 14px;
			font-family: Alibaba PuHuiTi;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 33px;
			// padding: 0 270px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.left {
				display: flex;

				.kuang {
					width: 164px;
					height: 50px;
					border: 1px solid #FFFFFF;
					border-radius: 6px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 16px;
					box-sizing: border-box;
					margin-right: 32px;
					
					.el-dropdown-link {
						color: #FFFFFF;
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						.el-icon-arrow-down {
							// float: right;
						}
					}

					.qieh {
						width: 14px;
						height: 17px;
						cursor: pointer;
					}
					
				}
			}

			.right {
				display: flex;
				align-items: center;

				input {
					width: 380px;
					height: 50px;
					border: 1px solid #FFFFFF;
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
					color: #FFFFFF;
					background: rgba(255, 255, 255, 0);
					box-sizing: border-box;
					font-size: 14px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
					padding: 0 20px;
					overflow: hidden;
				}

				img {
					width: 50px;
					height: 50px;
					background: #FFFFFF;
					border-radius: 0px 6px 6px 0px;
					padding: 15px;
					box-sizing: border-box;
				}
			}
		}

		.list {
			width: 1302px;
			// height: 1100px;
			// overflow-y: scroll;
			margin: auto;
			margin-top: 56px;
			margin-bottom: 162px;
			display: flex;
			flex-wrap: wrap;
			// padding: 56px 270px;
			// gap: 12px;
			justify-content: space-between;

			.item {
				min-width: 250px;
				margin-bottom: 59px;
				font-size: 18px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 33px;

				.pic {
					position: relative;

					img {
						width: 250px;
						// height: 392px;
						// background: #F3BCBC;
						border-radius: 12px;
						object-fit: contain;
					}

					.top {
						position: absolute;
						top: 0;
						display: flex;
						justify-content: space-between;
						width: 100%;

						.shangjia {
							width: 35px;
							height: 35px;
							border-radius: 12px 0px 12px 0px;
						}

						.putong {
							width: 35px;
							height: 35px;
							border-radius: 0px 12px 0px 12px;
						}
					}

					.bot {
						position: absolute;
						bottom: 0;
						text-align: center;
						width: 100%;

						.jingling {
							width: 54px;
							height: 63px;
						}
					}
				}

				.name {
					text-align: left;
				}

				.quan {
					text-align: left;
				}
			}
		}
	}


	.details-dialog {
		.content {
			width: 1300px;
			height: 638px;
			background: #323748;
			border-radius: 20px;
			background-image: url("../assets/xingzhuang.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
			box-sizing: border-box;
			padding: 28px 31px;
			display: flex;

			.left {
				width: 370px;
				min-width: 370px;
				height: 581px;
				display: flex;
				align-items: center;
				position: relative;

				.img {
					width: 100%;
					height: 100%;
					// background: #F3BCBC;
					border: 1px solid #FFFFFF;
					border-radius: 12px;
					// object-fit: contain;
				}

				.top {
					position: absolute;
					top: 0;
					display: flex;
					justify-content: space-between;
					width: 100%;

					.shangjia {
						width: 52px;
						height: 52px;
						background: #FFFFFF;
						border-radius: 12px 0px 12px 0px;
					}

					.putong {
						width: 52px;
						height: 52px;
						background: #FFFFFF;
						border-radius: 0px 12px 0px 12px;
					}
				}

				.bot {
					position: absolute;
					bottom: 0;
					text-align: center;
					width: 100%;

					.jingling {
						width: 80px;
						height: 93px;
					}
				}
			}

			.right {
				margin-left: 36px;
				position: relative;

				.title {
					margin-top: 21px;

					img {
						width: 19px;
						height: 19px;
					}

					span {
						font-size: 30px;
						font-family: Alibaba PuHuiTi;
						font-weight: 800;
						color: #FFFFFF;
						line-height: 33px;
						margin-left: 11px;
					}
				}

				.text {
					font-size: 18px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 33px;
					word-break: keep-all;
					margin-left: 32px;
				}

				.word {
					width: 772px;
					height: 300px;
					background: #292D3C;
					border-radius: 6px;
					margin: 18px 0;
					overflow-x: hidden;
					overflow-y: scroll;
					margin-left: 32px;
					padding: 20px;
					box-sizing: border-box;

					pre {
						width: 100%;
						font-size: 14px;
						font-family: Alibaba PuHuiTi;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 33px;
						white-space: pre-wrap;
						margin: unset;
						// overflow-y: scroll;
						// overflow: hidden;   
						// text-overflow:ellipsis;
						// display:-webkit-box; 
						// -webkit-box-orient:vertical; 
						// -webkit-line-clamp:9;
					}
				}

				.did {
					width: 230px;
					height: 35px;
					background: #FFFFFF;
					border-radius: 6px;
					font-size: 18px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #292D3C;
					line-height: 33px;
					position: absolute;
					top: 95px;
					right: 32px;
				}
			}
		}
	}

	.drawimg-1 {
		position: fixed;
		top: 0%;
		left: 0%;
		right: 0%;
		bottom: 0%;
		background-color: rgba(17, 26, 50, 0.8);
		z-index: 9999;
		display: flex;
		justify-content: center;
		align-items: center;
		.draw-ani {
			width: 1800px;
			// height: 100%;
			object-fit: contain;
		}
		.card-div {
			width: 250px;
			// height: 392px;
			top: 50%;
			left: 50%;
			position: absolute;
			transform: translate(-50%, -50%);
			z-index: -1;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 15px;
			overflow: hidden;
			.card22 {
				width: 250px;
				// height: 392px;
				position: relative;
				border-radius: 10px;
				overflow: hidden;
				.card {
					width: 250px;
					// height: 392px;
					object-fit: contain; 
				}
				
				.top {
					position: absolute;
					top: 0;
					display: flex;
					justify-content: space-between;
					width: 100%;
				
					.shangjia {
						width: 35px;
						height: 35px;
						border-radius: 12px 0px 12px 0px;
					}
				
					.putong {
						width: 35px;
						height: 35px;
						border-radius: 0px 12px 0px 12px;
					}
				}
				
				.bot {
					position: absolute;
					bottom: 0;
					text-align: center;
					width: 100%;
				
					.jingling {
						width: 54px;
						height: 63px;
					}
				}
			}
		}
		.guyun {
			width: 430px;
			height: 624px;
			// height: 392px;
			// object-fit: contain; 
			// position: absolute;
			z-index: -1;
			top: calc(50% - 1px);
			left: 50%;
			position: absolute;
			transform: translate(-50%, -50%);
		}
		
	}
	
	.drawimg-2 {
		position: fixed;
		top: 0%;
		left: 0%;
		right: 0%;
		bottom: 0%;
		background-color: rgba(17, 26, 50, 0.8);
		z-index: 9999;
		text-align: center;
		overflow: auto;
		.draw-ani {
			height: 1070px;
			object-fit: contain;
		}
		.card-vw {
			left: 0%;
			right: 0%;
			top: 0%;
			bottom: 0%;
			position: absolute;
			display: flex;
			justify-content: center;
		}
		.card-div {
			width: 1155px;
			// height: 100%;
			margin-top: 147px;
			box-sizing: border-box;
			position: absolute;
			display: flex;
			// justify-content: space-between;     
			flex-wrap: wrap;
			z-index: -1;
			.item {
				width: 183px;
				height: 338px;
				margin-right: 60px;
				border-radius: 10px;
				overflow: hidden;
				position: relative;
				border-radius: 15px;
				&:nth-of-type(5n) {
					margin-right: 0%;
				}
				&:nth-of-type(n+6) {
					margin-top: 99px;
				}
				.card {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
				
				.top {
					position: absolute;
					top: 0;
					display: flex;
					justify-content: space-between;
					width: 100%;
				
					.shangjia {
						width: 35px;
						height: 35px;
						border-radius: 12px 0px 12px 0px;
					}
				
					.putong {
						width: 35px;
						height: 35px;
						border-radius: 0px 12px 0px 12px;
					}
				}
				
				.bot {
					position: absolute;
					bottom: 0;
					text-align: center;
					width: 100%;
				
					.jingling {
						width: 54px;
						height: 63px;
					}
				}
			}
			
		}
		
		.guyun {
			// top: 0%;
			// bottom: 0%;
			// left: 0%;
			// right: 0%;
			width: 1213px;
			position: absolute;
			// padding: 4.3% 16%;
			flex-wrap: wrap;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			margin-top: 138px;
			z-index: -1;
			img {
				width: 240px;
				object-fit: contain;
				transform: scaleX(1.32) scaleY(1.28);
				&:nth-of-type(n+6) {
					margin-top: 80px;
				}
			}
			
			
			// .vw {
			// 	position: relative;
			// 	img {
			// 		left: -1%;
			// 		top: -10%;
			// 		// width: 25%;
			// 		// height: 52%;
			// 		position: absolute;
			// 	}
			// }
			
		}
	}
	

	::-webkit-scrollbar {
		width: 0px;
	}
</style>

<style>
	.el-dropdown-menu {
		width: 164px;
	}
</style>